import './style.scss';
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom';

import SiteHeader from 'components/SiteHeader/SiteHeader';
import PageSideBar from 'components/PageSideBar/PageSideBar';
import LoadingIcon from 'components/LoadingIcon/LoadingIcon';

// ユーザー管理
import UserPageHeader from 'components/PageHeader/UserPageHeader';
import UserListPage from 'components/UserListPage/UserListPage';
import CreateUserPage from 'components/CreateUserPage/CreateUserPage';
import EditUserPage from 'components/EditUserPage/EditUserPage';

// グループ管理
import GroupPageHeader from 'components/PageHeader/GroupPageHeader';
import GroupListPage from 'components/GroupListPage/GroupListPage';
import CreateGroupPage from 'components/CreateGroupPage/CreateGroupPage';
import EditGroupPage from 'components/EditGroupPage/EditGroupPage';

// ロール管理
import RolePageHeader from 'components/PageHeader/RolePageHeader';
import RoleListPage from 'components/RoleListPage/RoleListPage';
import CreateRolePage from 'components/CreateRolePage/CreateRolePage';
import EditRolePage from 'components/EditRolePage/EditRolePage';

import BackendApiService from 'service/api/BackendApiService';
import ErrorHandleService from 'error/ErrorHandleService';
import { ManageApiError } from 'error/ManageError';

const APP_TYPE = 'manage';

/**
 * 内部管理ツール管理アプリケーション
 */
export default class ManageApp extends React.Component {

  componentDidMount() {
    const mainComponent = this.props.mainComponent;
    const { isAccountInfoLoading, isAccountRolesLoading, isAssortsLoading } = mainComponent.state;
    if (isAccountInfoLoading && isAccountRolesLoading && isAssortsLoading) {
      Promise.all([
        BackendApiService.getUserProfile(),
        BackendApiService.getSessionUserRoles(),
        BackendApiService.getAssorts()
      ]).then(([ userResponse, userRolesResponse, assortsResponse ]) => {
        console.trace('get account profile api succeeded.', userResponse, userRolesResponse);
        mainComponent.setState({ isAccountInfoLoading: false, account: userResponse.data, isAccountRolesLoading: false, accountRoles: userRolesResponse.data, isAssortsLoading: false, assorts: assortsResponse.data });
      }).catch((error) => {
        console.error('get account profile api failed.', error);
        ErrorHandleService.handleError(ManageApp._errorHandler.bind(this), error);
      });
    }
  }

  static _errorHandler(error) {
    if (!(error instanceof ManageApiError)) { throw error; }
    const [ status, body, errorCode, errorSubCode, errorMessage ] = [ error.getStatusCode(), error.getResponseBody(), error.getErrorCode(), error.getErrorSubCode(), error.getErrorMessage() ];
    console.debug('error handler.', status, body, errorCode, errorSubCode, errorMessage);
    switch (status) {
      case 400:
        throw error;
      default:
        throw error;
    }
  }

  _viewTemplate(siteHeader, pageHeader, pageSideBar, pageBody) {
    return (
      <div class="p-site container-fluid d-flex flex-column">
        {siteHeader}
        <div class="p-page container-fluid">
          {pageHeader}
          <div class="p-page__main row">
            <div class="p-page__side-bar col-sm-2">
              {pageSideBar}
            </div>
            <div class="p-page__body col-sm-10">
              {pageBody}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    console.trace('ManageApp', this);
    const { isAccountInfoLoading, isAccountRolesLoading } = this.props.mainComponent.state;

    if (isAccountInfoLoading || isAccountRolesLoading) {
      return ( <LoadingIcon/> );
    }

    const { mainComponent } = this.props;
    const { account } = mainComponent.state;

    return (
      <Switch>
        <Route path="/apps/:appId/users" exact render={props => {
            // ユーザー一覧
            const pageType = 'LIST';
            const siteHeader = (<SiteHeader account={account}/>);
            const pageHeader = (<UserPageHeader {...props} pageType={pageType} mainComponent={mainComponent}/>);
            const pageSideBar = (<PageSideBar {...props} mainComponent={mainComponent}/>);
            const pageBody = (<UserListPage {...this.props} {...props}/>);
            return this._viewTemplate(siteHeader, pageHeader, pageSideBar, pageBody);
          }}/>
        <Route path="/apps/:appId/users/new" exact render={props => {
            // ユーザー作成
            const pageType = 'CREATE';
            const siteHeader = (<SiteHeader account={account}/>);
            const pageHeader = (<UserPageHeader {...props} pageType={pageType}/>);
            const pageSideBar = (<PageSideBar {...props} mainComponent={mainComponent}/>);
            const pageBody = (<CreateUserPage {...this.props} {...props}/>);
            return this._viewTemplate(siteHeader, pageHeader, pageSideBar, pageBody);
          }}/>
        <Route path="/apps/:appId/users/:userId" render={props => {
            const userId = props.match.params.userId;
            // ユーザー編集
            const pageType = 'EDIT';
            const siteHeader = (<SiteHeader account={account}/>);
            const pageHeader = (<UserPageHeader {...props} pageType={pageType} userId={userId}/>);
            const pageSideBar = (<PageSideBar {...props} mainComponent={mainComponent}/>);
            const pageBody = (<EditUserPage {...this.props} {...props} userId={userId}/>);
            return this._viewTemplate(siteHeader, pageHeader, pageSideBar, pageBody);
          }}/>
        <Route path="/apps/:appId/groups" exact render={props => {
            // グループ一覧
            const pageType = 'LIST';
            const siteHeader = (<SiteHeader account={account}/>);
            const pageHeader = (<GroupPageHeader {...props} pageType={pageType} mainComponent={mainComponent}/>);
            const pageSideBar = (<PageSideBar {...props} mainComponent={mainComponent}/>);
            const pageBody = (<GroupListPage {...this.props} {...props}/>);
            return this._viewTemplate(siteHeader, pageHeader, pageSideBar, pageBody);
          }}/>
        <Route path="/apps/:appId/groups/new" exact render={props => {
            // グループ作成
            const pageType = 'CREATE';
            const siteHeader = (<SiteHeader account={account}/>);
            const pageHeader = (<GroupPageHeader {...props} pageType={pageType}/>);
            const pageSideBar = (<PageSideBar {...props} mainComponent={mainComponent}/>);
            const pageBody = (<CreateGroupPage {...this.props} {...props}/>);
            return this._viewTemplate(siteHeader, pageHeader, pageSideBar, pageBody);
          }}/>
        <Route path="/apps/:appId/groups/:groupId" render={props => {
            const groupId = props.match.params.groupId;
            // グループ編集
            const pageType = 'EDIT';
            const siteHeader = (<SiteHeader account={account}/>);
            const pageHeader = (<GroupPageHeader {...props} pageType={pageType} groupId={groupId}/>);
            const pageSideBar = (<PageSideBar {...props} mainComponent={mainComponent}/>);
            const pageBody = (<EditGroupPage {...this.props} {...props} groupId={groupId}/>);
            return this._viewTemplate(siteHeader, pageHeader, pageSideBar, pageBody);
          }}/>
        <Route path="/apps/:appId/roles" exact render={props => {
            // ロール一覧
            const pageType = 'LIST';
            const siteHeader = (<SiteHeader account={account}/>);
            const pageHeader = (<RolePageHeader {...props} pageType={pageType} mainComponent={mainComponent}/>);
            const pageSideBar = (<PageSideBar {...props} mainComponent={mainComponent}/>);
            const pageBody = (<RoleListPage {...this.props} {...props}/>);
            return this._viewTemplate(siteHeader, pageHeader, pageSideBar, pageBody);
          }}/>
        <Route path="/apps/:appId/roles/new" exact render={props => {
            // ロール作成
            const pageType = 'CREATE';
            const siteHeader = (<SiteHeader account={account}/>);
            const pageHeader = (<RolePageHeader {...props} pageType={pageType}/>);
            const pageSideBar = (<PageSideBar {...props} mainComponent={mainComponent}/>);
            const pageBody = (<CreateRolePage {...this.props} {...props}/>);
            return this._viewTemplate(siteHeader, pageHeader, pageSideBar, pageBody);
          }}/>
        <Route path="/apps/:appId/roles/:roleId" render={props => {
            const roleId = props.match.params.roleId;
            // ロール編集
            const pageType = 'EDIT';
            const siteHeader = (<SiteHeader account={account}/>);
            const pageHeader = (<RolePageHeader {...props} pageType={pageType} roleId={roleId}/>);
            const pageSideBar = (<PageSideBar {...props} mainComponent={mainComponent}/>);
            const pageBody = (<EditRolePage {...this.props} {...props} roleId={roleId}/>);
            return this._viewTemplate(siteHeader, pageHeader, pageSideBar, pageBody);
          }}/>
        <Redirect from="/apps/manage" to="/apps/manage/users"/>
      </Switch>
    );
  }
}

ManageApp.APP_TYPE = APP_TYPE;
