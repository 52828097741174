/**
 * 有料ポイント付与に利用するダイアログ
 */
import React from 'react';

/**
 * 有料ポイント付与の確認用ダイアログ
 */
export class AddPaidPointsConfirmationDialog extends React.Component {
  render() {
    const {userId, description, price, points} = this.props;
    console.log('AddPaidPointsConfirmationDialog', this, userId, description, price, points);

    return (
      <div class="modal fade" id="add_paid_points_dialog" role="dialog">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">有料ポイント付与対象確認</h4>
            </div>
            <div class="modal-body">
              <p class="offset-sm-1 form-text text-muted"> 以下の内容で有料ポイントを付与します。本当によろしいですか？ </p>
              <table class="table table-bordered">
                <thead>
                <tr>
                  <th> ユーザーID</th>
                  <th> 説明</th>
                  <th> 価格</th>
                  <th> ポイント数</th>
                </tr>
                </thead>
                <tbody>
                  <tr>
                   <td> {userId} </td>
                   <td> {description} </td>
                   <td> {price}</td>
                   <td> {points} pt</td>
                 </tr>
                </tbody>
              </table>
            </div>
            <div class="modal-footer">
              <button class="btn btn-light"
                      data-dismiss="modal"
                      type="button"
                      onClick={this.props.onCancelButtonClicked}>閉じる
              </button>
              <button class="btn btn-light"
                      data-dismiss="modal"
                      type="button"
                      onClick={this.props.onOkButtonClicked}>実行
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/**
 * ポイント付与後の結果確認用ダイアログ
 */
export class AddPaidPointsResultDialog extends React.Component {
  render() {
    const {addPaidPointsResult} = this.props;
    console.log('AddPaidPointsResultDialog', this, addPaidPointsResult);

    return (
      <div class="modal fade" id="add_paid_points_dialog" role="dialog">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">有料ポイント付与結果確認</h4>
            </div>
            <div class="modal-body">
              <table class="table table-bordered">
                <thead>
                <tr>
                  <th> 取引きID</th>
                  <th> 付与結果</th>
                  <th> エラー理由</th>
                </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> {addPaidPointsResult.data.dealId} </td>
                    <td> {(addPaidPointsResult.meta.status == 200) ? "成功" : "失敗"} </td>
                    <td> {addPaidPointsResult.meta.errorMessage} </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="modal-footer">
              <button class="btn btn-light"
                      data-dismiss="modal"
                      type="button"
                      onClick={this.props.onOkButtonClicked}>OK
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
