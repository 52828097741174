import './style.scss';
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom';

import BackendApiService from 'service/api/BackendApiService';
import ErrorHandleService from 'error/ErrorHandleService';
import { ManageApiError } from 'error/ManageError';

class EventListener extends React.Component {
  static _onUpdateButtonClick(e) {
    // TODO: 金券情報更新できるようにする
  }

  static _errorHandler(error) {
    if (!(error instanceof ManageApiError)) { throw error; }
    const [ status, body, errorCode, errorSubCode, errorMessage ] = [ error.getStatusCode(), error.getResponseBody(), error.getErrorCode(), error.getErrorSubCode(), error.getErrorMessage() ];
    console.debug('error handler.', status, body, errorCode, errorSubCode, errorMessage);
    switch (status) {
      default:
        this.props.mainComponent.setState({ showAlert: true, alertContent: 'エラーが発生しました。' });
        break;
    }
  }
  static _onVoucherIdChange(e) {
    this.setState({ voucherId: e.target.value });
  }
  static _onVoucherNameChange(e) {
    this.setState({ voucherName: e.target.value });
  }
  static _onPaidPointsChange(e) {
    this.setState({ paidPoints: e.target.value });
  }
  static _onFreePointsChange(e) {
    this.setState({ freePoints: e.target.value });
  }
  static _onPriceChange(e) {
    this.setState({ price: e.target.value });
  }
  static _onProviderIdChange(e) {
    this.setState({ providerId: e.target.value });
  }
  static _onProviderPaymentMethodGroupIdChange(e) {
    this.setState({ providerPaymentMethodGroupId: e.target.value });
  }
  static _onAvailableStartedAtChange(e) {
    this.setState({ availableStartedAt: e.target.value });
  }
  static _onAvailableEndedAtChange(e) {
    this.setState({ availableEndedAt: e.target.value });
  }
  static _onAppleProductIdChange(e) {
    this.setState({ appleProductId: e.target.value });
  }
  static _onAppleBundleIdChange(e) {
    this.setState({ appleBundleId: e.target.value });
  }
  static _onAppleProductTypeChange(e) {
    this.setState({ appleProductType: e.target.value });
  }
  static _onGoogleProductIdChange(e) {
    this.setState({ googleProductId: e.target.value });
  }
  static _onGooglePackageNameChange(e) {
    this.setState({ googlePackageName: e.target.value });
  }
  static _onGooglePurchaseTypeChange(e) {
    this.setState({ googlePurchaseType: e.target.value });
  }
};

export default class EditVoucherPage extends React.Component {

  constructor(props) {
    super(props);
    const assortVoucher = this.props.mainComponent.state.assortVouchers.find((a) => { return a.assortId == this.props.assortId; });
    const { vouchers, isVouchersLoading } = (assortVoucher != null) ? assortVoucher : { vouchers: [], isVouchersLoading: true };
    const voucher = vouchers.find((e) => { return e.id == this.props.voucherId; });
    if (voucher != null) {
      this.state = {
        isEditVoucherCompleted: false,
        voucherId: voucher.id,
        voucherName: voucher.name,
        paidPoints: voucher.paidPoints,
        freePoints: voucher.freePoints,
        price: voucher.price,
        availableStartedAt: voucher.availableStartedAt,
        availableEndedAt: voucher.availableEndedAt,
        appleProductId: voucher.appleProductId,
        appleBundleId: voucher.appleBundleId,
        appleProductType: voucher.appleProductType,
        googleProductId: voucher.googleProductId,
        googlePackageName: voucher.googlePackageName,
        googlePurchaseType: voucher.googlePurchaseType
      };
    } else {
      this.state = {
        isEditVoucherCompleted: false
      };
    }

  }

  render() {
    console.log('EditVoucherPage', this);
    const { isEditVoucherCompleted } = this.state;
    const assortVoucher = this.props.mainComponent.state.assortVouchers.find((a) => { return a.assortId == this.props.assortId; });
    const { vouchers, isVouchersLoading } = (assortVoucher != null) ? assortVoucher : { vouchers: [], isVouchersLoading: true };

    if (isEditVoucherCompleted) {
      return ( <Redirect to={ `/apps/mmt/assorts/${this.props.assortId}/vouchers` }/> );
    }

    if (isVouchersLoading) {
      return ( <Redirect to={ `/apps/mmt/assorts/${this.props.assortId}/vouchers` }/> );
    }

    const voucher = vouchers.find((e) => { return e.id == this.props.voucherId; });
    if (voucher == null) {
      return ( <div>存在しない金券です。</div> );
    }

    return (
      <div class="p-edit-voucher-page container-fluid">
        <div class="p-edit-voucher-page__header">
          <div class="page-header">
            <h1><small class="text-muted">金券編集ページ</small></h1>
          </div>
        </div>
        <div class="p-edit-voucher-page__body row">
          <div class="p-edit-voucher-page__main container-fluid">
            <form>
              <div class="form-group row">
                <label for="inputVoucherId" class="col-sm-2 col-form-label text-right font-weight-bold">金券ID</label>
                <div class="col-sm-7">
                  <input type="text"
                         class="form-control"
                         id="inputVoucherId"
                         placeholder="例: test_voucher_001"
                         value={this.state.voucherId}
                         onChange={EventListener._onVoucherIdChange.bind(this)} />
                </div>
              </div>
              <div class="form-group row">
                <label for="inputVoucherName" class="col-sm-2 col-form-label text-right font-weight-bold">金券名</label>
                <div class="col-sm-7">
                  <input type="text"
                         class="form-control"
                         id="inputVoucherName"
                         placeholder="例: テスト用金券"
                         value={this.state.voucherName}
                         onChange={EventListener._onVoucherNameChange.bind(this)} />
                </div>
              </div>
              <div class="form-group row">
                <label for="inputPaidPoints" class="col-sm-2 col-form-label text-right font-weight-bold">有料ポイント数</label>
                <div class="col-sm-7">
                  <input type="text"
                         class="form-control"
                         id="inputPaidPoints"
                         placeholder="例: 200"
                         value={this.state.paidPoints}
                         onChange={EventListener._onPaidPointsChange.bind(this)} />
                </div>
              </div>
              <div class="form-group row">
                <label for="inputFreePoints" class="col-sm-2 col-form-label text-right font-weight-bold">無料ポイント数</label>
                <div class="col-sm-7">
                  <input type="text"
                         class="form-control"
                         id="inputFreePoints"
                         placeholder="例: 100"
                         value={this.state.freePoints}
                         onChange={EventListener._onFreePointsChange.bind(this)} />
                </div>
              </div>
              <div class="form-group row">
                <label for="inputPrice" class="col-sm-2 col-form-label text-right font-weight-bold">価格(円)</label>
                <div class="col-sm-7">
                  <input type="number"
                         class="form-control"
                         id="inputPrice"
                         placeholder="例: 1000"
                         value={this.state.price}
                         onChange={EventListener._onPriceChange.bind(this)} />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label text-right font-weight-bold">利用可能日時(optional)</label>
                <label class="col-sm-1 col-form-label text-right font-weight-bold">利用開始:</label>
                <div class="col-sm-3">
                  <input type="datetime-local"
                         class="form-control"
                         placeholder="例 2017-01-01T00:00:00"
                         value={this.state.availableStartedAt}
                         onChange={EventListener._onAvailableStartedAtChange.bind(this)}/>
                </div>
                <label class="col-sm-1 col-form-label text-right font-weight-bold">利用終了:</label>
                <div class="col-sm-3">
                  <input type="datetime-local"
                         class="form-control"
                         placeholder="例 2017-01-01T00:00:00"
                         value={this.state.availableEndedAt}
                         onChange={EventListener._onAvailableEndedAtChange.bind(this)}/>
                </div>
              </div>

              <div>
                <h4>Apple情報</h4>
                <div class="offset-sm-2 col-sm-10">
                  <p>Apple In-App Purchaseを利用する場合に以下を入力してください。</p>
                </div>

                <div class="form-group row">
                  <label for="inputAppleProductId" class="col-sm-2 col-form-label text-right font-weight-bold">プロダクトID</label>
                  <div class="col-sm-7">
                    <input type="text"
                           class="form-control"
                           id="inputAppleProductId"
                           placeholder="例: jp.co.dwango.testservice.point.500"
                           value={this.state.appleProductId}
                           onChange={EventListener._onAppleProductIdChange.bind(this)} />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="inputAppleBundleId" class="col-sm-2 col-form-label text-right font-weight-bold">バンドルID</label>
                  <div class="col-sm-7">
                    <input type="text"
                           class="form-control"
                           id="inputAppleBundleId"
                           placeholder="例: jp.co.dwango.testservice"
                           value={this.state.appleBundleId}
                           onChange={EventListener._onAppleBundleIdChange.bind(this)} />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="inputAppleProductType" class="col-sm-2 col-form-label text-right font-weight-bold">プロダクトタイプ</label>
                  <div class="col-sm-7">
                    <input type="text"
                           class="form-control"
                           id="inputAppleProductType"
                           placeholder="例: apple_consumable"
                           value={this.state.appleProductType}
                           onChange={EventListener._onAppleProductTypeChange.bind(this)} />
                  </div>
                </div>
              </div>
              <h4>Google Play情報</h4>
              <div class="offset-sm-2 col-sm-10">
                <p>Goole Play In-app Billingを利用する場合に以下を入力してください。</p>
              </div>
              <div class="form-group row">
                <label for="inputGoogleProductId" class="col-sm-2 col-form-label text-right font-weight-bold">プロダクトID</label>
                <div class="col-sm-7">
                  <input type="text"
                         class="form-control"
                         id="inputGoogleProductId"
                         placeholder="例: jp.co.dwango.testservice.product_id_200"
                         value={this.state.googleProductId}
                         onChange={EventListener._onGoogleProductIdChange.bind(this)} />
                </div>
              </div>
              <div class="form-group row">
                <label for="inputGooglePackageName" class="col-sm-2 col-form-label text-right font-weight-bold">パッケージ名</label>
                <div class="col-sm-7">
                  <input type="text"
                         class="form-control"
                         id="inputGooglePackageName"
                         placeholder="例: jp.co.dwango.testservice"
                         value={this.state.googlePackageName}
                         onChange={EventListener._onGooglePackageNameChange.bind(this)} />
                </div>
              </div>
              <div class="form-group row">
                <label for="inputGooglePurchaseType" class="col-sm-2 col-form-label text-right font-weight-bold">決済方法</label>
                <div class="col-sm-7">
                  <input type="text"
                         class="form-control"
                         id="inputGooglePurchaseType"
                         placeholder="例: managed_user"
                         value={this.state.googlePurchaseType}
                         onChange={EventListener._onGooglePurchaseTypeChange.bind(this)} />
                </div>
              </div>

              <div class="form-group row">
                <div class="offset-sm-1 col-sm-10">
                  <button type="button"
                          class="btn btn-primary btn-lg"
                          onClick={EventListener._onUpdateButtonClick.bind(this)}>
                    更新(TODO)
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
