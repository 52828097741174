import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom';

import SiteHeader from 'components/SiteHeader/SiteHeader';
import PageSideBar from 'components/PageSideBar/PageSideBar';
import LoadingIcon from 'components/LoadingIcon/LoadingIcon';

import BackendApiService from 'service/api/BackendApiService';
import ErrorHandleService from 'error/ErrorHandleService';
import { ManageApiError } from 'error/ManageError';
import MmtPageHeader from 'components/PageHeader/MmtPageHeader';
import BalancePage from 'components/BalancePage/BalancePage';
import SearchDealPage from 'components/SearchDealPage/SearchDealPage';
import AddFreePointsPage from 'components/AddFreePointsPage/AddFreePointsPage';
import InvalidatePointsPage from 'components/InvalidatePointsPage/InvalidatePointsPage';
// assort
import AssortPageHeader from 'components/PageHeader/AssortPageHeader';
import AssortListPage from 'components/AssortListPage/AssortListPage';
import CreateAssortPage from 'components/CreateAssortPage/CreateAssortPage';
import EditAssortPage from 'components/EditAssortPage/EditAssortPage';
// provider
import ProviderPageHeader from 'components/PageHeader/ProviderPageHeader';
import ProviderListPage from 'components/ProviderListPage/ProviderListPage';
import CreateProviderPage from 'components/CreateProviderPage/CreateProviderPage';
import EditProviderPage from 'components/EditProviderPage/EditProviderPage';
// event
import EventPageHeader from 'components/PageHeader/EventPageHeader';
import EventListPage from 'components/EventListPage/EventListPage';
import CreateEventPage from 'components/CreateEventPage/CreateEventPage';
import EditEventPage from 'components/EditEventPage/EditEventPage';
// product
import ProductPageHeader from 'components/PageHeader/ProductPageHeader';
import ProductListPage from 'components/ProductListPage/ProductListPage';
import CreateProductPage from 'components/CreateProductPage/CreateProductPage';
import EditProductPage from 'components/EditProductPage/EditProductPage';
// voucher
import VoucherPageHeader from 'components/PageHeader/VoucherPageHeader';
import VoucherListPage from 'components/VoucherListPage/VoucherListPage';
import CreateVoucherPage from 'components/CreateVoucherPage/CreateVoucherPage';
import EditVoucherPage from 'components/EditVoucherPage/EditVoucherPage';

const APP_TYPE = 'mmt';

/**
 * MMTアプリケーション
 */
export default class MmtApp extends React.Component {

  componentDidMount() {
    const mainComponent = this.props.mainComponent;
    const { isAccountInfoLoading, isAccountRolesLoading, isAssortsLoading } = mainComponent.state;
    if (isAccountInfoLoading && isAccountRolesLoading && isAssortsLoading) {
      Promise.all([
        BackendApiService.getUserProfile(),
        BackendApiService.getSessionUserRoles(),
        BackendApiService.getAssorts()
      ]).then(([ userResponse, userRolesResponse, assortsResponse ]) => {
        console.trace('all api succeeded.', userResponse, userRolesResponse, assortsResponse);
        mainComponent.setState({ isAccountInfoLoading: false, account: userResponse.data, isAccountRolesLoading: false, accountRoles: userRolesResponse.data, isAssortsLoading: false, assorts: assortsResponse.data });
      }).catch((error) => {
        console.error('api failed.', error);
        ErrorHandleService.handleError(MmtApp._errorHandler.bind(this), error);
      });
    }
  }

  static _errorHandler(error) {
    if (!(error instanceof ManageApiError)) { throw error; }
    const [ status, body, errorCode, errorSubCode, errorMessage ] = [ error.getStatusCode(), error.getResponseBody(), error.getErrorCode(), error.getErrorSubCode(), error.getErrorMessage() ];
    console.debug('error handler.', status, body, errorCode, errorSubCode, errorMessage);
    switch (status) {
      case 400:
        throw error;
      default:
        throw error;
    }
  }

  // TODO: css部分をファイルに移す
  _viewTemplate(siteHeader, pageHeader, pageSideBar, pageBody) {
    return (
      <div class="container-fluid d-flex flex-column">
        {siteHeader}
        <div class="p-page container-fluid">
          {pageHeader}
          <div class="p-page__main row">
            <div class="p-page__side-bar col-sm-2">
              {pageSideBar}
            </div>
            <div class="p-page__body col-sm-10">
              {pageBody}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    console.trace('MmtApp', this);
    const { isAccountInfoLoading, isAccountRolesLoading, isAssortsLoading } = this.props.mainComponent.state;

    if (isAccountInfoLoading || isAccountRolesLoading || isAssortsLoading) {
      return ( <LoadingIcon/> );
    }

    const { mainComponent } = this.props;
    const { account } = mainComponent.state;

    return (
      <Switch>
        <Route path="/apps/:appId/assorts/:assortId/balance" render={props => {
            const assortId = props.match.params.assortId;
            // ユーザーのポイント残高確認
            const pageType = 'BALANCE';
            const siteHeader = (<SiteHeader account={account}/>);
            const pageHeader = (<MmtPageHeader {...props} pageType={pageType} mainComponent={mainComponent} assortId={assortId}/>);
            const pageSideBar = (<PageSideBar {...props} mainComponent={mainComponent}/>);
            const pageBody = (<BalancePage {...this.props} {...props} assortId={assortId}/>);
            return this._viewTemplate(siteHeader, pageHeader, pageSideBar, pageBody);
          }}/>
        <Route path="/apps/:appId/assorts/:assortId/search-deal" render={props => {
            const assortId = props.match.params.assortId;
            // 取引き履歴検索
            const pageType = 'SEARCH_DEAL';
            const siteHeader = (<SiteHeader account={account}/>);
            const pageHeader = (<MmtPageHeader {...props} pageType={pageType} mainComponent={mainComponent} assortId={assortId}/>);
            const pageSideBar = (<PageSideBar {...props} mainComponent={mainComponent}/>);
            const pageBody = (<SearchDealPage {...this.props} {...props} assortId={assortId}/>);
            return this._viewTemplate(siteHeader, pageHeader, pageSideBar, pageBody);
          }}/>
        <Route path="/apps/:appId/assorts/:assortId/add-free-points" render={props => {
            const assortId = props.match.params.assortId;
            // 無料ポイント付与
            const pageType = 'ADD_FREE_POINTS';
            const siteHeader = (<SiteHeader account={account}/>);
            const pageHeader = (<MmtPageHeader {...props} pageType={pageType} mainComponent={mainComponent} assortId={assortId}/>);
            const pageSideBar = (<PageSideBar {...props} mainComponent={mainComponent}/>);
            const pageBody = (<AddFreePointsPage {...this.props} {...props} assortId={assortId}/>);
            return this._viewTemplate(siteHeader, pageHeader, pageSideBar, pageBody);
          }}/>
        <Route path="/apps/:appId/assorts/:assortId/invalidate-points" render={props => {
            const assortId = props.match.params.assortId;
            // ポイント失効
            const pageType = 'INVALIDATE_POINTS';
            const siteHeader = (<SiteHeader account={account}/>);
            const pageHeader = (<MmtPageHeader {...props} pageType={pageType} mainComponent={mainComponent} assortId={assortId}/>);
            const pageSideBar = (<PageSideBar {...props} mainComponent={mainComponent}/>);
            const pageBody = (<InvalidatePointsPage {...this.props} {...props} assortId={assortId}/>);
            return this._viewTemplate(siteHeader, pageHeader, pageSideBar, pageBody);
          }}/>
        <Route path="/apps/:appId/assorts" exact render={props => {
            // ポイント種別一覧
            const pageType = 'LIST';
            const siteHeader = (<SiteHeader account={account}/>);
            const pageHeader = (<AssortPageHeader {...props} pageType={pageType} mainComponent={mainComponent}/>);
            const pageSideBar = (<PageSideBar {...props} mainComponent={mainComponent}/>);
            const pageBody = (<AssortListPage {...this.props} {...props}/>);
            return this._viewTemplate(siteHeader, pageHeader, pageSideBar, pageBody);
          }}/>
        <Route path="/apps/:appId/assorts/new" exact render={props => {
            // ポイント種別作成
            const pageType = 'CREATE';
            const siteHeader = (<SiteHeader account={account}/>);
            const pageHeader = (<AssortPageHeader {...props} pageType={pageType} mainComponent={mainComponent}/>);
            const pageSideBar = (<PageSideBar {...props} mainComponent={mainComponent}/>);
            const pageBody = (<CreateAssortPage {...this.props} {...props}/>);
            return this._viewTemplate(siteHeader, pageHeader, pageSideBar, pageBody);
          }}/>
        <Route path="/apps/:appId/assorts/:assortId" exact render={props => {
            // ポイント種別編集
            const assortId = props.match.params.assortId;
            const pageType = 'EDIT';
            const siteHeader = (<SiteHeader account={account}/>);
            const pageHeader = (<AssortPageHeader {...props} pageType={pageType} mainComponent={mainComponent} assortId={assortId}/>);
            const pageSideBar = (<PageSideBar {...props} mainComponent={mainComponent}/>);
            const pageBody = (<EditAssortPage {...this.props} {...props} assortId={assortId}/>);
            return this._viewTemplate(siteHeader, pageHeader, pageSideBar, pageBody);
          }}/>
        <Route path="/apps/:appId/providers" exact render={props => {
            // プロバイダ一覧
            const pageType = 'LIST';
            const siteHeader = (<SiteHeader account={account}/>);
            const pageHeader = (<ProviderPageHeader {...props} pageType={pageType} mainComponent={mainComponent}/>);
            const pageSideBar = (<PageSideBar {...props} mainComponent={mainComponent}/>);
            const pageBody = (<ProviderListPage {...this.props} {...props}/>);
            return this._viewTemplate(siteHeader, pageHeader, pageSideBar, pageBody);
          }}/>
        <Route path="/apps/:appId/providers/new" exact render={props => {
            // プロバイダ作成
            const pageType = 'CREATE';
            const siteHeader = (<SiteHeader account={account}/>);
            const pageHeader = (<ProviderPageHeader {...props} pageType={pageType} mainComponent={mainComponent}/>);
            const pageSideBar = (<PageSideBar {...props} mainComponent={mainComponent}/>);
            const pageBody = (<CreateProviderPage {...this.props} {...props}/>);
            return this._viewTemplate(siteHeader, pageHeader, pageSideBar, pageBody);
          }}/>
        <Route path="/apps/:appId/providers/:providerId" exact render={props => {
            // プロバイダ編集
            const providerId = props.match.params.providerId;
            const pageType = 'EDIT';
            const siteHeader = (<SiteHeader account={account}/>);
            const pageHeader = (<ProviderPageHeader {...props} pageType={pageType} mainComponent={mainComponent} providerId={providerId}/>);
            const pageSideBar = (<PageSideBar {...props} mainComponent={mainComponent}/>);
            const pageBody = (<EditProviderPage {...this.props} {...props} providerId={providerId}/>);
            return this._viewTemplate(siteHeader, pageHeader, pageSideBar, pageBody);
          }}/>
        <Route path="/apps/:appId/assorts/:assortId/events" exact render={props => {
            // イベント一覧
            const assortId = props.match.params.assortId;
            const pageType = 'LIST';
            const siteHeader = (<SiteHeader account={account}/>);
            const pageHeader = (<EventPageHeader {...props} pageType={pageType} mainComponent={mainComponent} assortId={assortId}/>);
            const pageSideBar = (<PageSideBar {...props} mainComponent={mainComponent}/>);
            const pageBody = (<EventListPage {...this.props} {...props} assortId={assortId}/>);
            return this._viewTemplate(siteHeader, pageHeader, pageSideBar, pageBody);
          }}/>
        <Route path="/apps/:appId/assorts/:assortId/events/new" exact render={props => {
            // イベント作成
            const assortId = props.match.params.assortId;
            const pageType = 'CREATE';
            const siteHeader = (<SiteHeader account={account}/>);
            const pageHeader = (<EventPageHeader {...props} pageType={pageType} mainComponent={mainComponent} assortId={assortId}/>);
            const pageSideBar = (<PageSideBar {...props} mainComponent={mainComponent}/>);
            const pageBody = (<CreateEventPage {...this.props} {...props} assortId={assortId}/>);
            return this._viewTemplate(siteHeader, pageHeader, pageSideBar, pageBody);
          }}/>
        <Route path="/apps/:appId/assorts/:assortId/events/:eventId" exact render={props => {
            // イベント編集
            const assortId = props.match.params.assortId;
            const eventId = props.match.params.eventId;
            const pageType = 'EDIT';
            const siteHeader = (<SiteHeader account={account}/>);
            const pageHeader = (<EventPageHeader {...props} pageType={pageType} mainComponent={mainComponent} assortId={assortId} eventId={eventId}/>);
            const pageSideBar = (<PageSideBar {...props} mainComponent={mainComponent}/>);
            const pageBody = (<EditEventPage {...this.props} {...props} assortId={assortId} eventId={eventId}/>);
            return this._viewTemplate(siteHeader, pageHeader, pageSideBar, pageBody);
          }}/>
        <Route path="/apps/:appId/assorts/:assortId/products" exact render={props => {
            // 商品一覧
            const assortId = props.match.params.assortId;
            const pageType = 'LIST';
            const siteHeader = (<SiteHeader account={account}/>);
            const pageHeader = (<ProductPageHeader {...props} pageType={pageType} mainComponent={mainComponent} assortId={assortId}/>);
            const pageSideBar = (<PageSideBar {...props} mainComponent={mainComponent}/>);
            const pageBody = (<ProductListPage {...this.props} {...props} assortId={assortId}/>);
            return this._viewTemplate(siteHeader, pageHeader, pageSideBar, pageBody);
          }}/>
        <Route path="/apps/:appId/assorts/:assortId/products/new" exact render={props => {
            // 商品作成
            const assortId = props.match.params.assortId;
            const pageType = 'CREATE';
            const siteHeader = (<SiteHeader account={account}/>);
            const pageHeader = (<ProductPageHeader {...props} pageType={pageType} mainComponent={mainComponent} assortId={assortId}/>);
            const pageSideBar = (<PageSideBar {...props} mainComponent={mainComponent}/>);
            const pageBody = (<CreateProductPage {...this.props} {...props} assortId={assortId}/>);
            return this._viewTemplate(siteHeader, pageHeader, pageSideBar, pageBody);
          }}/>
        <Route path="/apps/:appId/assorts/:assortId/products/:productId" exact render={props => {
            // 商品編集
            const assortId = props.match.params.assortId;
            const productId = props.match.params.productId;
            const pageType = 'EDIT';
            const siteHeader = (<SiteHeader account={account}/>);
            const pageHeader = (<ProductPageHeader {...props} pageType={pageType} mainComponent={mainComponent} assortId={assortId} productId={productId}/>);
            const pageSideBar = (<PageSideBar {...props} mainComponent={mainComponent}/>);
            const pageBody = (<EditProductPage {...this.props} {...props} assortId={assortId} productId={productId}/>);
            return this._viewTemplate(siteHeader, pageHeader, pageSideBar, pageBody);
          }}/>
        <Route path="/apps/:appId/assorts/:assortId/vouchers" exact render={props => {
            // 金券一覧
            const assortId = props.match.params.assortId;
            const pageType = 'LIST';
            const siteHeader = (<SiteHeader account={account}/>);
            const pageHeader = (<VoucherPageHeader {...props} pageType={pageType} mainComponent={mainComponent} assortId={assortId}/>);
            const pageSideBar = (<PageSideBar {...props} mainComponent={mainComponent}/>);
            const pageBody = (<VoucherListPage {...this.props} {...props} assortId={assortId}/>);
            return this._viewTemplate(siteHeader, pageHeader, pageSideBar, pageBody);
          }}/>
        <Route path="/apps/:appId/assorts/:assortId/vouchers/new" exact render={props => {
            // 金券作成
            const assortId = props.match.params.assortId;
            const pageType = 'CREATE';
            const siteHeader = (<SiteHeader account={account}/>);
            const pageHeader = (<VoucherPageHeader {...props} pageType={pageType} mainComponent={mainComponent} assortId={assortId}/>);
            const pageSideBar = (<PageSideBar {...props} mainComponent={mainComponent}/>);
            const pageBody = (<CreateVoucherPage {...this.props} {...props} assortId={assortId}/>);
            return this._viewTemplate(siteHeader, pageHeader, pageSideBar, pageBody);
          }}/>
        <Route path="/apps/:appId/assorts/:assortId/vouchers/:voucherId" exact render={props => {
            // 金券編集
            const assortId = props.match.params.assortId;
            const voucherId = props.match.params.voucherId;
            const pageType = 'EDIT';
            const siteHeader = (<SiteHeader account={account}/>);
            const pageHeader = (<VoucherPageHeader {...props} pageType={pageType} mainComponent={mainComponent} assortId={assortId} voucherId={voucherId}/>);
            const pageSideBar = (<PageSideBar {...props} mainComponent={mainComponent}/>);
            const pageBody = (<EditVoucherPage {...this.props} {...props} assortId={assortId} voucherId={voucherId}/>);
            return this._viewTemplate(siteHeader, pageHeader, pageSideBar, pageBody);
          }}/>
        <Redirect from="/apps/mmt" to="/apps/mmt/assorts"/>
      </Switch>
    );
  }
}

MmtApp.APP_TYPE = APP_TYPE;
