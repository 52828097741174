import './style.scss';
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom';

import BackendApiService from 'service/api/BackendApiService';
import ErrorHandleService from 'error/ErrorHandleService';
import { ManageApiError } from 'error/ManageError';

class EventListener extends React.Component {
  static _onUpdateButtonClick(e) {
    const { productId, productName } = this.state;
    // TODO: 更新
  }

  static _errorHandler(error) {
    if (!(error instanceof ManageApiError)) { throw error; }
    const [ status, body, errorCode, errorSubCode, errorMessage ] = [ error.getStatusCode(), error.getResponseBody(), error.getErrorCode(), error.getErrorSubCode(), error.getErrorMessage() ];
    console.debug('error handler.', status, body, errorCode, errorSubCode, errorMessage);
    switch (status) {
      default:
        this.props.mainComponent.setState({ showAlert: true, alertContent: 'エラーが発生しました。' });
        break;
    }
  }

  static _onProductIdChange(e) {
    this.setState({ productId: e.target.value });
  }

  static _onProductNameChange(e) {
    this.setState({ productName: e.target.value });
  }
};

export default class EditProductPage extends React.Component {

  constructor(props) {
    super(props);
    const assortProduct = this.props.mainComponent.state.assortProducts.find((a) => { return a.assortId == this.props.assortId; });
    const { products, isProductsLoading } = (assortProduct != null) ? assortProduct : { products: [], isProductsLoading: true };
    const product = products.find((e) => { return e.id == this.props.productId; });
    if (product != null) {
      this.state = {
        isEditProductCompleted: false,
        productId: product.id,
        productName: product.name
      };
    } else {
      this.state = {
        isEditProductCompleted: false
      };
    }
  }

  render() {
    console.log('EditProductPage', this);
    const { isEditProductCompleted } = this.state;
    const assortProduct = this.props.mainComponent.state.assortProducts.find((a) => { return a.assortId == this.props.assortId; });
    const { products, isProductsLoading } = (assortProduct != null) ? assortProduct : { products: [], isProductsLoading: true };

    if (isEditProductCompleted) {
      return ( <Redirect to={ `/apps/mmt/assorts/${this.props.assortId}/products` }/> );
    }

    if (isProductsLoading) {
      return ( <Redirect to={ `/apps/mmt/assorts/${this.props.assortId}/products` }/> );
    }

    const product = products.find((e) => { return e.id == this.props.productId; });
    if (product == null) {
      return ( <div>存在しない商品です。</div> );
    }

    return (
      <div class="p-edit-product-page container-fluid">
        <div class="p-edit-product-page__header">
          <div class="page-header">
            <h1><small class="text-muted">商品編集ページ</small></h1>
          </div>
        </div>
        <div class="p-edit-product-page__body row">
          <div class="p-edit-product-page__main container-fluid">
            <form>
              <div class="form-group row">
                <label for="inputProductId" class="col-sm-2 col-form-label text-right font-weight-bold">商品ID</label>
                <div class="col-sm-7">
                  <input type="text"
                         class="form-control"
                         id="inputProductId"
                         placeholder="例: test_product_001"
                         value={this.state.productId}
                         onChange={EventListener._onProductIdChange.bind(this)} />
                </div>
              </div>
              <div class="form-group row">
                <label for="inputProductName" class="col-sm-2 col-form-label text-right font-weight-bold">商品名</label>
                <div class="col-sm-7">
                  <input type="text"
                         class="form-control"
                         id="inputProductName"
                         placeholder="例: テスト用商品"
                         value={this.state.productName}
                         onChange={EventListener._onProductNameChange.bind(this)} />
                </div>
              </div>
              <div class="form-group row">
                <div class="offset-sm-1 col-sm-10">
                  <button type="button"
                          class="btn btn-primary btn-lg"
                          onClick={EventListener._onUpdateButtonClick.bind(this)}>
                    更新(TODO)
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
