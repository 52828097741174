/**
 * バックエンドのIRS系のAPIにアクセスするサービス
 */
import config from 'config';
import url from 'url';
import SessionService from 'service/SessionService';
import HandleApiResponseService from 'service/api/HandleApiResponseService';
import ApiService from 'service/api/ApiService';

export default class IrsApiService {

  static _getHeaders() {
    const session = this.sessionService.load();
    return { 'X-MMT-Session': session };
  }

  static _getUrl(pathname = '', query = {}) {
    const urlObj = Object.assign({ pathname, query }, config.manage.backendServerApi);
    return url.format(urlObj);
  }

  /**
   * 問い合わせ情報を取得する
   */
  static getInquiry(inquiryId) {
    const path = `/api/v1/inquiries/${inquiryId}`;
    const promise = this.apiService.get(this._getUrl(path), this._getHeaders());
    return this.handleApiResponseService.handlePromise(promise);
  }
}

IrsApiService.sessionService = SessionService;
IrsApiService.handleApiResponseService = HandleApiResponseService;
IrsApiService.apiService = ApiService;
