import { ManageApiError } from 'error/ManageError';

/**
 * 管理ツールのAPIのレスポンスを処理するサービスクラス
 */
export default class HandleApiResponseService {
  /**
   * HTTPステータスが200の場合は成功して、それ以外の場合は失敗し、ManageApiErrorを返すプロミスを返す
   */
  static handlePromise(promise) {
    return new Promise((resolve, reject) => {
      promise
        .then((response) => {
          switch (response.status) {
          case 200:
            const json = response.json();
            console.log('api succeeded. response json:', json);
            resolve(json);
            break;
          default:
            response.json().then((json) => {
              const error = new ManageApiError(response, json);
              console.error('api failed.', error);
              reject(error);
            });
            break;
          }
        });
    });
  }
}
