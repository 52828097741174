import './style.scss';
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom';

import BackendApiService from 'service/api/BackendApiService';
import ErrorHandleService from 'error/ErrorHandleService';
import { ManageApiError } from 'error/ManageError';

class EventListener extends React.Component {
  static _onRegisterButtonClick(e) {
    const { eventId, eventName, availableStartedAt, availableEndedAt, providerId } = this.state;
    const providerIdInt = parseInt(providerId, 10);

    Promise.all([
      BackendApiService.createAndRegisterEvent(this.props.assortId, providerIdInt, eventId, eventName, availableStartedAt, availableEndedAt)
    ]).then(([ response ]) => {
      console.trace('create event api succeeded', response);
      setTimeout(() => { location.href = `/apps/mmt/assorts/${this.props.assortId}/events`; }, 500);
    }).catch((error) => {
      console.error('create event api failed.', error);
      ErrorHandleService.handleError(EventListener._errorHandler.bind(this), error);
    });
  }

  static _errorHandler(error) {
    if (!(error instanceof ManageApiError)) { throw error; }
    const [ status, body, errorCode, errorSubCode, errorMessage ] = [ error.getStatusCode(), error.getResponseBody(), error.getErrorCode(), error.getErrorSubCode(), error.getErrorMessage() ];
    console.debug('error handler.', status, body, errorCode, errorSubCode, errorMessage);
    switch (status) {
      default:
        this.props.mainComponent.setState({ showAlert: true, alertContent: 'エラーが発生しました。' });
        break;
    }
  }

  static _onEventIdChange(e) {
    this.setState({ eventId: e.target.value });
  }

  static _onEventNameChange(e) {
    this.setState({ eventName: e.target.value });
  }

  static _onProviderIdChange(e) {
    this.setState({ providerId: e.target.value });
  }

  static _onAvailableStartedAtChange(e) {
    this.setState({ availableStartedAt: e.target.value });
  }

  static _onAvailableEndedAtChange(e) {
    this.setState({ availableEndedAt: e.target.value });
  }
};

export default class CreateEventPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isCreateEventCompleted: false
    };
  }

  render() {
    console.log('CreateEventPage', this);
    const { isCreateEventCompleted } = this.state;

    if (isCreateEventCompleted) {
      return ( <Redirect to={ '/apps/mmt/events' }/> );
    }

    return (
      <div class="p-create-event-page container-fluid">
        <div class="p-create-event-page__header">
          <div class="page-header">
            <h1><small class="text-muted">イベント登録ページ</small></h1>
          </div>
        </div>
        <div class="p-create-event-page__body row">
          <div class="p-create-event-page__main container-fluid">
            <form>
              <div class="form-group row">
                <label for="inputEventId" class="col-sm-2 col-form-label text-right font-weight-bold">イベントID</label>
                <div class="col-sm-7">
                  <input type="text"
                         class="form-control"
                         id="inputEventId"
                         placeholder="例: test_event_001"
                         onChange={EventListener._onEventIdChange.bind(this)} />
                </div>
              </div>
              <div class="form-group row">
                <label for="inputEventName" class="col-sm-2 col-form-label text-right font-weight-bold">イベント名</label>
                <div class="col-sm-7">
                  <input type="text"
                         class="form-control"
                         id="inputEventName"
                         placeholder="例: テスト用イベント"
                         onChange={EventListener._onEventNameChange.bind(this)} />
                </div>
              </div>
              <div class="form-group row">
                <label for="inputProviderId" class="col-sm-2 col-form-label text-right font-weight-bold">利用登録先プロバイダID</label>
                <div class="col-sm-7">
                  <input type="text"
                         class="form-control"
                         id="inputProviderId"
                         placeholder="例: 1"
                         onChange={EventListener._onProviderIdChange.bind(this)} />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label text-right font-weight-bold">利用可能日時(optional)</label>
                <label class="col-sm-1 col-form-label text-right font-weight-bold">利用開始:</label>
                <div class="col-sm-3">
                  <input type="datetime-local"
                         class="form-control"
                         placeholder="例 2017-01-01T00:00:00"
                         value={this.state.availableStartedAt}
                         onChange={EventListener._onAvailableStartedAtChange.bind(this)}/>
                </div>
                <label class="col-sm-1 col-form-label text-right font-weight-bold">利用終了:</label>
                <div class="col-sm-3">
                  <input type="datetime-local"
                         class="form-control"
                         placeholder="例 2017-01-01T00:00:00"
                         value={this.state.availableEndedAt}
                         onChange={EventListener._onAvailableEndedAtChange.bind(this)}/>
                </div>
              </div>
              <div class="form-group row">
                <div class="offset-sm-1 col-sm-10">
                  <button type="button"
                          class="btn btn-primary btn-lg"
                          onClick={EventListener._onRegisterButtonClick.bind(this)}>
                    登録
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
