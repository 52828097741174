export default class BootService  {
  static init() {
    if (__DEV__) {
      this._catchErrors();
    } else {
      this._suppressLog();
    }
  }

  static _suppressLog() {
    ['log', 'debug', 'info', 'warn', 'trace'].forEach((f) => {
      console[f] = () => {};
    });
  }

  static _catchErrors() {
    { // エラーの取り漏らしを阻止(ほぼデバッグ用途)
      window.onerror = (message, file, line, column, error) => {
        console.error('Unhandled error (error: ', error, ', message: ', message, ', at ', file + ':' + line);
      };
      window.addEventListener('unhandledrejection', (event) => {
        console.error('Unhandled rejection (promise: ', event.promise, ', reason: ', event.reason, ').');
      });
    }
  }
};


