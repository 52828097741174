import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom';

export default class ProfilePage extends React.Component {

  render() {
    console.log('ProfilePage', this);
    const { account, isAccountInfoLoading } = this.props.mainComponent.state;

    if (isAccountInfoLoading) {
      return ( <Redirect to={ '/apps/mmt/search-deal' }/> );
    }

    return (
      <div class="p-profile-page container-fluid">
        <div class="p-profile-page__header">
          <div class="page-header">
            <h1><small class="text-muted">プロフィール情報ページ</small></h1>
          </div>
        </div>
        <div class="p-profile-page__body row">
          <div class="p-profile-page__main container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <form>
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label text-right font-weight-bold">ID</label>
                    <div class="col-sm-9 pt-2">
                      <p>{account.id}</p>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label text-right font-weight-bold">ユーザー名</label>
                    <div class="col-sm-9 pt-2">
                      <p>{account.username}</p>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label text-right font-weight-bold">メールアドレス</label>
                    <div class="col-sm-9 pt-2">
                      <p>{account.email}</p>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
