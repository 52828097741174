import './style.scss';
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom';

import BackendApiService from 'service/api/BackendApiService';
import ErrorHandleService from 'error/ErrorHandleService';
import { ManageApiError } from 'error/ManageError';

class EventListener extends React.Component {
  static _onUpdateButtonClick(e) {
    const { eventId, eventName, availableStartedAt, availableEndedAt } = this.state;
    // TODO: 更新
  }

  static _errorHandler(error) {
    if (!(error instanceof ManageApiError)) { throw error; }
    const [ status, body, errorCode, errorSubCode, errorMessage ] = [ error.getStatusCode(), error.getResponseBody(), error.getErrorCode(), error.getErrorSubCode(), error.getErrorMessage() ];
    console.debug('error handler.', status, body, errorCode, errorSubCode, errorMessage);
    switch (status) {
      default:
        this.props.mainComponent.setState({ showAlert: true, alertContent: 'エラーが発生しました。' });
        break;
    }
  }

  static _onEventIdChange(e) {
    this.setState({ eventId: e.target.value });
  }

  static _onEventNameChange(e) {
    this.setState({ eventName: e.target.value });
  }

  static _onAvailableStartedAtChange(e) {
    this.setState({ availableStartedAt: e.target.value });
  }

  static _onAvailableEndedAtChange(e) {
    this.setState({ availableEndedAt: e.target.value });
  }
};

export default class EditEventPage extends React.Component {

  constructor(props) {
    super(props);
    const assortEvent = this.props.mainComponent.state.assortEvents.find((a) => { return a.assortId == this.props.assortId; });
    const { events, isEventsLoading } = (assortEvent != null) ? assortEvent : { events: [], isEventsLoading: true };
    const event = events.find((e) => { return e.id == this.props.eventId; });
    if (event != null) {
      this.state = {
        isEditEventCompleted: false,
        eventId: event.id,
        eventName: event.name,
        availableStartedAt: event.availableStartedAt,
        availableEndedAt: event.availableEndedAt
      };
    } else {
      this.state = {
        isEditEventCompleted: false
      };
    }
  }

  render() {
    console.log('EditEventPage', this);
    const { isEditEventCompleted } = this.state;
    const assortEvent = this.props.mainComponent.state.assortEvents.find((a) => { return a.assortId == this.props.assortId; });
    const { events, isEventsLoading } = (assortEvent != null) ? assortEvent : { events: [], isEventsLoading: true };

    if (isEditEventCompleted) {
      return ( <Redirect to={ `/apps/mmt/assorts/${this.props.assortId}/events` }/> );
    }

    if (isEventsLoading) {
      return ( <Redirect to={ `/apps/mmt/assorts/${this.props.assortId}/events` }/> );
    }

    const event = events.find((e) => { return e.id == this.props.eventId; });
    if (event == null) {
      return ( <div>存在しないイベントです。</div> );
    }

    return (
      <div class="p-edit-event-page container-fluid">
        <div class="p-edit-event-page__header">
          <div class="page-header">
            <h1><small class="text-muted">イベント編集ページ</small></h1>
          </div>
        </div>
        <div class="p-edit-event-page__body row">
          <div class="p-edit-event-page__main container-fluid">
            <form>
              <div class="form-group row">
                <label for="inputEventId" class="col-sm-2 col-form-label text-right font-weight-bold">イベントID</label>
                <div class="col-sm-7">
                  <input type="text"
                         class="form-control"
                         id="inputEventId"
                         placeholder="例: test_event_001"
                         value={this.state.eventId}
                         onChange={EventListener._onEventIdChange.bind(this)} />
                </div>
              </div>
              <div class="form-group row">
                <label for="inputEventName" class="col-sm-2 col-form-label text-right font-weight-bold">イベント名</label>
                <div class="col-sm-7">
                  <input type="text"
                         class="form-control"
                         id="inputEventName"
                         placeholder="例: テスト用イベント"
                         value={this.state.eventName}
                         onChange={EventListener._onEventNameChange.bind(this)} />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label text-right font-weight-bold">利用可能日時(optional)</label>
                <label class="col-sm-1 col-form-label text-right font-weight-bold">利用開始:</label>
                <div class="col-sm-3">
                  <input type="datetime-local"
                         class="form-control"
                         placeholder="例 2017-01-01T00:00:00"
                         value={this.state.availableStartedAt}
                         onChange={EventListener._onAvailableStartedAtChange.bind(this)}/>
                </div>
                <label class="col-sm-1 col-form-label text-right font-weight-bold">利用終了:</label>
                <div class="col-sm-3">
                  <input type="datetime-local"
                         class="form-control"
                         placeholder="例 2017-01-01T00:00:00"
                         value={this.state.availableEndedAt}
                         onChange={EventListener._onAvailableEndedAtChange.bind(this)}/>
                </div>
              </div>
              <div class="form-group row">
                <div class="offset-sm-1 col-sm-10">
                  <button type="button"
                          class="btn btn-primary btn-lg"
                          onClick={EventListener._onUpdateButtonClick.bind(this)}>
                    更新(TODO)
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
