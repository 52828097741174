import './style.scss';
import React from 'react';

import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom';

import config from 'config';

/**
 * ページヘッダーの基底部分
 */
export default class PageHeaderBase extends React.Component {

  render() {
    console.log('PageHeaderBase', this);
    const { title, children, dialogs } = this.props;

    const envButtonClass = `p-page-header__label-button btn btn-sm ${this._getEnvButtonType(config.env.value)}`;

    return (
      <div class="p-page-header container-fluid">
        <nav class="navbar navbar-expand-sm navbar-dark bg-dark p-page-header__nav">
          <div class="col-sm-2 p-page-header__label">
            <button type="button" class={envButtonClass}>
              {config.env.label}
            </button>
          </div>
          <div class="col-sm-10 p-page-header__main d-flex justify-content-start">
            <span class="navbar-brand p-page-header__title">{title}</span>
            {children}
          </div>
        </nav>
        {dialogs}
      </div>
    );
  }

  // 環境毎にページヘッダーの環境ボタンの色を変更しています。
  // 間違った環境でのオペレーションミスをなくすため。
  _getEnvButtonType(env) {
    switch (env) {
      case 'beta':
        return 'btn-info';
      case 'dev':
        return 'btn-primary';
      case 'stage':
        return 'btn-warning';
      case 'prod':
        return 'btn-danger';
      default:
        return 'btn-light';
    }
  }
}
