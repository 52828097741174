/**
 * ポイント失効に利用するダイアログ
 */
import React from 'react';

/**
 * ポイント失効の確認用ダイアログ
 */
export class InvalidatePointsConfirmationDialog extends React.Component {
  render() {
    const {userId, points, balance, isAllPointsInvalidation} = this.props;
    console.log('InvalidatePointsResultDialog', this);
    const invalidationMessage = (isAllPointsInvalidation) ? '一括でポイント失効処理' : points + 'ポイントの失効処理';

    return (
      <div class="modal fade" id="invalidate_points_confirm_dialog" role="dialog">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">ポイント失効確認</h4>
            </div>
            <div class="modal-body">
              <table class="table table-bordered">
                <thead>
                <tr>
                  <th> ユーザーID</th>
                  <th> 総ポイント</th>
                  <th> 有料ポイント</th>
                  <th> 無料ポイント</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td> {userId} </td>
                  <td> {balance.totalPoints} pt</td>
                  <td> {balance.details.paidPoints} pt</td>
                  <td> {balance.details.freePoints} pt</td>
                </tr>
                </tbody>
              </table>
              <p> 上記のユーザーに対し{invalidationMessage}を行います。 </p>
            </div>
            <div class="modal-footer">
              <button class="btn btn-light"
                      data-dismiss="modal"
                      type="button"
                      onClick={this.props.onCancelButtonClicked}>閉じる
              </button>
              <button class="btn btn-light"
                      data-dismiss="modal"
                      type="button"
                      onClick={this.props.onOkButtonClicked}>実行
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/**
 * ポイント失効の結果表示用ダイアログ
 */
export class InvalidatePointsResultDialog extends React.Component {
  render() {
    const {apiResponse} = this.props;
    console.log('InvalidatePointsResultDialog', this);

    return (
      <div class="modal fade" id="invalidate_points_result_dialog" role="dialog">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">ポイント失効結果</h4>
            </div>
            <div class="modal-body">
              <p>ポイント失効処理に {(apiResponse.meta.status == 200) ? "成功" : "失敗"} しました</p>
              <table class="table table-bordered">
                <thead>
                <tr>
                  <th> 取引ID</th>
                  <th> 総ポイント</th>
                  <th> 有料ポイント</th>
                  <th> 無料ポイント</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td> {apiResponse.data.dealId} </td>
                  <td> {apiResponse.data.balance.totalPoints} pt</td>
                  <td> {apiResponse.data.balance.details.paidPoints} pt</td>
                  <td> {apiResponse.data.balance.details.freePoints} pt</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="modal-footer">
              <button class="btn btn-light"
                      data-dismiss="modal"
                      type="button"
                      onClick={this.props.onCancelButtonClicked}>閉じる
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
