import './style.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom';

import MiniLoadingIcon from 'components/LoadingIcon/MiniLoadingIcon';

import BackendApiService from 'service/api/BackendApiService';
import ErrorHandleService from 'error/ErrorHandleService';
import {ManageApiError} from 'error/ManageError';
import {InvalidatePointsResultDialog, InvalidatePointsConfirmationDialog} from './InvalidatePointsDialog';

class EventListener {
  static _onUserIdChange(e) {
    this.setState({userId: e.target.value});
  }

  static _onDescriptionChange(e) {
    this.setState({description: e.target.value});
  }

  static _onPointsChange(e) {
    this.setState({points: e.target.value});
  }

  static _onInvalidateButtonClick(e) {
    this.setState({isAllPointsInvalidation: false});
    const assortId = this.props.assortId;
    const {userId} = this.state;
    this.setState({isInvalidatePointsLoading: true});
    Promise.all([
      this.props.backendApiService.getBalance(assortId, userId)
    ]).then(([ response ]) => {
      console.trace('get balance api succeeded', response);
      this.setState({isInvalidatePointsLoading: false, balance: response.data});
      const confirmDialog = ReactDOM.findDOMNode(this.refs.invalidatePointsConfirmationDialog);
      $(confirmDialog).modal({backdrop: false});
    }).catch((error) => {
      console.error('get balance api failed.', error);
      this.setState({isInvalidatePointsLoading: false});
      this.props.errorHandleService.handleError(EventListener._errorHandler.bind(this), error);
    });
  }

  static _onInvalidateAllButtonClick(e) {
    this.setState({isAllPointsInvalidation: true});
    const assortId = this.props.assortId;
    const {userId} = this.state;
    this.setState({isInvalidatePointsLoading: true});
    Promise.all([
      this.props.backendApiService.getBalance(assortId, userId)
    ]).then(([ response ]) => {
      console.trace('get balance api succeeded', response);
      this.setState({isInvalidatePointsLoading: false, balance: response.data});
      const confirmDialog = ReactDOM.findDOMNode(this.refs.invalidatePointsConfirmationDialog);
      $(confirmDialog).modal({backdrop: false});
    }).catch((error) => {
      console.error('get balance api failed.', error);
      this.setState({isInvalidatePointsLoading: false});
      this.props.errorHandleService.handleError(EventListener._errorHandler.bind(this), error);
    });
  }

  static _onConfirmButtonClick(e) {
    const assortId = this.props.assortId;
    const {userId, points, description, isAllPointsInvalidation} = this.state;
    const targetPoints = (isAllPointsInvalidation) ? null : points;
    this.setState({isInvalidatePointsLoading: true});
    Promise.all([
      this.props.backendApiService.invalidatePoints(assortId, userId, targetPoints, description)
    ]).then(([ response ]) => {
      console.trace('invalidate points api succeeded', response);
      this.setState({isInvalidatePointsLoading: false, apiResponse: response});
      const resultDialog = ReactDOM.findDOMNode(this.refs.invalidatePointsResultDialog);
      $(resultDialog).modal({backdrop: false});
    }).catch((error) => {
      console.error('invalidate points api failed.', error);
      this.setState({isInvalidatePointsLoading: false});
      this.props.errorHandleService.handleError(EventListener._errorHandler.bind(this), error);
    });
  }

  static _errorHandler(error) {
    if (!(error instanceof ManageApiError)) {
      throw error;
    }
    const [ status, body, errorCode, errorSubCode, errorMessage ] = [error.getStatusCode(), error.getResponseBody(), error.getErrorCode(), error.getErrorSubCode(), error.getErrorMessage()];
    console.debug('error handler.', status, body, errorCode, errorSubCode, errorMessage);
    switch (status) {
      default:
        this.props.mainComponent.setState({showAlert: true, alertContent: 'エラーが発生しました。'});
        break;
    }
  }
}

export default class InvalidatePointsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isInvalidatePointsLoading: false,
      userId: '',
      points: '',
      description: '',
      isAllPointsInvalidation: false,
      balance: null,
      apiResponse: null
    }
  }

  _invalidateForm() {
    return (
      <form>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label text-right font-weight-bold">ユーザーID</label>
          <div class="col-sm-8">
            <input type="text"
                   class="form-control"
                   placeholder=""
                   onChange={EventListener._onUserIdChange.bind(this)}
                   required/> 必須
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label text-right font-weight-bold">失効理由</label>
          <div class="col-sm-8">
            <input type="text"
                   class="form-control"
                   placeholder=""
                   onChange={EventListener._onDescriptionChange.bind(this)}
                   required/> 必須
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label text-right font-weight-bold">失効ポイント数</label>
          <div class="col-sm-8">
            <input type="text"
                   class="form-control"
                   placeholder=""
                   onChange={EventListener._onPointsChange.bind(this)}/>
          </div>
        </div>
      </form>
    );
  }

  _invalidateButton() {
    return (
      <div class="form-group row">
        <label class="col-sm-2 col-form-label text-right font-weight-bold"> </label>
        <button type="button" class="btn btn-primary btn-sm"
                onClick={EventListener._onInvalidateButtonClick.bind(this)}>
          失効
        </button>
        <button type="button" class="btn btn-primary btn-sm"
                onClick={EventListener._onInvalidateAllButtonClick.bind(this)}>
          一括失効
        </button>
      </div>
    );
  }

  _dialogs() {
    const {userId, points, balance, isAllPointsInvalidation, apiResponse} = this.state;
    // ポイント失効前の確認用ダイアログ
    const invalidatePointsConfirmationDialog = (balance != null) ? (
      <InvalidatePointsConfirmationDialog
        ref="invalidatePointsConfirmationDialog"
        userId={userId}
        points={points}
        balance={balance}
        isAllPointsInvalidation={isAllPointsInvalidation}
        onOkButtonClicked={EventListener._onConfirmButtonClick.bind(this)}/> ) : null;
    // ポイント失効後の結果確認用ダイアログ
    const invalidatePointsResultDialog = (apiResponse != null) ? (
      <InvalidatePointsResultDialog
        ref="invalidatePointsResultDialog"
        apiResponse={apiResponse}/> ) : null;
    return (
      <div>
        {invalidatePointsConfirmationDialog}
        {invalidatePointsResultDialog}
      </div>
    );
  }

  render() {
    console.log('InvalidatePointsPage', this);
    const {isInvalidatePointsLoading} = this.state;

    if (isInvalidatePointsLoading) {
      return ( <MiniLoadingIcon/> );
    }
    return (
      <div class="p-invalidate-points container-fluid">
        <div class="p-invalidate-points__header">
          <div class="page-header">
            <h1>
              <small class="text-muted">ポイント失効</small>
            </h1>
          </div>
        </div>
        <div class="p-invalidate-points__body row">
          <div class="p-invalidate-points__main container-fluid">
            { this._invalidateForm() }
            { this._invalidateButton() }
            { this._dialogs() }
          </div>
        </div>
      </div>
    );
  }
}

InvalidatePointsPage.propTypes = {
  backendApiService: BackendApiService,
  errorHandleService: ErrorHandleService
};

InvalidatePointsPage.defaultProps = {
  backendApiService: BackendApiService,
  errorHandleService: ErrorHandleService
};
