import './style.scss';
import React from 'react';

import createBrowserHistory from 'history/createBrowserHistory';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom';

import BackendApiService from 'service/api/BackendApiService';
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog';
import ErrorHandleService from 'error/ErrorHandleService';
import { ManageApiError } from 'error/ManageError';
import PageHeaderBase from './PageHeaderBase';

const PAGE_TYPES = {
  LIST : 'LIST',
  CREATE : 'CREATE',
  EDIT : 'EDIT'
};

class EventListener {
  static _deleteGroup() {
    const { groupId } = this.props;
    if (groupId == null) { return ; }
    console.log('delete group', groupId);

    Promise.all([
      BackendApiService.deleteGroup(groupId)
    ]).then(([ response ]) => {
      console.trace('delete group api succeeded', response);
      setTimeout(() => { location.href = '/apps/manage/groups'; }, 500);
    }).catch((error) => {
      console.error('delete group api failed.', error);
      ErrorHandleService.handleError(EventListener._errorHandler.bind(this), error);
    });
  }

  static _onReloadGroupsButtonClick() {
    console.log('onReloadGroupsButtonClick', this);
    this.setState({ isGroupsLoading: true, groups: [] });

    Promise.all([
      BackendApiService.getGroups()
    ]).then(([ response ]) => {
      console.trace('get groups api succeeded', response);
      setTimeout(() => {
        this.setState({ isGroupsLoading: false, groups: response.data.groups });
      }, 500);
    }).catch((error) => {
      console.error('get groups api failed.', error);
      ErrorHandleService.handleError(EventListener._errorHandler.bind(this), error);
    });
  }

  static _errorHandler(error) {
    if (!(error instanceof ManageApiError)) { throw error; }
    const [ status, body, errorCode, errorSubCode, errorMessage ] = [ error.getStatusCode(), error.getResponseBody(), error.getErrorCode(), error.getErrorSubCode(), error.getErrorMessage() ];
    console.debug('error handler.', status, body, errorCode, errorSubCode, errorMessage);
    switch (status) {
      default:
        this.props.mainComponent.setState({ showAlert: true, alertContent: 'エラーが発生しました。' });
        break;
    }
  }
}

export default class GroupPageHeader extends React.Component {
  _listTypeView() {
    const history = createBrowserHistory();
    return (
      <div class="collapse navbar-collapse">
        <button type="button" class="p-page-header__button btn btn-light">
          <Link to="/apps/manage/groups">
            <i class="fa fa-home fa-lg"/>
          </Link>
        </button>
        <button type="button" class="p-page-header__button btn btn-light" onClick={ (e) => { history.goBack(); } }>
          <i class="fa fa-arrow-left fa-lg"/>
        </button>
        <button type="button" class="p-page-header__button btn btn-light"
                onClick={ EventListener._onReloadGroupsButtonClick.bind(this.props.mainComponent) }>
          <i class="fa fa-refresh fa-lg"/>
        </button>
        <button type="button" class="p-page-header__button btn btn-light">
          <Link to="/apps/manage/groups/new">
            <i class="fa fa-plus"/> 作成
          </Link>
        </button>
      </div>
    );
  }

  _createTypeView() {
    const history = createBrowserHistory();
    return (
      <div class="collapse navbar-collapse">
        <button type="button" class="p-page-header__button btn btn-light">
          <Link to="/apps/manage/groups">
            <i class="fa fa-home fa-lg"/>
          </Link>
        </button>
        <button type="button" class="p-page-header__button btn btn-light" onClick={ (e) => { history.goBack(); } }>
          <i class="fa fa-arrow-left fa-lg"/>
        </button>
      </div>
    );
  }

  _editTypeView() {
    const history = createBrowserHistory();
    return (
      <div class="collapse navbar-collapse">
        <button type="button" class="p-page-header__button btn btn-light">
          <Link to="/apps/manage/groups">
            <i class="fa fa-home fa-lg"/>
          </Link>
        </button>
        <button type="button" class="p-page-header__button btn btn-light" onClick={ (e) => { history.goBack(); } }>
          <i class="fa fa-arrow-left fa-lg"/>
        </button>
        <button type="button" class="p-page-header__button btn btn-light">
          <Link to="/apps/manage/groups/new">
            <i class="fa fa-plus"/> 作成
          </Link>
        </button>
        <button type="button" class="p-page-header__button btn btn-danger"
                data-target="#deleteGroup" data-toggle="modal">
          <i class="fa fa-trash"/> 削除
        </button>
      </div>
    );
  }

  _viewTemplate(view) {
    const title = 'グループ管理';
    const dialogs = (
      <div>
        <DeleteGroupConfirmationDialog onOkButtonClicked={EventListener._deleteGroup.bind(this)}/>
      </div>
    );

    return (
      <PageHeaderBase title={title} dialogs={dialogs}>
        {view}
      </PageHeaderBase>
    );
  }

  render() {
    console.log('GroupPageHeader', this);
    const { pageType } = this.props;

    switch (pageType){
      case PAGE_TYPES.LIST:
        return this._viewTemplate(this._listTypeView());
      case PAGE_TYPES.CREATE:
        return this._viewTemplate(this._createTypeView());
      case PAGE_TYPES.EDIT:
        return this._viewTemplate(this._editTypeView());
      default:
        return this._viewTemplate(this._listTypeView());
    }
  }
}

GroupPageHeader.PAGE_TYPES = PAGE_TYPES;

/**
 * グループ削除の確認ダイアログ
 */
class DeleteGroupConfirmationDialog extends ConfirmationDialog {};

DeleteGroupConfirmationDialog.defaultProps = {
  id: 'deleteGroup',
  title: '',
  body: '本当に削除しますか？',
  onCancelButtonClicked: (e) => { return null; }
};

