import './style.scss';
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom';

import MiniLoadingIcon from 'components/LoadingIcon/MiniLoadingIcon';

import BackendApiService from 'service/api/BackendApiService';
import ErrorHandleService from 'error/ErrorHandleService';
import { ManageApiError } from 'error/ManageError';

export default class GroupListPage extends React.Component {

  componentDidMount() {
    const { isGroupsLoading } = this.props.mainComponent.state;

    if (isGroupsLoading) {
      Promise.all([
        BackendApiService.getUsers(),
        BackendApiService.getGroups(),
        BackendApiService.getRoles()
      ]).then(([ usersResponse, groupsResponse, rolesResponse ]) => {
        console.trace('get groups api succeeded', usersResponse, groupsResponse, rolesResponse);
        this.props.mainComponent.setState({
          users: usersResponse.data.users,
          isUsersLoading: false,
          groups: groupsResponse.data.groups,
          isGroupsLoading: false,
          roles: rolesResponse.data.roles,
          isRolesLoading: false
        });
      }).catch((error) => {
        console.error('get groups api failed.', error);
        ErrorHandleService.handleError(GroupListPage._errorHandler.bind(this), error);
      });
    }
  }

  static _errorHandler(error) {
    if (!(error instanceof ManageApiError)) { throw error; }
    const [ status, body, errorCode, errorSubCode, errorMessage ] = [ error.getStatusCode(), error.getResponseBody(), error.getErrorCode(), error.getErrorSubCode(), error.getErrorMessage() ];
    console.debug('error handler.', status, body, errorCode, errorSubCode, errorMessage);
    switch (status) {
      default:
        this.props.mainComponent.setState({ showAlert: true, alertContent: 'エラーが発生しました。' });
        break;
    }
  }

  _groupItem(group) {
    return (
      <tr key={group.id}>
        <td> {group.id} </td>
        <td> {group.title} </td>
        <td> {group.description} </td>
        <td> <h4><span class="badge badge-warning"> {group.role.name} </span></h4> </td>
        <td> {group.createdAt} </td>
        <td> {group.updatedAt} </td>
        <td>
          <button type="button" class="btn btn-light btn-sm">
            <Link to={ '/apps/manage/groups/' + group.id }>
              <i class="fa fa-pencil-square-o" aria-hidden="true"></i> 編集
            </Link>
          </button>
        </td>
      </tr>
    );
  }

  _groupList(groups) {
    return (
      <table class="table table-bordered">
        <thead>
          <tr>
            <th> ID </th>
            <th> グループ名 </th>
            <th> 概要 </th>
            <th> ロール </th>
            <th> 作成日時 </th>
            <th> 更新日時 </th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {groups.map((group) => {
             return this._groupItem(group);
           })}
        </tbody>
      </table>
    );
  }

  render() {
    console.log('GroupListPage', this);
    const { groups, isGroupsLoading } = this.props.mainComponent.state;

    if (isGroupsLoading) {
      return (
        <MiniLoadingIcon/>
      );
    }
    return (
      <div class="p-group-list container-fluid">
        <div class="p-group-list__header">
          <div class="page-header">
            <h1><small class="text-muted">グループ一覧</small></h1>
          </div>
        </div>
        <div class="p-group-list__body row">
          <div class="p-group-list__main container-fluid">
            { this._groupList(groups) }
          </div>
        </div>
      </div>
    );
  }
}
