import './style.scss';
import React from 'react';

/**
 * 確認ダイアログの基底
 */
export default class ConfirmationDialog extends React.Component {
  render() {
    return (
      <div class="modal fade" id={this.props.id} role="dialog">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button class="close" data-dismiss="modal" type="button">
                <i class="fa fa-times" aria-hidden="true"></i>
              </button>
              <h4 class="modal-title">{this.props.title}</h4>
            </div>
            <div class="modal-body">
              <p>{this.props.body}</p>
            </div>
            <div class="modal-footer">
              <button class="btn btn-light"
                      data-dismiss="modal"
                      type="button"
                      onClick={this.props.onCancelButtonClicked}>キャンセル</button>
              <button class="btn btn-light"
                      data-dismiss="modal"
                      type="button"
                      onClick={this.props.onOkButtonClicked}>OK</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

