import './style.scss';
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom';

import MiniLoadingIcon from 'components/LoadingIcon/MiniLoadingIcon';

import BackendApiService from 'service/api/BackendApiService';
import ErrorHandleService from 'error/ErrorHandleService';
import { ManageApiError } from 'error/ManageError';

export default class ProviderListPage extends React.Component {

  componentDidMount() {
    const { isProvidersLoading } = this.props.mainComponent.state;
    if (isProvidersLoading) {
      Promise.all([
        this.props.backendApiService.getProviders()
      ]).then(([ providersResponse ]) => {
        console.trace('get providers api succeeded', providersResponse);
        this.props.mainComponent.setState({
          providers: providersResponse.data.providers,
          isProvidersLoading: false
        });
      }).catch((error) => {
        console.error('get providers api failed.', error);
        this.props.errorHandleService.handleError(ProviderListPage._errorHandler.bind(this), error);
      });
    }
  }

  static _errorHandler(error) {
    if (!(error instanceof ManageApiError)) { throw error; }
    const [ status, body, errorCode, errorSubCode, errorMessage ] = [ error.getStatusCode(), error.getResponseBody(), error.getErrorCode(), error.getErrorSubCode(), error.getErrorMessage() ];
    console.debug('error handler.', status, body, errorCode, errorSubCode, errorMessage);
    switch (status) {
      default:
        this.props.mainComponent.setState({ showAlert: true, alertContent: 'エラーが発生しました。' });
        break;
    }
  }

  _providerItem(provider) {
    return (
      <tr key={provider.id}>
        <td> {provider.id} </td>
        <td> {provider.name} </td>
        <td> {provider.createdAt} </td>
        <td> {provider.updatedAt} </td>
        <td>
          <button type="button" class="btn btn-light btn-sm">
            <Link to={ '/apps/mmt/providers/' + provider.id }>
              <i class="fa fa-pencil-square-o" aria-hidden="true"></i> 編集
            </Link>
          </button>
        </td>
      </tr>
    );
  }

  _providerList(providers) {
    return (
      <table class="table table-bordered">
        <thead>
          <tr>
            <th> ID </th>
            <th> プロバイダ名 </th>
            <th> 作成日時 </th>
            <th> 更新日時 </th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {providers.map((provider) => {
             return this._providerItem(provider);
           })}
        </tbody>
      </table>
    );
  }

render() {
  console.log('ProviderListPage', this);
  const { providers, isProvidersLoading } = this.props.mainComponent.state;
  if (isProvidersLoading) {
    return ( <MiniLoadingIcon/> );
  }
    return (
      <div class="p-provider-list container-fluid">
        <div class="p-provider-list__header">
          <div class="page-header">
            <h1><small class="text-muted">プロバイダ一覧</small></h1>
          </div>
        </div>
        <div class="p-provider-list__body row">
          <div class="p-provider-list__main container-fluid">
            { this._providerList(providers) }
          </div>
        </div>
      </div>
    );
  }
}

ProviderListPage.propTypes = {
  backendApiService: BackendApiService,
  errorHandleService: ErrorHandleService
};

ProviderListPage.defaultProps = {
  backendApiService: BackendApiService,
  errorHandleService: ErrorHandleService
};
