/**
 * バックエンドの管理ツール系のAPIにアクセスするサービス
 */
import config from 'config';
import url from 'url';
import SessionService from 'service/SessionService';
import HandleApiResponseService from 'service/api/HandleApiResponseService';
import ApiService from 'service/api/ApiService';

export default class ManageApiService {

  static _getHeaders() {
    const session = this.sessionService.load();
    return { 'X-MMT-Session': session };
  }

  static _getUrl(pathname = '', query = {}) {
    const urlObj = Object.assign({ pathname, query }, config.manage.backendServerApi);
    return url.format(urlObj);
  }

  /**
   * ログイン
   */
  static login(email, password) {
    const path = '/api/v1/login';
    const requestBody = { email, password };
    const promise = this.apiService.post(this._getUrl(path), this._getHeaders(), requestBody);
    return this.handleApiResponseService.handlePromise(promise);
  }

  /**
   * ログアウト
   */
  static logout() {
    const path = '/api/v1/logout';
    const requestBody = {};
    const promise = this.apiService.post(this._getUrl(path), this._getHeaders(), requestBody);
    return this.handleApiResponseService.handlePromise(promise);
  }

  /**
   * セッションユーザーの情報を取得する
   */
  static getUserProfile() {
    const path = '/api/v1/users/profile';
    const promise = this.apiService.get(this._getUrl(path), this._getHeaders());
    return this.handleApiResponseService.handlePromise(promise);
  }

  /**
   * ユーザーを作成する
   */
  static createUser(username, email, password) {
    const path = '/api/v1/users';
    const requestBody = { email, password, username };
    const promise = this.apiService.post(this._getUrl(path), this._getHeaders(), requestBody);
    return this.handleApiResponseService.handlePromise(promise);
  }

  /**
   * ユーザーを削除する
   */
  static deleteUser(userId) {
    const path = `/api/v1/users/${userId}`;
    const promise = this.apiService.delete(this._getUrl(path), this._getHeaders());
    return this.handleApiResponseService.handlePromise(promise);
  }

  /**
   * ユーザー一覧を取得する
   */
  static getUsers() {
    const path = '/api/v1/users';
    const promise = this.apiService.get(this._getUrl(path), this._getHeaders());
    return this.handleApiResponseService.handlePromise(promise);
  }

  /**
   * パスワードを更新する
   */
  static updatePassword(password) {
    const path = '/api/v1/users/password';
    const requestBody = { password };
    const promise = this.apiService.put(this._getUrl(path), this._getHeaders(), requestBody);
    return this.handleApiResponseService.handlePromise(promise);
  }


  /**
   * グループ一覧を取得する
   */
  static getGroups() {
    const path = '/api/v1/groups';
    const promise = this.apiService.get(this._getUrl(path), this._getHeaders());
    return this.handleApiResponseService.handlePromise(promise);
  }

  /**
   * グループを作成する
   */
  static createGroup(title, description, roleId, memberIds) {
    const path = '/api/v1/groups';
    const requestBody = { title, description, roleId, memberIds };
    const promise = this.apiService.post(this._getUrl(path), this._getHeaders(), requestBody);
    return this.handleApiResponseService.handlePromise(promise);
  }

  /**
   * グループを更新する
   */
  static updateGroup(groupId, title, description, roleId, memberIds) {
    const path = `/api/v1/groups/${groupId}`;
    const requestBody = { title, description, roleId, memberIds };
    const promise = this.apiService.put(this._getUrl(path), this._getHeaders(), requestBody);
    return this.handleApiResponseService.handlePromise(promise);
  }

  /**
   * グループを削除する
   */
  static deleteGroup(groupId) {
    const path = `/api/v1/groups/${groupId}`;
    const promise = this.apiService.delete(this._getUrl(path), this._getHeaders());
    return this.handleApiResponseService.handlePromise(promise);
  }

  /**
   * セッションユーザーのロール一覧を取得する
   */
  static getSessionUserRoles() {
    const path = '/api/v1/users/roles';
    const promise = this.apiService.get(this._getUrl(path), this._getHeaders());
    return this.handleApiResponseService.handlePromise(promise);
  }

  /**
   * ロール一覧を取得する
   */
  static getRoles() {
    const path = '/api/v1/roles';
    const promise = this.apiService.get(this._getUrl(path), this._getHeaders());
    return this.handleApiResponseService.handlePromise(promise);
  }

  /**
   * ロールを作成する
   */
  static createRole(name, permissions) {
    const path = '/api/v1/roles';
    const requestBody = { name, permissions };
    const promise = this.apiService.post(this._getUrl(path), this._getHeaders(), requestBody);
    return this.handleApiResponseService.handlePromise(promise);
  }

  /**
   * ロールを更新する
   */
  static updateRole(roleId, name, permissions) {
    const path = `/api/v1/roles/${roleId}`;
    const requestBody = { name, permissions };
    const promise = this.apiService.put(this._getUrl(path), this._getHeaders(), requestBody);
    return this.handleApiResponseService.handlePromise(promise);
  }

  /**
   * ロールを削除する
   */
  static deleteRole(roleId) {
    const path = `/api/v1/roles/${roleId}`;
    const promise = this.apiService.delete(this._getUrl(path), this._getHeaders());
    return this.handleApiResponseService.handlePromise(promise);
  }
}

ManageApiService.sessionService = SessionService;
ManageApiService.handleApiResponseService = HandleApiResponseService;
ManageApiService.apiService = ApiService;
