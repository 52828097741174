import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom';

import BackendApiService from 'service/api/BackendApiService';

class EventListener extends React.Component {
  static _onUpdateButtonClick(e) {
    const { roleName, permissions } = this.state;
    Promise.all([
      BackendApiService.updateRole(this.props.roleId, roleName, permissions)
    ]).then(([ response ]) => {
      console.trace('edit role api succeeded', response);
      setTimeout(() => { location.href = '/apps/manage/roles'; }, 500);
    }).catch((error) => {
      console.error('edit role api failed.', error);
    });
  }

  static _onRoleNameChange(e) {
    this.setState({ roleName: e.target.value });
  }

  static _onPermissionCheckboxChanged(e) {
    const [ targetPermissionId, checked ] = [ e.target.value, e.target.checked ];
    if (checked) {
      const permissions = this.state.permissions.concat([ targetPermissionId ]);
      this.setState({ permissions });
    } else {
      const permissions = this.state.permissions.filter((permissionId) => { return permissionId != targetPermissionId; });
      this.setState({ permissions });
    }
  }
};

// TODO: バリデーション
export default class EditRolePage extends React.Component {

  constructor(props) {
    super(props);
    const role = this.props.mainComponent.state.roles.find((r) => { return r.id == this.props.roleId; });
    if (role != null) {
      this.state = {
        isEditRoleCompleted: false,
        roleName: role.name,
        permissions: role.permissions.map((p) => { return p.id; })
      };
    } else {
      this.state = {
        isEditRoleCompleted: false
      };
    }
  }

  render() {
    console.log('EditRolePage', this);
    const { isEditRoleCompleted, roleName, permissions } = this.state;
    const { isRolesLoading, roles } = this.props.mainComponent.state;

    if (isEditRoleCompleted) {
      return ( <Redirect to={ '/apps/manage/roles' }/> );
    }

    if (isRolesLoading) {
      return ( <Redirect to={ '/apps/manage/roles' }/> );
    }

    const role = roles.find((r) => { return r.id == this.props.roleId; });
    if (role == null) {
      return ( <div>存在しないロールです。</div> );
    }

    return (
      <div class="p-create-role-page container-fluid">
        <div class="p-create-role-page__header">
          <div class="page-header">
            <h1><small class="text-muted">ロール情報編集ページ</small></h1>
          </div>
        </div>
        <div class="p-create-role-page__body row">
          <div class="p-create-role-page__main container-fluid">
            <form>

              <div class="form-group row">
                <label for="inputRoleName" class="col-sm-2 col-form-label text-right font-weight-bold">ロール名</label>
                <div class="col-sm-7">
                  <input type="text"
                         class="form-control"
                         id="inputRoleName"
                         placeholder="role name"
                         value={roleName}
                         onChange={EventListener._onRoleNameChange.bind(this)} />
                </div>
              </div>

              <div class="form-group row">
                <label for="inputRoleName" class="col-sm-2 col-form-label text-right font-weight-bold">権限</label>
                <div class="d-flex flex-column justify-content-start align-items-start offset-sm-2 col-sm-10">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox"
                             value="MANAGE_USER"
                             onChange={EventListener._onPermissionCheckboxChanged.bind(this)}
                             checked={ permissions.indexOf('MANAGE_USER') !== -1 }
                    />
                    <label class="form-check-label font-weight-bold ml-1">ユーザーの管理権限</label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox"
                            value="MANAGE_GROUP"
                            onChange={EventListener._onPermissionCheckboxChanged.bind(this)}
                            checked={ permissions.indexOf('MANAGE_GROUP') !== -1 }
                    />
                    <label class="form-check-label font-weight-bold ml-1">グループの管理権限</label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox"
                            value="MANAGE_PRIVILEGE"
                            onChange={EventListener._onPermissionCheckboxChanged.bind(this)}
                            checked={ permissions.indexOf('MANAGE_PRIVILEGE') !== -1 }
                    />
                    <label class="form-check-label font-weight-bold ml-1">ロールの管理権限</label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox"
                            value="MANAGE_BALANCE"
                            onChange={EventListener._onPermissionCheckboxChanged.bind(this)}
                            checked={ permissions.indexOf('MANAGE_BALANCE') !== -1 }
                    />
                    <label class="form-check-label font-weight-bold ml-1">ポイント残高の管理権限</label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox"
                            value="MANAGE_DEAL_HISTORY"
                            onChange={EventListener._onPermissionCheckboxChanged.bind(this)}
                            checked={ permissions.indexOf('MANAGE_DEAL_HISTORY') !== -1 }
                    /> 
                    <label class="form-check-label font-weight-bold ml-1">取引き履歴の管理権限</label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox"
                            value="MANAGE_ADD_FREE_POINTS"
                            onChange={EventListener._onPermissionCheckboxChanged.bind(this)}
                            checked={ permissions.indexOf('MANAGE_ADD_FREE_POINTS') !== -1 }
                    />
                    <label class="form-check-label font-weight-bold ml-1">無料ポイント付与の管理権限</label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox"
                            value="MANAGE_INVALIDATE_POINTS"
                            onChange={EventListener._onPermissionCheckboxChanged.bind(this)}
                            checked={ permissions.indexOf('MANAGE_INVALIDATE_POINTS') !== -1 }
                    />
                    <label class="form-check-label font-weight-bold ml-1">ポイント失効の管理権限</label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox"
                            value="MANAGE_INQUIRY"
                            onChange={EventListener._onPermissionCheckboxChanged.bind(this)}
                            checked={ permissions.indexOf('MANAGE_INQUIRY') !== -1 }
                    />
                    <label class="form-check-label font-weight-bold ml-1">問い合わせの管理権限</label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox"
                            value="MANAGE_ASSORT"
                            onChange={EventListener._onPermissionCheckboxChanged.bind(this)}
                            checked={ permissions.indexOf('MANAGE_ASSORT') !== -1 }
                    />
                    <label class="form-check-label font-weight-bold ml-1">ポイント種別の管理権限</label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox"
                            value="MANAGE_PROVIDER"
                            onChange={EventListener._onPermissionCheckboxChanged.bind(this)}
                            checked={ permissions.indexOf('MANAGE_PROVIDER') !== -1 }
                    />
                    <label class="form-check-label font-weight-bold ml-1">プロバイダの管理権限</label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox"
                            value="MANAGE_PRODUCT"
                            onChange={EventListener._onPermissionCheckboxChanged.bind(this)}
                            checked={ permissions.indexOf('MANAGE_PRODUCT') !== -1 }
                    />
                    <label class="form-check-label font-weight-bold ml-1">商品の管理権限</label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox"
                            value="MANAGE_EVENT"
                            onChange={EventListener._onPermissionCheckboxChanged.bind(this)}
                            checked={ permissions.indexOf('MANAGE_EVENT') != -1 }
                    />
                    <label class="form-check-label font-weight-bold ml-1">イベントの管理権限</label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox"
                            value="MANAGE_VOUCHER"
                            onChange={EventListener._onPermissionCheckboxChanged.bind(this)}
                            checked={ permissions.indexOf('MANAGE_VOUCHER') != -1 }
                    />
                    <label class="form-check-label font-weight-bold ml-1">金券の管理権限</label>
                  </div>

                </div>
              </div>

              <div class="form-group row">
                <div class="offset-sm-1 col-sm-10">
                  <button type="button"
                          class="btn btn-primary btn-lg"
                          onClick={EventListener._onUpdateButtonClick.bind(this)}>
                    更新
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
