import './style.scss';
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom';

import BackendApiService from 'service/api/BackendApiService';
import ErrorHandleService from 'error/ErrorHandleService';
import { ManageApiError } from 'error/ManageError';

class EventListener extends React.Component {
  static _onUpdateButtonClick(e) {
    /*
    const { assortName, maxPointUnitPrice, expiresAfterDays, expiredAt } = this.state;
    const consumeOrderRule = this.refs.consumeOrderRuleSelectBox.selectedOptions[0].value;
    const expirationRule = this.refs.expirationRuleSelectBox.selectedOptions[0].value;
    const maxPointUnitPriceFroat = parseFloat(maxPointUnitPrice);
    const assortIdInt = parseInt(assortId, 10);
    
    Promise.all([
      BackendApiService.updateAssort(assortIdInt, assortName, maxPointUnitPriceFroat, consumeOrderRule, expirationRule, expiresAfterDays, expiredAt)
    ]).then(([ response ]) => {
      console.trace('update assort api succeeded', response);
      setTimeout(() => { location.href = '/apps/mmt/assorts'; }, 500);
    }).catch((error) => {
      console.error('update assort api failed.', error);
      ErrorHandleService.handleError(EventListener._errorHandler.bind(this), error);
    });
    */
  }

  static _errorHandler(error) {
    if (!(error instanceof ManageApiError)) { throw error; }
    const [ status, body, errorCode, errorSubCode, errorMessage ] = [ error.getStatusCode(), error.getResponseBody(), error.getErrorCode(), error.getErrorSubCode(), error.getErrorMessage() ];
    console.debug('error handler.', status, body, errorCode, errorSubCode, errorMessage);
    switch (status) {
      default:
        this.props.mainComponent.setState({ showAlert: true, alertContent: 'エラーが発生しました。' });
        break;
    }
  }

  static _onAssortNameChange(e) {
    this.setState({ assortName: e.target.value });
  }
  static _onMaxPointUnitPriceChange(e) {
    this.setState({ maxPointUnitPrice: e.target.value });
  }
  static _onExpiresAfterDaysChange(e) {
    this.setState({ expiresAfterDays: e.target.value });
  }
  static _onExpiredAtChange(e) {
    this.setState({ expiredAt: e.target.value });
  }
};

export default class EditAssortPage extends React.Component {

  constructor(props) {
    super(props);
    const assort = this.props.mainComponent.state.assorts.find((a) => { return a.id == this.props.assortId; });
    if (assort != null) {
      this.state = {
        isEditAssortCompleted: false,
        assortId: assort.id,
        assortName: assort.name,
        maxPointUnitPrice: assort.maxPointUnitPrice,
        consumeOrderRule: assort.consumeOrderRule,
        expirationRule: assort.expirationRule,
        expiresAfterDays: assort.expiresAfterDays,
        expiredAt: assort.expiredAt
      };
    } else {
      this.state = {
        isEditAssortCompleted: false
      };
    }
  }

  render() {
    console.log('EditAssortPage', this);
    const { isEditAssortCompleted, assortId, assortName, maxPointUnitPrice, consumeOrderRule, expirationRule, expiresAfterDays, expiredAt } = this.state;
    const { isAssortsLoading, assorts } = this.props.mainComponent.state;

    if (isEditAssortCompleted) {
      return ( <Redirect to={ '/apps/mmt/assorts' }/> );
    }

    if (isAssortsLoading) {
      return ( <Redirect to={ '/apps/mmt/assorts' }/> );
    }

    const assort = assorts.find((a) => { return a.id == this.props.assortId; });
    if (assort == null) {
      return ( <div>存在しないポイント種別です。</div> );
    }

    return (
      <div class="p-edit-assort-page container-fluid">
        <div class="p-edit-assort-page__header">
          <div class="page-header">
            <h1><small class="text-muted">ポイント種別更新ページ</small></h1>
          </div>
        </div>
        <div class="p-edit-assort-page__body row">
          <div class="p-edit-assort-page__main container-fluid">
            <form>
              <div class="form-group row">
                <label for="inputAssortId" class="col-sm-2 col-form-label text-right font-weight-bold">ポイント種別ID</label>
                <div class="col-sm-7">
                  {assort.id}
                </div>
              </div>
              <div class="form-group row">
                <label for="inputAssortName" class="col-sm-2 col-form-label text-right font-weight-bold">ポイント種別名</label>
                <div class="col-sm-7">
                  <input type="text"
                         class="form-control"
                         id="inputAssortName"
                         placeholder=""
                         value={this.state.assortName}
                         onChange={EventListener._onAssortNameChange.bind(this)} />
                </div>
              </div>
              <div class="form-group row">
                <label for="inputMaxPointUnitPrice" class="col-sm-2 col-form-label text-right font-weight-bold">ポイント単価の上限</label>
                <div class="col-sm-7">
                  <input type="number"
                         class="form-control"
                         min="0"
                         id="inputMaxPointUnitPrice"
                         placeholder="例: 1.2"
                         value={this.state.maxPointUnitPrice}
                         onChange={EventListener._onMaxPointUnitPriceChange.bind(this)} />
                </div>
              </div>
              <div class="form-group row">
                <label for="inputConsumeOrderRule" class="col-sm-2 col-form-label text-right font-weight-bold">ポイント消費順ルール</label>
                <div class="col-sm-7">
                  <select class="form-control" ref="consumeOrderRuleSelectBox">
                    <option key="created_at" value="created_at">作成日が古いポイント優先</option>
                    <option key="paid_points_and_created_at" value="paid_points_and_created_at">有料ポイント優先かつ作成日が古いポイント優先</option>
                    <option key="free_points_and_created_at" value="free_points_and_created_at">無料ポイント優先かつ作成日が古いポイント優先</option>
                    <option key="expired_at" value="expired_at">失効日が近いポイント優先</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label for="inputExpirationRule" class="col-sm-2 col-form-label text-right font-weight-bold">ポイント失効ルール</label>
                <div class="col-sm-7">
                  <select class="form-control" ref="expirationRuleSelectBox">
                    <option key="never" value="never">失効なし</option>
                    <option key="after_days" value="after_days">N日後に失効</option>
                    <option key="on_expired_at" value="on_expired_at">次の失効日に失効</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label for="inputExpiresAfterDays" class="col-sm-2 col-form-label text-right font-weight-bold">失効日数</label>
                <div class="col-sm-7">
                  <input type="text"
                         class="form-control"
                         id="inputExpiresAfterDays"
                         placeholder=""
                         onChange={EventListener._onExpiresAfterDaysChange.bind(this)} />
                </div>
              </div>
              <div class="form-group row">
                <label for="inputExpiredAt" class="col-sm-2 col-form-label text-right font-weight-bold">失効日時</label>
                <div class="col-sm-7">
                  <input type="datetime-local"
                         class="form-control"
                         placeholder="例 2017-01-01T00:00:00"
                         value={this.state.expiredAt}
                         onChange={EventListener._onExpiredAtChange.bind(this)}/>
                </div>
              </div>
              <div class="form-group row">
                <div class="offset-sm-1 col-sm-10">
                  <button type="button"
                          class="btn btn-primary btn-lg"
                          onClick={EventListener._onUpdateButtonClick.bind(this)}>
                    更新(TODO)
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
