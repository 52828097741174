/**
 * 管理ツールのバックエンドAPIを利用するためのサービス
 */
import ManageApiService from 'service/api/manage-backend/ManageApiService';
import BmtbApiService from 'service/api/manage-backend/BmtbApiService';
import IrsApiService from 'service/api/manage-backend/IrsApiService';

export default class BackendApiService {
  // ログイン系のAPI
  static login(email, passowrd) { return ManageApiService.login(email, passowrd); }
  static logout() { return ManageApiService.logout(); }
  // ユーザー系のAPI
  static getUserProfile() { return ManageApiService.getUserProfile(); }
  static getUsers() { return ManageApiService.getUsers(); }
  static createUser(username, email, password) { return ManageApiService.createUser(username, email, password); }
  static deleteUser(userId) { return ManageApiService.deleteUser(userId); }
  static updatePassword(password) { return ManageApiService.updatePassword(password); }
  // グループ系のAPI
  static getGroups() { return ManageApiService.getGroups(); }
  static createGroup(title, description, roleId, memberIds) { return ManageApiService.createGroup(title, description, roleId, memberIds); }
  static updateGroup(groupId, title, description, roleId, memberIds) { return ManageApiService.updateGroup(groupId, title, description, roleId, memberIds); }
  static deleteGroup(groupId) { return ManageApiService.deleteGroup(groupId); }
  // ロール系のAPI
  static getSessionUserRoles() { return ManageApiService.getSessionUserRoles(); }
  static getRoles() { return ManageApiService.getRoles(); }
  static createRole(name, permissions) { return ManageApiService.createRole(name, permissions); }
  static updateRole(roleId, name, permissions) { return ManageApiService.updateRole(roleId, name, permissions); }
  static deleteRole(roleId) { return ManageApiService.deleteRole(roleId); }
  // BMTB系のAPI
  static createAssort(assortId, name, maxPointUnitPrice, consumeOrderRule, expirationRule, expiresAfterDays, expiredAt) { return BmtbApiService.createAssort(assortId, name, maxPointUnitPrice, consumeOrderRule, expirationRule, expiresAfterDays, expiredAt); }
  static deleteAssort(assortId) { return BmtbApiService.deleteAssort(assortId); }
  static getAssorts() { return BmtbApiService.getAssorts(); }
  static createProvider(providerId, name) { return BmtbApiService.createProvider(providerId, name); }
  static getProviders() { return BmtbApiService.getProviders(); }
  static getEvents(assortId) { return BmtbApiService.getEvents(assortId); }
  static createEvent(assortId, eventId, name, availableStartedAt, availableEndedAt) { return BmtbApiService.createEvent(assortId, eventId, name, availableStartedAt, availableEndedAt); }
  static deleteEvent(assortId, eventId) { return BmtbApiService.deleteEvent(assortId, eventId); }
  static createAndRegisterEvent(assortId, providerId, eventId, name, availableStartedAt, availableEndedAt) { return BmtbApiService.createAndRegisterEvent(assortId, providerId, eventId, name, availableStartedAt, availableEndedAt); }
  static getProducts(assortId) { return BmtbApiService.getProducts(assortId); }
  static createProduct(assortId, productId, name) { return BmtbApiService.createProduct(assortId, productId, name); }
  static deleteProduct(assortId, productId) { return BmtbApiService.deleteProduct(assortId, productId); }
  static createAndRegisterProduct(assortId, providerId, productId, name) { return BmtbApiService.createAndRegisterProduct(assortId, providerId, productId, name); }
  static getVouchers(assortId) { return BmtbApiService.getVouchers(assortId) ; }
  static createVoucher(assortId, voucherId, name) { return BmtbApiService.createVoucher(assortId, voucherId, name) ; }
  static deleteVoucher(assortId, voucherId) { return BmtbApiService.deleteVoucher(assortId, voucherId) ; }
  static createAndRegisterVoucher(assortId, providerId, voucherId, name, paidPoints, freePoints, price, appleProductId, appleBundleId, appleProductType, googleProductId, googlePackageName, googlePurchaseType, availableStartedAt, availableEndedAt, providerPaymentMethodGroup) { return BmtbApiService.createAndRegisterVoucher(assortId, providerId, voucherId, name, paidPoints, freePoints, price, appleProductId, appleBundleId, appleProductType, googleProductId, googlePackageName, googlePurchaseType, availableStartedAt, availableEndedAt, providerPaymentMethodGroup); }
  static getBalance(assortId, userId) { return BmtbApiService.getBalance(assortId, userId); }
  static searchDeal(assortId, offset, limit, dealType, dealtAtFrom, dealtAtUntil, dealIds, userId, providerId, kaeruTransactionId, agencyTransactionId) { return BmtbApiService.searchDeal(assortId, offset, limit, dealType, dealtAtFrom, dealtAtUntil, dealIds, userId, providerId, kaeruTransactionId, agencyTransactionId); }
  static cancelDeals(assortId, dealIds, description) { return BmtbApiService.cancelDeals(assortId, dealIds, description); }
  static addPaidPoints(assortId, userId, description, uniqueToken, price, points) { return BmtbApiService.addPaidPoints(assortId, userId, description, uniqueToken, price, points); }
  static addFreePoints(assortId, eventId, targets) { return BmtbApiService.addFreePoints(assortId, eventId, targets); }
  static invalidatePoints(assortId, userId, points, description) { return BmtbApiService.invalidatePoints(assortId, userId, points, description); }
  static consumePoints(assortId, userId, points, consumeRule, productId, uniqueToken, description, forceConsume) { return BmtbApiService.consumePoints(assortId, userId, points, consumeRule, productId, uniqueToken, description, forceConsume); }
  // IRS系のAPI
  static getInquiry(inquiryId) { return IrsApiService.getInquiry(inquiryId); }
}
