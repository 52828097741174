import React from 'react';
import ReactDOM from 'react-dom';
import {v4 as uuidv4} from 'uuid';

import MiniLoadingIcon from "components/LoadingIcon/MiniLoadingIcon";

import BackendApiService from "service/api/BackendApiService";
import ErrorHandleService from "error/ErrorHandleService";
import {ManageApiError} from "error/ManageError";
import {ForceConsumePointsConfirmationDialog, ForceConsumePointsResultDialog} from './ForceConsumePointsDialog';

class EventListener {
  static _onUserIdChange(e) {
    this.setState({userId: e.target.value});
  }

  static _onDescriptionChange(e) {
    this.setState({description: e.target.value});
  }

  static _onPointsChange(e) {
    this.setState({points: e.target.value});
  }

  static _onProductsChange(e) {
    this.setState({productId: e.target.value});
    this.setState({productName: e.target.options[e.target.selectedIndex].label});
  }

  static _errorHandler(error) {
    if (!(error instanceof ManageApiError)) {
      throw error;
    }
    const [ status, body, errorCode, errorSubCode, errorMessage ] = [error.getStatusCode(), error.getResponseBody(), error.getErrorCode(), error.getErrorSubCode(), error.getErrorMessage()];
    console.debug('debug handler.', status, body, errorCode, errorSubCode, errorMessage);
    this.props.mainComponent.setState({showAlert: true, alertContent: 'エラーが発生しました。'});
  }

  static _onForceConsumeButtonClick(e) {
    const assortId = this.props.assortId;
    const {userId} = this.state;
    this.setState({isForceConsumePointsLoading: true});
    Promise.all([
      this.props.backendApiService.getBalance(assortId, userId)
    ]).then(([response]) => {
      console.trace('get balance api succeeded.', response);
      this.setState({isForceConsumePointsLoading: false, balance: response.data});
      const confirmDialog = ReactDOM.findDOMNode(this.refs.forceConsumePointsConfirmationDialog);
      $(confirmDialog).modal({backdrop: false});
    }).catch((error) => {
      console.error('get balance api failed.', error);
      this.setState({isForceConsumePointsLoading: false});
      this.props.errorHandleService.handleError(EventListener._errorHandler.bind(this), error);
    });
  }

  static _onConfirmButtonClick(e) {
    const assortId = this.props.assortId;
    const {userId, productId, points, description} = this.state;
    const uniqueToken = uuidv4();
    this.setState({isForceConsumePointsLoading: true});
    Promise.all([
      this.props.backendApiService.consumePoints(assortId, userId, points, null, productId, uniqueToken, description, true)
    ]).then(([ response ]) => {
      console.trace('consume points api succeeded.', response);
      this.setState({isForceConsumePointsLoading: false, apiResponse: response});
      const resultDialog = ReactDOM.findDOMNode(this.refs.forceConsumePointsResultDialog);
      $(resultDialog).modal({backdrop: false});
    }).catch((error) => {
      console.error('consume points api failed.', error);
      this.setState({isForceConsumePointsLoading: false});
      this.props.errorHandleService.handleError(EventListener._errorHandler.bind(this), error);
    });
    this.setState({userId: '', productId: '', productName: '', points: '', description: ''});
  }

  static _onCancelButtonClick(e) {
    this.setState({userId: '', productId: '', productName: '', points: '', description: ''});
  }
}

export default class ForceConsumePointsPage extends React.Component {
  constructor(props) {
    super();
    this.state = {
      isForceConsumePointsLoading: false,
      userId: '',
      productId: '',
      productName: '',
      points: '',
      description: '',
      balance: null,
      apiResponse: null,
    }
  }

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentDidUpdate() {
    const assortProduct = this.props.mainComponent.state.assortProducts.find((assortProduct) => { return assortProduct.assortId == this.props.assortId; })
    const { isProductsLoading } = (assortProduct != null) ? assortProduct : { isProductsLoading: true };
    if(isProductsLoading) {
      Promise.all([
        this.props.backendApiService.getProducts(this.props.assortId)
      ]).then(([productsResponse]) => {
        console.trace('get products api succeeded.', this.props.assortId, productsResponse);
        const assortProducts = [].concat(this.props.mainComponent.state.assortProducts, [{
          products: productsResponse.data.products,
          assortId: this.props.assortId,
          isProductsLoading: false
        }]);
        this.props.mainComponent.setState({ assortProducts });
      }).catch((error) => {
        console.error('get products api failed.', error);
        this.props.errorHandleService.handleError(ForceConsumePointsPage._errorHandler.bind(this), error);
      });
    }
  }

  _forceConsumePointsForm(products) {
      return (
        <form className="form-horizontal">
          <div className="form-group">
            <label className="col-sm-2 control-label">ユーザーID</label>
            <div className="col-sm-8">
              <input type="text"
                     className="form-control"
                     placeholder=""
                     onChange={EventListener._onUserIdChange.bind(this)}
                     required/> 必須
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label">強制消費ポイント数</label>
            <div className="col-sm-8">
              <input type="text"
                     className="form-control"
                     placeholder=""
                     onChange={EventListener._onPointsChange.bind(this)}
                     required/> 必須
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label">商品</label>
            <div className="col-sm-8">
              <select className="form-control" onChange={EventListener._onProductsChange.bind(this)} required>
                <option>商品を選択してください</option>
                {products.map((product) => {
                  return (<option key={product.id} value={product.id} label={product.name}/>);
                })}
              </select> 必須
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label">強制消費理由</label>
            <div className="col-sm-8">
              <input type="text"
                     className="form-control"
                     placeholder=""
                     onChange={EventListener._onDescriptionChange.bind(this)}/>
            </div>
          </div>
        </form>
      );
  }

  _forceConsumeButton() {
    return (
      <div className="form-group">
        <label className="col-sm-2 control-label"> </label>
        <button type="button" className="btn btn-primary btn-sm"
                onClick={EventListener._onForceConsumeButtonClick.bind(this)}>
          消費
        </button>
      </div>
    );
  }

  _dialogs() {
    const {userId, productId, productName, points, description, balance, apiResponse} = this.state;
    // ポイント強制消費前の確認ダイアログ
    const forceConsumePointsConfirmationDialog = (balance != null) ? (
      <ForceConsumePointsConfirmationDialog
        ref="forceConsumePointsConfirmationDialog"
        userId={userId}
        productId={productId}
        productName={productName}
        description={description}
        points={points}
        balance={balance}
        onCancelButtonClicked={EventListener._onCancelButtonClick.bind(this)}
        onOkButtonClicked={EventListener._onConfirmButtonClick.bind(this)}/>) : null;
    // ポイント強制消費後の結果確認用ダイアログ
    const forceConsumePointsResultDialog = (apiResponse != null) ? (
      <ForceConsumePointsResultDialog
        ref="forceConsumePointsResultDialog"
        apiResponse={apiResponse}/>): null;
    return (
      <div>
        {forceConsumePointsConfirmationDialog}
        {forceConsumePointsResultDialog}
      </div>
    );
  }

  render() {
    console.log('ForceConsumePointsPage', this);
    const {isForceConsumePointsLoading} = this.state;
    const { isAssortProductsLoading } = this.props.mainComponent.state;
    const assortProduct = this.props.mainComponent.state.assortProducts.find((assortProduct) => { return assortProduct.assortId == this.props.assortId; })
    const { products, isProductsLoading } = (assortProduct != null) ? assortProduct: {products: [], isProductsLoading: true };

    if (isForceConsumePointsLoading || isAssortProductsLoading) {
      return ( <MiniLoadingIcon/> );
    } else if (products.length === 0) {
      return (
        <div className="p-force-consume-points container-fluid">
          <div className="p-force-consume-points__header row">
            <div className="page-header">
              <h1>
                <small>ポイント強制消費</small>
              </h1>
            </div>
          </div>
          <div className="p-force-consume-points__body row">
            <div className="p-force-consume-points__main container-fluid">
              <p class="col-sm-offset-2 help-block">通貨に商品の登録がないため実施できません</p>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="p-force-consume-points container-fluid">
          <div className="p-force-consume-points__header row">
            <div className="page-header">
              <h1>
                <small>ポイント強制消費</small>
              </h1>
            </div>
          </div>
          <div className="p-force-consume-points__body row">
            <div className="p-force-consume-points__main container-fluid">
              {this._forceConsumePointsForm(products)}
              {this._forceConsumeButton()}
              {this._dialogs()}
            </div>
          </div>
        </div>
      );
    }
  }
}

ForceConsumePointsPage.propTypes = {
  backendApiService: BackendApiService,
  errorHandleService: ErrorHandleService
};

ForceConsumePointsPage.defaultProps = {
  backendApiService: BackendApiService,
  errorHandleService: ErrorHandleService
};
