import './style.scss';
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom';

import BackendApiService from 'service/api/BackendApiService';
import ErrorHandleService from 'error/ErrorHandleService';
import { ManageApiError } from 'error/ManageError';

class EventListener extends React.Component {
  static _onRegisterButtonClick(e) {
    const { email, username, password } = this.state;
    Promise.all([
      BackendApiService.createUser(username, email, password)
    ]).then(([ response ]) => {
      console.trace('create user api succeeded', response);
      // TODO: リダイレクトのやり方うまい方法を考える
      setTimeout(() => { location.href = '/apps/manage/users'; }, 500);
    }).catch((error) => {
      console.error('create user api failed.', error);
      ErrorHandleService.handleError(EventListener._errorHandler.bind(this), error);
    });
  }

  static _errorHandler(error) {
    if (!(error instanceof ManageApiError)) { throw error; }
    const [ status, body, errorCode, errorSubCode, errorMessage ] = [ error.getStatusCode(), error.getResponseBody(), error.getErrorCode(), error.getErrorSubCode(), error.getErrorMessage() ];
    console.debug('error handler.', status, body, errorCode, errorSubCode, errorMessage);
    switch (status) {
      default:
        this.props.mainComponent.setState({ showAlert: true, alertContent: 'エラーが発生しました。' });
        break;
    }
  }

  static _onEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  static _onUsernameChange(e) {
    this.setState({ username: e.target.value });
  }

  static _onPasswordChange(e) {
    this.setState({ password: e.target.value });
  }

  static _onConfirmationPasswordChange(e) {
    this.setState({ confirmationPassword: e.target.value });
  }
};

// TODO: ユーザー作成をダイアログ化する
// TODO: バリデーション、パスワード確認処理
export default class CreateUserPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isCreateUserCompleted: false
    };
  }

  render() {
    console.log('CreateUserPage', this);
    const { isCreateUserCompleted } = this.state;

    if (isCreateUserCompleted) {
      return ( <Redirect to={ '/apps/manage/users' }/> );
    }

    return (
      <div class="p-create-user-page container-fluid">
        <div class="p-create-user-page__header">
          <div class="page-header">
            <h1><small class="text-muted">ユーザー登録ページ</small></h1>
          </div>
        </div>
        <div class="p-create-user-page__body row">
          <div class="p-create-user-page__main container-fluid">
            <form>
              <div class="form-group row">
                <label for="inputEmail" class="col-sm-2 col-form-label text-right font-weight-bold">メールアドレス</label>
                <div class="col-sm-7">
                  <input type="text"
                         class="form-control"
                         id="inputEmail"
                         placeholder="email address"
                         onChange={EventListener._onEmailChange.bind(this)} />
                </div>
              </div>
              <div class="form-group row">
                <label for="inputUsername" class="col-sm-2 col-form-label text-right font-weight-bold">ユーザー名</label>
                <div class="col-sm-7">
                  <input type="text"
                         class="form-control"
                         id="inputUsername"
                         placeholder="user name"
                         onChange={EventListener._onUsernameChange.bind(this)} />
                </div>
              </div>
              <div class="form-group row">
                <label for="inputPassword" class="col-sm-2 col-form-label text-right font-weight-bold">パスワード</label>
                <div class="col-sm-7">
                  <input type="password"
                         class="form-control"
                         id="inputPassword"
                         placeholder="password"
                         onChange={EventListener._onPasswordChange.bind(this)} />
                </div>
              </div>
              <div class="form-group row">
                <label for="inputConfirmationPassword" class="col-sm-2 col-form-label text-right font-weight-bold">パスワード 確認</label>
                <div class="col-sm-7">
                  <input type="password"
                         class="form-control"
                         id="inputConfirmationPassword"
                         placeholder="confirmation password"
                         onChange={EventListener._onConfirmationPasswordChange.bind(this)} />
                </div>
              </div>
              <div class="form-group row">
                <div class="offset-sm-1 col-sm-10">
                  <button type="button"
                          class="btn btn-primary btn-lg"
                          onClick={EventListener._onRegisterButtonClick.bind(this)}>
                    登録
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
