import './style.scss';
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom';

import MiniLoadingIcon from 'components/LoadingIcon/MiniLoadingIcon';

import BackendApiService from 'service/api/BackendApiService';
import ErrorHandleService from 'error/ErrorHandleService';
import { ManageApiError } from 'error/ManageError';

export default class RoleListPage extends React.Component {

  componentDidMount() {
    const { isRolesLoading } = this.props.mainComponent.state;
    if (isRolesLoading) {
      Promise.all([
        BackendApiService.getRoles()
      ]).then(([ rolesResponse ]) => {
        console.trace('get roles api succeeded', rolesResponse);
        this.props.mainComponent.setState({
          roles: rolesResponse.data.roles,
          isRolesLoading: false
        });
      }).catch((error) => {
        console.error('get roles api failed.', error);
        ErrorHandleService.handleError(RoleListPage._errorHandler.bind(this), error);
      });
    }
  }

  static _errorHandler(error) {
    if (!(error instanceof ManageApiError)) { throw error; }
    const [ status, body, errorCode, errorSubCode, errorMessage ] = [ error.getStatusCode(), error.getResponseBody(), error.getErrorCode(), error.getErrorSubCode(), error.getErrorMessage() ];
    console.debug('error handler.', status, body, errorCode, errorSubCode, errorMessage);
    switch (status) {
      default:
        this.props.mainComponent.setState({ showAlert: true, alertContent: 'エラーが発生しました。' });
        break;
    }
  }

  _roleItem(role) {
    return (
      <tr key={role.id}>
        <td> {role.id} </td>
        <td> {role.name} </td>
        <td>
          {role.permissions.map((permission) => {
             return (
               <span key={ role.id + '_' + permission.id }
                     class="badge badge-primary">{permission.name}</span>
             );
           })}
        </td>
        <td>
          <button type="button" class="btn btn-light btn-sm">
            <Link to={ '/apps/manage/roles/' + role.id }>
              <i class="fa fa-pencil-square-o" aria-hidden="true"></i> 編集
            </Link>
          </button>
        </td>
      </tr>
    );
  }

  _roleList(roles) {
    return (
      <table class="table table-bordered">
        <thead>
          <tr>
            <th> ID </th>
            <th> ロール名 </th>
            <th> 権限 </th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {roles.map((role) => {
             return this._roleItem(role);
           })}
        </tbody>
      </table>
    );
  }

  render() {
    console.log('RoleListPage', this);
    const { roles, isRolesLoading } = this.props.mainComponent.state;

    if (isRolesLoading) {
      return (
        <MiniLoadingIcon/>
      );
    }
    return (
      <div class="p-role-list container-fluid">
        <div class="p-role-list__header">
          <div class="page-header">
            <h1><small class="text-muted">ロール一覧</small></h1>
          </div>
        </div>
        <div class="p-role-list__body row">
          <div class="p-role-list__main container-fluid">
            { this._roleList(roles) }
          </div>
        </div>
      </div>
    );
  }
}
