/**
 * ロールの権限から表示可能かどうかを判定するサービス
 */
const PERMISSION = {
  MANAGE_USER                 : 'MANAGE_USER',
  MANAGE_GROUP                : 'MANAGE_GROUP',
  MANAGE_PRIVILEGE            : 'MANAGE_PRIVILEGE',
  MANAGE_BALANCE              : 'MANAGE_BALANCE',
  MANAGE_DEAL_HISTORY         : 'MANAGE_DEAL_HISTORY',
  MANAGE_ADD_FREE_POINTS      : 'MANAGE_ADD_FREE_POINTS',
  MANAGE_INVALIDATE_POINTS    : 'MANAGE_INVALIDATE_POINTS',
  MANAGE_INQUIRY              : 'MANAGE_INQUIRY',
  MANAGE_ASSORT               : 'MANAGE_ASSORT',
  MANAGE_PROVIDER             : 'MANAGE_PROVIDER',
  MANAGE_PRODUCT              : 'MANAGE_PRODUCT',
  MANAGE_EVENT                : 'MANAGE_EVENT',
  MANAGE_VOUCHER              : 'MANAGE_VOUCHER',
  MANAGE_FORCE_CONSUME_POINTS : 'MANAGE_FORCE_CONSUME_POINTS',
  MANAGE_ADD_PAID_POINTS      : 'MANAGE_ADD_PAID_POINTS'
};

export default class PrivilegeService {

  static getPermissions() {
    return PERMISSION;
  }

  static getVisibleInfo(roles) {
    const permissionIds = PrivilegeService._getPermissionIds(roles);
    return {
      isManageUsersVisible            : permissionIds.includes(PERMISSION.MANAGE_USER),
      isManageGroupsVisible           : permissionIds.includes(PERMISSION.MANAGE_GROUP),
      isManageRolesVisible            : permissionIds.includes(PERMISSION.MANAGE_PRIVILEGE),
      isIrsGetInquiryVisible          : permissionIds.includes(PERMISSION.MANAGE_INQUIRY),
      isBmtbBalanceVisible            : permissionIds.includes(PERMISSION.MANAGE_BALANCE),
      isBmtbSearchDealVisible         : permissionIds.includes(PERMISSION.MANAGE_DEAL_HISTORY),
      isBmtbAddFreePointsVisible      : permissionIds.includes(PERMISSION.MANAGE_ADD_FREE_POINTS),
      isBmtbInvalidatePointsVisible   : permissionIds.includes(PERMISSION.MANAGE_INVALIDATE_POINTS),
      isBmtbAssortsVisible            : permissionIds.includes(PERMISSION.MANAGE_ASSORT),
      isBmtbProvidersVisible          : permissionIds.includes(PERMISSION.MANAGE_PROVIDER),
      isBmtbProductsVisible           : permissionIds.includes(PERMISSION.MANAGE_PRODUCT),
      isBmtbEventsVisible             : permissionIds.includes(PERMISSION.MANAGE_EVENT),
      isBmtbVouchersVisible           : permissionIds.includes(PERMISSION.MANAGE_VOUCHER),
      isBmtbForceConsumePointsVisible : permissionIds.includes(PERMISSION.MANAGE_FORCE_CONSUME_POINTS),
      isBmtbAddPaidPointsVisible      : permissionIds.includes(PERMISSION.MANAGE_ADD_PAID_POINTS)
    };
  }

  static _getPermissionIds(roles) {
    return Array.prototype.concat.apply([], roles.map((role) => { return role.permissions; }))
      .map((permission) => { return permission.id; });
  }
}
