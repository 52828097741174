import './style.scss';
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom';

import BackendApiService from 'service/api/BackendApiService';
import SessionService from 'service/SessionService';
import ErrorHandleService from 'error/ErrorHandleService';
import { ManageApiError } from 'error/ManageError';

class EventListener {
  static _onEmailChange(e) {
    const email = e.target.value;
    this.setState({ email });
  }
  static _onPasswordChange(e) {
    const password = e.target.value;
    this.setState({ password });
  }
  static _onLoginButtonClicked(e) {
    const { email, password } = this.state;

    BackendApiService
      .login(email, password)
      .then((loginResponse) => {
        console.debug(loginResponse);
        const { session } = loginResponse.data;
        SessionService.save(session);
        this.setState({ redirectToReferrer: true });
      }).catch((error) => {
        console.debug(error);
        ErrorHandleService.handleError(EventListener._errorHandler.bind(this), error);
      });
  }

  static _errorHandler(error) {
    if (!(error instanceof ManageApiError)) { throw error; }
    const [ status, body, errorCode, errorSubCode, errorMessage ] = [ error.getStatusCode(), error.getResponseBody(), error.getErrorCode(), error.getErrorSubCode(), error.getErrorMessage() ];
    console.debug('error handler.', status, body, errorCode, errorSubCode, errorMessage);
    switch (status) {
      case 400:
        if (errorSubCode == 'INVALID_CREDENTIAL') {
          this.props.mainComponent.setState({ showAlert: true, alertContent: 'メールアドレスまたはパスワードが間違っています。' });
        } else {
          throw error;
        }
        break;
      default:
        throw error;
        break;
    }
  }
}

class LoginComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      redirectToReferrer: false
    };
  }

  _viewTemplate() {
    return (
      <div class="row justify-content-center">
        <div class="col-sm-4 p-login">
          <div class="card p-login__main">
            <div class="card-header p-login__title">
              <h3 class="card-title">アプリ版ニコニコポイント 管理ツール</h3>
            </div>
            <div class="card-body p-login__body">
              <form class="p-login__form">
                <div class="p-login__form-email form-group">
                  <input type="text"
                        class="form-control"
                        placeholder="Email Address"
                        onChange={EventListener._onEmailChange.bind(this)}
                  />
                </div>
                <div class="p-login__form-password form-group">
                  <input type="password"
                        class="form-control"
                        placeholder="Password"
                        onChange={EventListener._onPasswordChange.bind(this)}
                  />
                </div>
                <button class="btn btn-lg btn-success btn-block p-login__login-button"
                        type="submit"
                        onClick={(e) => { e.preventDefault(); EventListener._onLoginButtonClicked.bind(this)(e); return false; }}>
                  Login
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
  render() {
    console.trace('LoginPage', this);
    const { redirectToReferrer } = this.state;
    if (redirectToReferrer) {
      return ( <Redirect to="/"/> );
    } else {
      return this._viewTemplate();
    }
  }
}

export default class LoginPage extends React.Component {
  _viewTemplate(siteBody) {
    return (
      <div class="p-login-page">
        <div class="p-login-page__site-body">
          {siteBody}
        </div>
      </div>

    );
  }
  render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } };
    const siteBody = (<LoginComponent redirectTo={from} {...this.props}/>);
    return this._viewTemplate(siteBody);
  }
}
