/**
 * バックエンドのBMTB系のAPIにアクセスするサービス
 */
import config from 'config';
import url from 'url';
import SessionService from 'service/SessionService';
import HandleApiResponseService from 'service/api/HandleApiResponseService';
import ApiService from 'service/api/ApiService';

export default class BmtbApiService {

  static _getHeaders() {
    const session = this.sessionService.load();
    return { 'X-MMT-Session': session };
  }

  static _getUrl(pathname = '', query = {}) {
    const urlObj = Object.assign({ pathname, query }, config.manage.backendServerApi);
    return url.format(urlObj);
  }

  /**
   * ポイント種別一覧を取得する
   */
  static getAssorts() {
    const path = '/api/v1/assorts';
    const promise = this.apiService.get(this._getUrl(path), this._getHeaders());
    return this.handleApiResponseService.handlePromise(promise);
  }

  /**
   * ポイント種別を作成する
   */
  static createAssort(assortId, name, maxPointUnitPrice, consumeOrderRule, expirationRule, expiresAfterDays, expiredAt) {
    const path = '/api/v1/assorts';
    const requestBody = { assortId, name, maxPointUnitPrice, consumeOrderRule, expirationRule };
    if (expiresAfterDays != null) requestBody.expiresAfterDays = expiresAfterDays;
    if (expiredAt != null) requestBody.expiredAt = expiredAt;
    const promise = this.apiService.post(this._getUrl(path), this._getHeaders(), requestBody);
    return this.handleApiResponseService.handlePromise(promise);
  }

  /**
   * ポイント種別を削除する
   */
  static deleteAssort(assortId) {
    const path = `/api/v1/assorts/${assortId}`;
    const promise = this.apiService.delete(this._getUrl(path), this._getHeaders());
    return this.handleApiResponseService.handlePromise(promise);
  }

  /**
   * プロバイダ一覧を取得する
   */
  static getProviders() {
    const path = '/api/v1/providers';
    const promise = this.apiService.get(this._getUrl(path), this._getHeaders());
    return this.handleApiResponseService.handlePromise(promise);
  }

  /**
   * プロバイダを作成する
   */
  static createProvider(providerId, name) {
    const path = '/api/v1/providers';
    const requestBody = { providerId, name };
    const promise = this.apiService.post(this._getUrl(path), this._getHeaders(), requestBody);
    return this.handleApiResponseService.handlePromise(promise);
  }

  /**
   * プロバイダを削除する
   */
  static deleteProvider(providerId) {
    const path = `/api/v1/providers/${providerId}`;
    const promise = this.apiService.delete(this._getUrl(path), this._getHeaders());
    return this.handleApiResponseService.handlePromise(promise);
  }

  /**
   * イベント一覧を取得する
   */
  static getEvents(assortId) {
    const path = `/api/v1/assorts/${assortId}/events`;
    const promise = this.apiService.get(this._getUrl(path), this._getHeaders());
    return this.handleApiResponseService.handlePromise(promise);
  }

  /**
   * イベントを作成する
   */
  static createEvent(assortId, eventId, name, availableStartedAt, availableEndedAt) {
    const path = `/api/v1/assorts/${assortId}/events`;
    const requestBody = { eventId, name };
    if (availableStartedAt != null) query.availableStartedAt = availableStartedAt;
    if (availableEndedAt != null) query.availableEndedAt = availableEndedAt;
    const promise = this.apiService.post(this._getUrl(path), this._getHeaders(), requestBody);
    return this.handleApiResponseService.handlePromise(promise);
  }

  /**
   * イベントを削除する
   */
  static deleteEvent(assortId, eventId) {
    const path = `/api/v1/assorts/${assortId}/events/${eventId}`;
    const promise = this.apiService.delete(this._getUrl(path), this._getHeaders());
    return this.handleApiResponseService.handlePromise(promise);
  }

  /**
   * イベントを作成し利用登録する
   */
  static createAndRegisterEvent(assortId, providerId, eventId, name, availableStartedAt, availableEndedAt) {
    const path = `/api/v1/assorts/${assortId}/providers/${providerId}/events`;
    const requestBody = { eventId, name };
    if (availableStartedAt != null) requestBody.availableStartedAt = availableStartedAt;
    if (availableEndedAt != null) requestBody.availableEndedAt = availableEndedAt;
    const promise = this.apiService.post(this._getUrl(path), this._getHeaders(), requestBody);
    return this.handleApiResponseService.handlePromise(promise);
  }

  /**
   * 商品一覧を取得する
   */
  static getProducts(assortId) {
    const path = `/api/v1/assorts/${assortId}/products`;
    const promise = this.apiService.get(this._getUrl(path), this._getHeaders());
    return this.handleApiResponseService.handlePromise(promise);
  }

  /**
   * 商品を作成する
   */
  static createProduct(assortId, productId, name) {
    const path = `/api/v1/assorts/${assortId}/products`;
    const requestBody = { productId, name };
    const promise = this.apiService.post(this._getUrl(path), this._getHeaders(), requestBody);
    return this.handleApiResponseService.handlePromise(promise);
  }

  /**
   * 商品を削除する
   */
  static deleteProduct(assortId, productId) {
    const path = `/api/v1/assorts/${assortId}/products/${productId}`;
    const promise = this.apiService.delete(this._getUrl(path), this._getHeaders());
    return this.handleApiResponseService.handlePromise(promise);
  }

  /**
   * 商品を作成し利用登録する
   */
  static createAndRegisterProduct(assortId, providerId, productId, name) {
    const path = `/api/v1/assorts/${assortId}/providers/${providerId}/products`;
    const requestBody = { productId, name };
    const promise = this.apiService.post(this._getUrl(path), this._getHeaders(), requestBody);
    return this.handleApiResponseService.handlePromise(promise);
  }

  /**
   * 金券一覧を取得する
   */
  static getVouchers(assortId) {
    const path = `/api/v1/assorts/${assortId}/vouchers`;
    const promise = this.apiService.get(this._getUrl(path), this._getHeaders());
    return this.handleApiResponseService.handlePromise(promise);
  }

  /**
   * 金券を作成する
   */
  static createVoucher(assortId, voucherId, name) {
    const path = `/api/v1/assorts/${assortId}/vouchers`;
    const requestBody = { voucherId, name };
    const promise = this.apiService.post(this._getUrl(path), this._getHeaders(), requestBody);
    return this.handleApiResponseService.handlePromise(promise);
  }

  /**
   * 金券を削除する
   */
  static deleteVoucher(assortId, voucherId) {
    const path = `/api/v1/assorts/${assortId}/vouchers/${voucherId}`;
    const promise = this.apiService.delete(this._getUrl(path), this._getHeaders());
    return this.handleApiResponseService.handlePromise(promise);
  }

  /**
   * 金券を作成し利用登録する
   */
  static createAndRegisterVoucher(
    assortId,
    providerId,
    voucherId,
    name,
    paidPoints,
    freePoints,
    price,
    appleProductId,
    appleBundleId,
    appleProductType,
    googleProductId,
    googlePackageName,
    googlePurchaseType,
    availableStartedAt,
    availableEndedAt,
    providerPaymentMethodGroup
  ) {
    const path = `/api/v1/assorts/${assortId}/providers/${providerId}/vouchers`;
    const requestBody = { voucherId, name, paidPoints, freePoints, price, providerPaymentMethodGroup };
    if (appleProductId != null) requestBody.appleProductId = appleProductId;
    if (appleBundleId != null) requestBody.appleBundleId = appleBundleId;
    if (appleProductType != null) requestBody.appleProductType = appleProductType;
    if (googleProductId != null) requestBody.googleProductId = googleProductId;
    if (googlePackageName != null) requestBody.googlePackageName = googlePackageName;
    if (googlePurchaseType != null) requestBody.googlePurchaseType = googlePurchaseType;
    if (availableStartedAt != null) requestBody.availableStartedAt = availableStartedAt;
    if (availableEndedAt != null) requestBody.availableEndedAt = availableEndedAt;
    const promise = this.apiService.post(this._getUrl(path), this._getHeaders(), requestBody);
    return this.handleApiResponseService.handlePromise(promise);
  }

  /**
   * ポイント残高を取得する
   */
  static getBalance(assortId, userId) {
    const path = `/api/v1/assorts/${assortId}/users/${userId}/balance`;
    const promise = this.apiService.get(this._getUrl(path), this._getHeaders());
    return this.handleApiResponseService.handlePromise(promise);
  }

  /**
   * 取引き履歴を検索する
   */
  static searchDeal(assortId, offset, limit, dealType, dealtAtFrom, dealtAtUntil, dealIds, userId, providerId, kaeruTransactionId, agencyTransactionId) {
    const query = {};
    if (offset != null) query.offset = offset;
    if (limit != null) query.limit = limit;
    if (dealType != null && dealType != '' && dealType != 'all') query.dealType = dealType;
    if (dealtAtFrom != null && dealtAtFrom != '') query.dealtAtFrom = dealtAtFrom;
    if (dealtAtUntil != null && dealtAtUntil != '') query.dealtAtUntil = dealtAtUntil;
    if (dealIds != null) query.dealId = dealIds;
    if (userId != null && userId != '') query.userId = userId;
    if (providerId != null) query.providerId = providerId;
    if (kaeruTransactionId != null && kaeruTransactionId != '') query.kaeruTransactionId = kaeruTransactionId;
    if (agencyTransactionId != null && agencyTransactionId != '') query.agencyTransactionId = agencyTransactionId;

    const path = `/api/v1/assorts/${assortId}/deals/search`;
    const promise = this.apiService.get(this._getUrl(path, query), this._getHeaders());
    return this.handleApiResponseService.handlePromise(promise);
  }

  /**
   * 取引きをキャンセルする
   */
  static cancelDeals(assortId, dealIds, description) {
    const path = `/api/v1/assorts/${assortId}/deals/cancel`;
    const requestBody = { dealIds: dealIds };
    if (description !== '') requestBody.description = description;
    const promise = this.apiService.post(this._getUrl(path), this._getHeaders(), requestBody);
    return this.handleApiResponseService.handlePromise(promise);
  }

  /**
   * 有料ポイントを付与する
   */
  static addPaidPoints(assortId, userId, description, uniqueToken, price, points) {
    const path = `/api/v1/assorts/${assortId}/users/${userId}/points`;
    const requestBody = {
      description: description,
      uniqueToken: uniqueToken,
      points: points,
      price: parseInt(price)
    };
    const promise = this.apiService.post(this._getUrl(path), this._getHeaders(), requestBody);
    return this.handleApiResponseService.handlePromise(promise);
  }

  /**
   * 無料ポイントを付与する
   */
  static addFreePoints(assortId, eventId, targets) {
    const path = `/api/v1/assorts/${assortId}/free-points`;
    const requestBody = {
      eventId: eventId,
      targets: targets
    };
    const promise = this.apiService.post(this._getUrl(path), this._getHeaders(), requestBody);
    return this.handleApiResponseService.handlePromise(promise);
  }

  /**
   * ポイントを失効させる
   */
  static invalidatePoints(assortId, userId, points, description) {
    const path = `/api/v1/assorts/${assortId}/users/${userId}/invalidate`;
    const requestBody = (points != null) ? {description: description, points: points} : {description: description};
    const promise = this.apiService.post(this._getUrl(path), this._getHeaders(), requestBody);
    return this.handleApiResponseService.handlePromise(promise);
  }

  /**
   * ポイントを消費する
   */
  static consumePoints(assortId, userId, points, consumeRule, productId, uniqueToken, description, forceConsume) {
    const path = `/api/v1/assorts/${assortId}/users/${userId}/consume`;
    const requestBody = {
      points,
      productId,
      uniqueToken,
      ...(consumeRule ? { consumeRule } : {}),
      ...(description ? { description } : {}),
      ...(forceConsume ? { forceConsume } : {})
    };
    return this.handleApiResponseService.handlePromise(
      this.apiService.post(this._getUrl(path), this._getHeaders(), requestBody)
    );
  }
}

BmtbApiService.sessionService = SessionService;
BmtbApiService.handleApiResponseService = HandleApiResponseService;
BmtbApiService.apiService = ApiService;
