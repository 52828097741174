import './style.scss';
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom';

import BackendApiService from 'service/api/BackendApiService';
import ErrorHandleService from 'error/ErrorHandleService';
import { ManageApiError } from 'error/ManageError';

class EventListener extends React.Component {
  static _onRegisterButtonClick(e) {
    const { assortId, assortName, maxPointUnitPrice, expiresAfterDays, expiredAt } = this.state;
    const consumeOrderRule = this.refs.consumeOrderRuleSelectBox.selectedOptions[0].value;
    const expirationRule = this.refs.expirationRuleSelectBox.selectedOptions[0].value;
    const maxPointUnitPriceFroat = parseFloat(maxPointUnitPrice);
    const assortIdInt = parseInt(assortId, 10);

    Promise.all([
      BackendApiService.createAssort(assortIdInt, assortName, maxPointUnitPriceFroat, consumeOrderRule, expirationRule, expiresAfterDays, expiredAt)
    ]).then(([ response ]) => {
      console.trace('create assort api succeeded', response);
      setTimeout(() => { location.href = '/apps/mmt/assorts'; }, 500);
    }).catch((error) => {
      console.error('create assort api failed.', error);
      ErrorHandleService.handleError(EventListener._errorHandler.bind(this), error);
    });
  }

  static _errorHandler(error) {
    if (!(error instanceof ManageApiError)) { throw error; }
    const [ status, body, errorCode, errorSubCode, errorMessage ] = [ error.getStatusCode(), error.getResponseBody(), error.getErrorCode(), error.getErrorSubCode(), error.getErrorMessage() ];
    console.debug('error handler.', status, body, errorCode, errorSubCode, errorMessage);
    switch (status) {
      default:
        this.props.mainComponent.setState({ showAlert: true, alertContent: 'エラーが発生しました。' });
        break;
    }
  }

  static _onAssortIdChange(e) {
    this.setState({ assortId: e.target.value });
  }
  static _onAssortNameChange(e) {
    this.setState({ assortName: e.target.value });
  }
  static _onMaxPointUnitPriceChange(e) {
    this.setState({ maxPointUnitPrice: e.target.value });
  }
  static _onExpiresAfterDaysChange(e) {
    this.setState({ expiresAfterDays: e.target.value });
  }
  static _onExpiredAtChange(e) {
    this.setState({ expiredAt: e.target.value });
  }
};

export default class CreateAssortPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isCreateAssortCompleted: false
    };
  }

  render() {
    console.log('CreateAssortPage', this);
    const { isCreateAssortCompleted } = this.state;

    if (isCreateAssortCompleted) {
      return ( <Redirect to={ '/apps/manage/assorts' }/> );
    }

    return (
      <div class="p-create-assort-page container-fluid">
        <div class="p-create-assort-page__header">
          <div class="page-header">
            <h1><small class="text-muted">ポイント種別登録ページ</small></h1>
          </div>
        </div>
        <div class="p-create-assort-page__body row">
          <div class="p-create-assort-page__main container-fluid">
            <form>
              <div class="form-group row">
                <label for="inputAssortId" class="col-sm-2 col-form-label text-right font-weight-bold">ポイント種別ID</label>
                <div class="col-sm-7">
                  <input type="number"
                         class="form-control"
                         id="inputAssortId"
                         placeholder="例: 2"
                         onChange={EventListener._onAssortIdChange.bind(this)} />
                </div>
              </div>
              <div class="form-group row">
                <label for="inputAssortName" class="col-sm-2 col-form-label text-right font-weight-bold">ポイント種別名</label>
                <div class="col-sm-7">
                  <input type="text"
                         class="form-control"
                         id="inputAssortName"
                         placeholder=""
                         onChange={EventListener._onAssortNameChange.bind(this)} />
                </div>
              </div>
              <div class="form-group row">
                <label for="inputMaxPointUnitPrice" class="col-sm-2 col-form-label text-right font-weight-bold">ポイント単価の上限</label>
                <div class="col-sm-7">
                  <input type="number"
                         class="form-control"
                         min="0"
                         id="inputMaxPointUnitPrice"
                         placeholder="例: 1.2"
                         onChange={EventListener._onMaxPointUnitPriceChange.bind(this)} />
                </div>
              </div>
              <div class="form-group row">
                <label for="inputConsumeOrderRule" class="col-sm-2 col-form-label text-right font-weight-bold">ポイント消費順ルール</label>
                <div class="col-sm-7">
                  <select class="form-control" ref="consumeOrderRuleSelectBox">
                    <option key="created_at" value="created_at">作成日が古いポイント優先</option>
                    <option key="paid_points_and_created_at" value="paid_points_and_created_at">有料ポイント優先かつ作成日が古いポイント優先</option>
                    <option key="free_points_and_created_at" value="free_points_and_created_at">無料ポイント優先かつ作成日が古いポイント優先</option>
                    <option key="expired_at" value="expired_at">失効日が近いポイント優先</option>
                    <option key="expired_at_and_free_points" value="expired_at_and_free_points">失効日が近い順（期限が同一の場合には無料ポイント優先）</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label for="inputExpirationRule" class="col-sm-2 col-form-label text-right font-weight-bold">ポイント失効ルール</label>
                <div class="col-sm-7">
                  <select class="form-control" ref="expirationRuleSelectBox">
                    <option key="never" value="never">失効なし</option>
                    <option key="after_days" value="after_days">N日後に失効</option>
                    <option key="on_expired_at" value="on_expired_at">次の失効日に失効</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label for="inputExpiresAfterDays" class="col-sm-2 col-form-label text-right font-weight-bold">失効日数</label>
                <div class="col-sm-7">
                  <input type="text"
                         class="form-control"
                         id="inputExpiresAfterDays"
                         placeholder=""
                         onChange={EventListener._onExpiresAfterDaysChange.bind(this)} />
                </div>
              </div>
              <div class="offset-sm-2 col-sm-10">
                <p>ポイント失効ルールが「N日後に失効」のときのみ入力してください。</p>
                <p>ポイントが付与された時刻に、失効日数を加えた値がそのポイントの失効日となります。</p>
              </div>
              <div class="form-group row">
                <label for="inputExpiredAt" class="col-sm-2 col-form-label text-right font-weight-bold">失効日時</label>
                <div class="col-sm-7">
                  <input type="datetime-local"
                         class="form-control"
                         placeholder="例 2017-01-01T00:00:00"
                         value={this.state.expiredAt}
                         onChange={EventListener._onExpiredAtChange.bind(this)}/>
                </div>
              </div>
              <div class="offset-sm-2 col-sm-10">
                <p>ポイント失効ルールが「次の失効日に失効」のときのみ入力してください。</p>
                <p>ポイントが付与された日付から、次の失効日の00:00がそのポイントの失効日となります。</p>
                <p>一年に一回の失効となります。</p>
                <p>例: 失効日時が2018-10-01の場合</p>
                <p>・2018-07-01 12:10に付与したポイントの失効日は、2018-10-01 00:00</p>
                <p>・2018-11-01 12:10に付与したポイントの失効日は、2019-10-01 00:00</p>
              </div>
              <div class="form-group row">
                <div class="offset-sm-1 col-sm-10">
                  <button type="button"
                          class="btn btn-primary btn-lg"
                          onClick={EventListener._onRegisterButtonClick.bind(this)}>
                    登録
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
