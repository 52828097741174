import './style.scss';
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom';

import AuthenticationRequiredRoute from 'components/AuthenticationRequiredRoute/AuthenticationRequiredRoute';
import ManageApp from 'components/ManageApp/ManageApp';
import MmtApp from 'components/MmtApp/MmtApp';
import AccountApp from 'components/AccountApp/AccountApp';
import LoginPage from 'components/LoginPage/LoginPage';
import Alert from './Alert';

// TODO: typescript使う
// TODO: redux使う
// TODO: リンクのURLを一箇所で生成するようにする
/**
 * エントリーポイントとなるコンポーネント
 *
 * 以下のことを行います
 * - 認証が必要なページの保護
 * - 各アプリケーションへのルーティング
 * - 各種APIにより取得した情報の管理
 */
export default class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // アラート
      showAlert: false,
      alertContent: '',
      // 各種APIが終了したかどうかと取得した情報
      isAccountInfoLoading: true,
      isAccountRolesLoading: true,
      account: null,
      accountRoles: [],
      isUsersLoading: true,
      users: [],
      isRolesLoading: true,
      roles: [],
      isGroupsLoading: true,
      groups: [],
      isClientsLoading: true,
      assorts: [],
      isAssortsLoading: true,
      providers: [],
      isProvidersLoading: true,
      assortEvents: [],
      isAssortEventsLoading: false,
      assortProducts: [],
      isAssortProductsLoading: false,
      assortVouchers: [],
      isAssortVouchersLoading: false
    };
  }

  render() {
    console.trace('Main', this);
    const { showAlert, alertContent } = this.state;
    return (
      <Router>
        <div class="container-fluid p-main">
          <Alert display={showAlert} content={alertContent} mainComponent={this}/>
          <Switch>
            <AuthenticationRequiredRoute path="/apps/manage" component={ManageApp} mainComponent={this}/>
            <AuthenticationRequiredRoute path="/apps/account" component={AccountApp} mainComponent={this}/>
            <AuthenticationRequiredRoute path="/apps/mmt" component={MmtApp} mainComponent={this}/>
            <Route path="/login" render={props => (<LoginPage {...props} mainComponent={this}/>)}/>
            <Redirect from="/" to="/apps/account"/>
          </Switch>
        </div>
      </Router>
    );
  }
};
