import './style.scss';
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom';

import MiniLoadingIcon from 'components/LoadingIcon/MiniLoadingIcon';

import BackendApiService from 'service/api/BackendApiService';
import ErrorHandleService from 'error/ErrorHandleService';
import { ManageApiError } from 'error/ManageError';

export default class VoucherListPage extends React.Component {

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentDidUpdate() {
    const assortVoucher = this.props.mainComponent.state.assortVouchers.find((assortVoucher) => { return assortVoucher.assortId == this.props.assortId; })
    const { vouchers, isVouchersLoading } = (assortVoucher != null) ? assortVoucher : { vouchers: [], isVouchersLoading: true };
    if (isVouchersLoading) {
      Promise.all([
        this.props.backendApiService.getVouchers(this.props.assortId)
      ]).then(([ vouchersResponse ]) => {
        console.trace('get vouchers api succeeded', this.props.assortId, vouchersResponse);
        const assortVouchers = [].concat(this.props.mainComponent.state.assortVouchers, [{
          vouchers: vouchersResponse.data.vouchers,
          assortId: this.props.assortId,
          isVouchersLoading: false
        }]);
        this.props.mainComponent.setState({ assortVouchers });
      }).catch((error) => {
        console.error('get vouchers api failed.', error);
        this.props.errorHandleService.handleError(VoucherListPage._errorHandler.bind(this), error);
      });
    }
  }

  static _errorHandler(error) {
    if (!(error instanceof ManageApiError)) { throw error; }
    const [ status, body, errorCode, errorSubCode, errorMessage ] = [ error.getStatusCode(), error.getResponseBody(), error.getErrorCode(), error.getErrorSubCode(), error.getErrorMessage() ];
    console.debug('error handler.', status, body, errorCode, errorSubCode, errorMessage);
    switch (status) {
      default:
        this.props.mainComponent.setState({ showAlert: true, alertContent: 'エラーが発生しました。' });
        break;
    }
  }

  _voucherItem(assortId, voucher) {
    return (
      <tr key={voucher.id}>
        <td> {voucher.id} </td>
        <td> {voucher.name} </td>
        <td> {voucher.price} </td>
        <td> {voucher.paidPoints} </td>
        <td> {voucher.freePoints} </td>
        <td> {voucher.availableStartedAt} </td>
        <td> {voucher.availableEndedAt} </td>
        <td> {voucher.createdAt} </td>
        <td> {voucher.updatedAt} </td>
        <td>
          <button type="button" class="btn btn-light btn-sm">
            <Link to={ `/apps/mmt/assorts/${assortId}/vouchers/${voucher.id}` }>
              <i class="fa fa-pencil-square-o" aria-hidden="true"></i> 編集
            </Link>
          </button>
        </td>
      </tr>
    );
  }

  _voucherList(assortId, vouchers) {
    return (
      <table class="table table-bordered">
        <thead>
          <tr>
            <th> ID </th>
            <th> 金券名 </th>
            <th> 価格 </th>
            <th> 有料ポイント数 </th>
            <th> 無料ポイント数 </th>
            <th> 利用開始日時 </th>
            <th> 利用終了日時 </th>
            <th> 作成日時 </th>
            <th> 更新日時 </th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {vouchers.map((voucher) => {
             return this._voucherItem(assortId, voucher);
           })}
        </tbody>
      </table>
    );
  }

  render() {
    console.log('VoucherListPage', this);
    const { isAssortVouchersLoading } = this.props.mainComponent.state;
    const assortVoucher = this.props.mainComponent.state.assortVouchers.find((assortVoucher) => { return assortVoucher.assortId == this.props.assortId; })
    const { vouchers, isVouchersLoading } = (assortVoucher != null) ? assortVoucher : { vouchers: [], isVouchersLoading: true };
    if (isVouchersLoading || isAssortVouchersLoading) {
      return ( <MiniLoadingIcon/> );
    }
    return (
      <div class="p-voucher-list container-fluid">
        <div class="p-voucher-list__header">
          <div class="page-header">
            <h1><small class="text-muted">金券一覧</small></h1>
          </div>
        </div>
        <div class="p-voucher-list__body row">
          <div class="p-voucher-list__main container-fluid">
            { this._voucherList(this.props.assortId, vouchers) }
          </div>
        </div>
      </div>
    );
  }
}

VoucherListPage.propTypes = {
  backendApiService: BackendApiService,
  errorHandleService: ErrorHandleService
};

VoucherListPage.defaultProps = {
  backendApiService: BackendApiService,
  errorHandleService: ErrorHandleService
};
