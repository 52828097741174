/**
 * 小さめのローディング画面
 */
import './style.scss';
import React from 'react';

export default class MiniLoadingIcon extends React.Component {
  render() {
    return (
      <div class="p-loader">
        <div class="p-loading-icon">
          <div class="p-loading-icon__image">
            <img src="/image/loading.gif" alt=""/>
          </div>
        </div>
      </div>
    );
  }
};


