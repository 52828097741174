import { ManageError, ManageApiError } from './ManageError';

export default class ErrorHandleService {

  // 全ページで共通のエラーハンドラ
  static _defaultCommonErrorHandler(error) {
    if (error instanceof ManageApiError) {
      ErrorHandleService._defaultCommonApiErrorHandler(error);
    } else {
      throw error;
    }
  };

  static _defaultCommonApiErrorHandler(error) {
    const [ status, body, errorCode ] = [ error.getStatusCode(), error.getResponseBody(), error.getErrorCode() ];
    console.debug('common api error handler.', status, body, errorCode);
    // TODO: 共通のエラー処理実装 
    switch (status) {
    case 401:
      if (errorCode == 'UNAUTHORIZED') {
        console.log('UNAUTHORIZED');
        setTimeout(() => { location.href = '/login'; }, 500);
      } else {
        throw error;
      }
      break;
    case 500:
      // サーバー側でエラーが発生
      if (errorCode == 'INTERNAL_SERVER_ERROR') {
        console.log('INTERNAL_SERVER_ERROR');
      } else {
        throw error;
      }
      break;
    default:
      // 各ページのエラーハンドラと共通エラーハンドラで想定していないエラー。
      // エラーの取り漏らしの可能性が高い。
      console.debug('unknown error in common error handler.', error, error.stack);
      throw error;
      break;
    }
  }

  static _composeErrorHandler(errorHandler = (error) => { throw error; }, commonErrorHandler = ErrorHandleService._defaultCommonErrorHandler) {
    return (err) => {
      try {
        errorHandler(err);
      } catch (e) {
        commonErrorHandler(e);
      }
    };
  };

  static handleError(errorHandler = (error) => { throw error; }, error) {
    ErrorHandleService._composeErrorHandler(errorHandler)(error);
  }
};
