import './style.scss';
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom';

export default class Alert extends React.Component {

  _removeAlertButtonClick(e) {
    this.props.mainComponent.setState({ showAlert: false, alertContent: '' });
  }

  render() {
    console.trace('Alert', this);
    const { display, content } = this.props;
    const alertComponent = (display) ? (
      <div class="p-alert alert alert-danger alert-dismissible">
        <button type="button" class="close" onClick={this._removeAlertButtonClick.bind(this)}>
          <i class="fa fa-times"/>
        </button>
        <i class="fa fa-info-circle"/>
        {content}
      </div>
    ) : null;

    // 表示したときは10秒後に自動で消す
    if (display) setTimeout(() => { this._removeAlertButtonClick(null) }, 10000);

    return (
      <div class="p-alert">
        {alertComponent}
      </div>
    );
  }
}
