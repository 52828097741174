import './style.scss';
import React from 'react';

import createBrowserHistory from 'history/createBrowserHistory';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom';

import BackendApiService from 'service/api/BackendApiService';
import ErrorHandleService from 'error/ErrorHandleService';
import { ManageApiError } from 'error/ManageError';
import PageHeaderBase from './PageHeaderBase';

const PAGE_TYPES = {
  BALANCE : 'BALANCE',
  SEARCH_DEAL : 'SEARCH_DEAL'
};

class EventListener {
  static _onReloadUsersButtonClick() {
    console.log('onReloadUsersButtonClick', this);
    this.setState({ isUsersLoading: true, users: [] });

    Promise.all([
      BackendApiService.getUsers()
    ]).then(([ response ]) => {
      console.trace('get users api succeeded', response);
      setTimeout(() => {
        this.setState({ isUsersLoading: false, users: response.data.users });
      }, 500);
    }).catch((error) => {
      console.error('get users api failed.', error);
      ErrorHandleService.handleError(EventListener._errorHandler.bind(this), error);
    });
  }

  static _errorHandler(error) {
    if (!(error instanceof ManageApiError)) { throw error; }
    const [ status, body, errorCode, errorSubCode, errorMessage ] = [ error.getStatusCode(), error.getResponseBody(), error.getErrorCode(), error.getErrorSubCode(), error.getErrorMessage() ];
    console.debug('error handler.', status, body, errorCode, errorSubCode, errorMessage);
    switch (status) {
      default:
        this.props.mainComponent.setState({ showAlert: true, alertContent: 'エラーが発生しました。' });
        break;
    }
  }
}

export default class MmtPageHeader extends React.Component {
  constructor(props) {
    super(props);
  }

  _balanceTypeView() {
    const history = createBrowserHistory();
    return (
      <div class="collapse navbar-collapse">
      </div>
    );
  }

  _searchDealTypeView() {
    const history = createBrowserHistory();
    return (
      <div class="collapse navbar-collapse">
      </div>
    );
  }

  _viewTemplate(view, assort) {
    const assortName = (assort != null) ? assort.name : '';
    const title = `ポイント管理 ${assortName}`;
    return (
      <PageHeaderBase title={title}>
        {view}
      </PageHeaderBase>
    );
  }

  render() {
    console.log('MmtPageHeader', this);
    const { pageType, assortId } = this.props;
    const { assorts } = this.props.mainComponent.state;
    const assort = assorts.find((a) => { return a.id == assortId; })

    switch (pageType){
      case PAGE_TYPES.BALANCE:
        return this._viewTemplate(this._balanceTypeView(), assort);
      case PAGE_TYPES.SEARCH_DEAL:
        return this._viewTemplate(this._searchDealTypeView(), assort);
      default:
        return this._viewTemplate(this._balanceTypeView(), assort);
    }
  }
}

MmtPageHeader.PAGE_TYPES = PAGE_TYPES;
