/**
 * セッションを管理するサービス
 *
 * セッションローカルストレージに保存しています
 */
export default class SessionService {
  static _getKey() {
    return 'manage_user_session';
  }

  static load() {
    return localStorage.getItem(this._getKey());
  }

  static save(session) {
    localStorage.setItem(this._getKey(), session);
  }

  static clear() {
    localStorage.removeItem(this._getKey());
  }
}
