import './style.scss';
import React from 'react';

import createBrowserHistory from 'history/createBrowserHistory';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom';

import BackendApiService from 'service/api/BackendApiService';
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog';
import ErrorHandleService from 'error/ErrorHandleService';
import { ManageApiError } from 'error/ManageError';
import PageHeaderBase from './PageHeaderBase';

const PAGE_TYPES = {
  LIST : 'LIST',
  CREATE : 'CREATE',
  EDIT : 'EDIT'
};

class EventListener {
  static _deleteProduct() {
    const { assortId, productId } = this.props;
    if (productId == null) { return ; }
    console.log('delete product', assortId, productId);

    Promise.all([
      BackendApiService.deleteProduct(assortId, productId)
    ]).then(([ response ]) => {
      console.trace('delete product api succeeded', response);
      setTimeout(() => { location.href = `/apps/mmt/assorts/${assortId}/products`; }, 500);
    }).catch((error) => {
      console.error('delete product api failed.', error);
      ErrorHandleService.handleError(EventListener._errorHandler.bind(this), error);
    });
  }

  static _onReloadProductsButtonClick() {
    console.log('onReloadProductsButtonClick', this);
    const assortId = this.props.assortId;
    this.props.mainComponent.setState({ isAssortProductsLoading: true });

    Promise.all([
      BackendApiService.getProducts(assortId)
    ]).then(([ response ]) => {
      console.trace('get products api succeeded', this.props.assortId, response);
      const tmpAssortProducts = this.props.mainComponent.state.assortProducts.filter((assortProduct) => { return assortProduct.assortId != assortId; })
      const assortProducts = [].concat(tmpAssortProducts, [{
        products: response.data.products,
        assortId: assortId,
        isProductsLoading: false
      }]);
      setTimeout(() => { this.props.mainComponent.setState({ assortProducts, isAssortProductsLoading: false }); }, 500);
    }).catch((error) => {
      console.error('get products api failed.', error);
      ErrorHandleService.handleError(EventListener._errorHandler.bind(this), error);
    });
  }

  static _errorHandler(error) {
    if (!(error instanceof ManageApiError)) { throw error; }
    const [ status, body, errorCode, errorSubCode, errorMessage ] = [ error.getStatusCode(), error.getResponseBody(), error.getErrorCode(), error.getErrorSubCode(), error.getErrorMessage() ];
    console.debug('error handler.', status, body, errorCode, errorSubCode, errorMessage);
    switch (status) {
      default:
        this.props.mainComponent.setState({ showAlert: true, alertContent: 'エラーが発生しました。' });
        break;
    }
  }
}

export default class ProductPageHeader extends React.Component {
  constructor(props) {
    super(props);
  }

  _listTypeView(assortId) {
    const history = createBrowserHistory();
    return (
      <div class="collapse navbar-collapse">
        <button type="button" class="p-page-header__button btn btn-light">
          <Link to={ `/apps/mmt/assorts/${assortId}/products` }>
            <i class="fa fa-home fa-lg"/>
          </Link>
        </button>
        <button type="button" class="p-page-header__button btn btn-light" onClick={ (e) => { history.goBack(); } }>
          <i class="fa fa-arrow-left fa-lg"/>
        </button>
        <button type="button" class="p-page-header__button btn btn-light"
                onClick={ EventListener._onReloadProductsButtonClick.bind(this) }>
          <i class="fa fa-refresh fa-lg"/>
        </button>
        <button type="button" class="p-page-header__button btn btn-light">
          <Link to={ `/apps/mmt/assorts/${assortId}/products/new` }>
            <i class="fa fa-plus"/> 作成
          </Link>
        </button>
      </div>
    );
  }

  _createTypeView(assortId) {
    const history = createBrowserHistory();
    return (
      <div class="collapse navbar-collapse">
        <button type="button" class="p-page-header__button btn btn-light">
          <Link to={ `/apps/mmt/assorts/${assortId}/products` }>
            <i class="fa fa-home fa-lg"/>
          </Link>
        </button>
        <button type="button" class="p-page-header__button btn btn-light" onClick={ (e) => { history.goBack(); } }>
          <i class="fa fa-arrow-left fa-lg"/>
        </button>
      </div>
    );
  }

  _editTypeView(assortId) {
    const history = createBrowserHistory();
    return (
      <div class="collapse navbar-collapse">
        <button type="button" class="p-page-header__button btn btn-light">
          <Link to={ `/apps/mmt/assorts/${assortId}/products` }>
            <i class="fa fa-home fa-lg"/>
          </Link>
        </button>
        <button type="button" class="p-page-header__button btn btn-light" onClick={ (e) => { history.goBack(); } }>
          <i class="fa fa-arrow-left fa-lg"/>
        </button>
        <button type="button" class="p-page-header__button btn btn-light">
          <Link to={ `/apps/mmt/assorts/${assortId}/products` }>
            <i class="fa fa-plus"/> 作成
          </Link>
        </button>
        <button type="button" class="p-page-header__button btn btn-danger"
                data-target="#deleteProduct" data-toggle="modal">
          <i class="fa fa-trash"/> 削除
        </button>
      </div>
    );
  }

  _viewTemplate(view, assort) {
    const assortName = (assort != null) ? assort.name : '';
    const title = `${assortName} 商品管理`;
    const dialogs = (
      <div>
        <DeleteProductConfirmationDialog onOkButtonClicked={EventListener._deleteProduct.bind(this)}/>
      </div>
    );
    return (
      <PageHeaderBase title={title} dialogs={dialogs}>
        {view}
      </PageHeaderBase>
    );
  }

  render() {
    console.log('ProductPageHeader', this);
    const { pageType, assortId } = this.props;
    const { assorts } = this.props.mainComponent.state;
    const assort = assorts.find((a) => { return a.id == assortId; })

    switch (pageType){
      case PAGE_TYPES.LIST:
        return this._viewTemplate(this._listTypeView(assortId), assort);
      case PAGE_TYPES.CREATE:
        return this._viewTemplate(this._createTypeView(assortId), assort);
      case PAGE_TYPES.EDIT:
        return this._viewTemplate(this._editTypeView(assortId), assort);
      default:
        return this._viewTemplate(this._listTypeView(assortId), assort);
    }
  }
}

ProductPageHeader.PAGE_TYPES = PAGE_TYPES;

/**
 * 商品削除の確認ダイアログ
 */
class DeleteProductConfirmationDialog extends ConfirmationDialog {};

DeleteProductConfirmationDialog.defaultProps = {
  id: 'deleteProduct',
  title: '',
  body: '本当に削除しますか？',
  onCancelButtonClicked: (e) => { return null; }
};
