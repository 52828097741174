/**
 * 取引きキャンセルに利用するダイアログ
 */
import React from 'react';

/**
 * 取引きキャンセル前の確認用ダイアログ
 */
export class CancelDealsConfirmationDialog extends React.Component {
  render() {
    const { deals, cancelDescription } = this.props;
    console.log('CancelDealsConfirmationDialog', this, deals);

    return (
      <div class="modal fade" id="cancelDeals" role="dialog">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">取引きキャンセル対象確認 (合計 {deals.length} 件)</h4>
            </div>
            <div class="modal-body">
              <p class="offset-sm-1 form-text text-muted"> 以下の取引きをキャンセルします。本当によろしいですか？ </p>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th> 取引きID </th>
                    <th> ユーザーID </th>
                    <th> 取引き区分 </th>
                    <th> 取引き内容 </th>
                    <th> ポイント数 </th>
                    <th> 売上金額 </th>
                    <th> プロバイダ名 </th>
                    <th> 取引き日時 </th>
                  </tr>
                </thead>
                <tbody>
                  {deals.map((deal) => {
                     return (
                       <tr key={deal.id}>
                         <td> {deal.id} </td>
                         <td> {deal.userId} </td>
                         <td> {CancelDealsConfirmationDialog._getDealTypeLabel(deal.dealType)} </td>
                         <td> {deal.description} </td>
                         <td class="text-right"> {deal.points} pt </td>
                         <td class="text-right"> {deal.salesPrice} 円 </td>
                         <td> {deal.provider.name} </td>
                         <td> {deal.dealtAt} </td>
                       </tr>
                     );
                   })}
                </tbody>
              </table>
              <textarea className = "form-control" rows = "2" placeholder = "取引きキャンセル理由（任意）" value={cancelDescription} onChange={this.props.onCancelDescriptionChanged} />
            </div>
            <div class="modal-footer">
              <button class="btn btn-light"
                      data-dismiss="modal"
                      type="button"
                      onClick={this.props.onCancelButtonClicked}>閉じる</button>
              <button class="btn btn-light"
                      data-dismiss="modal"
                      type="button"
                      onClick={this.props.onOkButtonClicked}>実行</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  static _getDealTypeLabel(dealType) {
    const dealTypeLabels = {
      'all': 'すべて',
      'add_free_points': '無料ポイント付与',
      'auto_charge': 'オートチャージ',
      'purchase_voucher': '金券購入',
      'consume_points': 'ポイント消費',
      'points_expired': 'ポイント期限失効',
      'points_invalidated': 'ポイント失効',
      'cancel_deal': '取引きキャンセル'
    }
    return dealTypeLabels[dealType];
  }
};

/**
 * 取引きキャンセル後の結果確認用ダイアログ
 */
export class CancelDealsResultDialog extends React.Component {
  render() {
    const { cancelDealsResult } = this.props;
    console.log('CancelDealsResultDialog', this, cancelDealsResult);

    return (
      <div class="modal fade" id="cancelDeals" role="dialog">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">キャンセル結果確認</h4>
            </div>
            <div class="modal-body">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th> 取引きID </th>
                    <th> キャンセル結果 </th>
                    <th> エラー理由 </th>
                  </tr>
                </thead>
                <tbody>
                  {cancelDealsResult.canceledDealIds.map((dealId) => {
                     return (
                       <tr key={dealId}>
                         <td> {dealId} </td>
                         <td> 成功 </td>
                         <td> </td>
                       </tr>
                     );
                   })}
                  {cancelDealsResult.errors.map((error) => {
                     const [ dealId, errorMessage ] = [ error.dealId, error.meta.errorMessage ];
                     return (
                       <tr key={dealId}>
                         <td> {dealId} </td>
                         <td> 失敗 </td>
                         <td> {errorMessage} </td>
                       </tr>
                     );
                   })}
                </tbody>
              </table>
            </div>
            <div class="modal-footer">
              <button class="btn btn-light"
                      data-dismiss="modal"
                      type="button"
                      onClick={this.props.onOkButtonClicked}>OK</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
