import './style.scss';
import React from 'react';
import { BrowserRouter as Router, Route, Link, Redirect, Switch, withRouter } from 'react-router-dom';
import PrivilegeService from 'service/PriviledgeService';

// ニコポのポイント種別ID。ニコポは取引き検索などのAPIが利用できないため管理ツールでは利用できません。
const nicopointAssortId = 1;
// 利用できないポイント種別一覧
const unavailableAssortIds = [ nicopointAssortId ];

// TODO: 項目をホバーしたときに強調するようにcss修正する
export default class PageSideBar extends React.Component {

  _manageToolManage(isManageUsersVisible, isManageGroupsVisible, isManageRolesVisible) {

    if (!isManageUsersVisible && !isManageGroupsVisible && !isManageRolesVisible) { return null; };

    return (
      <li class="p-page-sidebar__item nav-item">
        <span class="nav-link" data-toggle="" data-target="#manage-app-list">
          管理ツール管理 <i class="fa fa-fw fa-caret-down"/>
        </span>
        <ul id="manage-app-list" class="">
          {(isManageUsersVisible) ? (
             <li>
               <Link to="/apps/manage/users">
                 <i class="fa fa-fw fa-users"/> ユーザー管理
               </Link>
             </li>
           ) : null }
          {(isManageGroupsVisible) ? (
             <li>
               <Link to="/apps/manage/groups">
                 <i class="fa fa-fw fa-google"/> グループ管理
               </Link>
             </li>
           ) : null }
          {(isManageRolesVisible) ? (
             <li>
               <Link to="/apps/manage/roles">
                 <i class="fa fa-fw fa-user-times"/> 権限管理
               </Link>
             </li>
           ) : null }
        </ul>
      </li>
    );
  }

  _bmtbManage(assorts, isBmtbSearchDealVisible, isBmtbBalanceVisible, isBmtbAddFreePointsVisible, isBmtbInvalidatePointsVisible, isBmtbAssortsVisible, isBmtbProvidersVisible, isBmtbProductsVisible, isBmtbEventsVisible, isBmtbVouchersVisible, isBmtbForceConsumePointsVisible, isBmtbAddPaidPointsVisible) {
    const assortView = (isBmtbAssortsVisible) ? (
      <li>
        <Link to="/apps/mmt/assorts">
          <i class="fa fa-fw fa-adn"/> ポイント種別管理
        </Link>
      </li>
    ) : null;
    const providerView = (isBmtbProvidersVisible) ? (
      <li>
        <Link to="/apps/mmt/providers">
          <i class="fa fa-fw fa-product-hunt"/> プロバイダ管理
        </Link>
      </li>
    ) : null;
    // 利用できないポイント種別は表示から除外しています
    const availableAssorts = assorts.filter((a) => { return !unavailableAssortIds.includes(a.id); });
    return (
      <li class="p-page-sidebar__item nav-item">
        <span class="nav-link" data-toggle="collapse" data-target="#bmtb-app-list">
          ポイント管理 <i class="fa fa-fw fa-caret-down"/>
        </span>
        <ul id="bmtb-app-list" class="collapse show">
          {assortView}
          {providerView}
          {availableAssorts.map((assort) => {
             const searchDealView = (isBmtbSearchDealVisible) ? (
               <li>
                 <Link to={ `/apps/mmt/assorts/${assort.id}/search-deal` }>
                   <i class="fa fa-fw fa-rocket"/> 取引き履歴検索
                 </Link>
               </li>
             ) : null;
             const balanceView = (isBmtbBalanceVisible) ? (
               <li>
                 <Link to={ `/apps/mmt/assorts/${assort.id}/balance` }>
                   <i class="fa fa-fw fa-building"/> ポイント残高確認
                 </Link>
               </li>
             ) : null;
             const addPaidPointsView = (isBmtbAddPaidPointsVisible) ? (
               <li>
                 <Link to={`/apps/mmt/assorts/${assort.id}/add-paid-points`}>
                   <i className="fa fa-fw fa-yen"/> 有料ポイント付与
                 </Link>
               </li>
             ) : null;
             const addFreePointsView = (isBmtbAddFreePointsVisible) ? (
               <li>
                 <Link to={ `/apps/mmt/assorts/${assort.id}/add-free-points` }>
                   <i class="fa fa-fw fa-gift"/> 無料ポイント付与
                 </Link>
               </li>
             ) : null;
            const forceConsumePoints = (isBmtbForceConsumePointsVisible) ? (
              <li>
                <Link to={ `/apps/mmt/assorts/${assort.id}/force-consume-points` }>
                  <i class="fa fa-fw fa-fire"></i> ポイント強制消費
                </Link>
              </li>
            ) : null;
             const invalidatePointsView = (isBmtbInvalidatePointsVisible) ? (
               <li>
                 <Link to={ `/apps/mmt/assorts/${assort.id}/invalidate-points` }>
                   <i class="fa fa-fw fa-tint"/> ポイント失効
                 </Link>
               </li>
             ) : null;
             const eventsView = (isBmtbEventsVisible) ? (
               <li>
                 <Link to={ `/apps/mmt/assorts/${assort.id}/events` }>
                   <i class="fa fa-fw fa-calendar"/> イベント管理
                 </Link>
               </li>
             ) : null;
             const productsView = (isBmtbProductsVisible) ? (
               <li>
                 <Link to={ `/apps/mmt/assorts/${assort.id}/products` }>
                   <i class="fa fa-fw fa-book"/> 商品管理
                 </Link>
               </li>
             ) : null;
             const vouchersView = (isBmtbVouchersVisible) ? (
               <li>
                 <Link to={ `/apps/mmt/assorts/${assort.id}/vouchers` }>
                   <i class="fa fa-fw fa-money"/> 金券管理
                 </Link>
               </li>
             ) : null;
             return (
               <div key={assort.id} class="mb-2">
                 <span class="nav-link" data-toggle="collapse" data-target={ `#assort-${assort.id}-list` }>
                   {assort.name} <i class="fa fa-fw fa-caret-down"/>
                 </span>
                 <ul id={ `assort-${assort.id}-list` } class="collapse pl-2">
                   {searchDealView}
                   {balanceView}
                   {addPaidPointsView}
                   {addFreePointsView}
                   {forceConsumePoints}
                   {invalidatePointsView}
                   {eventsView}
                   {productsView}
                   {vouchersView}
                 </ul>
               </div>
             );
           })}
        </ul>
      </li>
    );
  }

  render() {
    const { assorts, accountRoles } = this.props.mainComponent.state;
    const { isManageUsersVisible, isManageGroupsVisible, isManageRolesVisible, isBmtbSearchDealVisible, isBmtbBalanceVisible, isBmtbAddFreePointsVisible, isBmtbInvalidatePointsVisible, isIrsGetInquiryVisible, isBmtbAssortsVisible, isBmtbProvidersVisible, isBmtbProductsVisible, isBmtbEventsVisible, isBmtbVouchersVisible, isBmtbForceConsumePointsVisible, isBmtbAddPaidPointsVisible } = PrivilegeService.getVisibleInfo(accountRoles.roles);

    return (
      <nav class="navbar navbar-expand-sm navbar-dark bg-dark p-page-sidebar">
        <div class="p-page-sidebar__main container-fluid">
          <div class="p-page-sidebar__main-sub collapse navbar-collapse">
            <ul class="p-page-sidebar__list nav navbar-nav d-flex flex-column align-items-start">
              {this._manageToolManage(isManageUsersVisible, isManageGroupsVisible, isManageRolesVisible)}
              {this._bmtbManage(assorts, isBmtbSearchDealVisible, isBmtbBalanceVisible, isBmtbAddFreePointsVisible, isBmtbInvalidatePointsVisible, isBmtbAssortsVisible, isBmtbProvidersVisible, isBmtbProductsVisible, isBmtbEventsVisible, isBmtbVouchersVisible, isBmtbForceConsumePointsVisible, isBmtbAddPaidPointsVisible)}
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}
