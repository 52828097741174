import './style.scss';
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom';

import MiniLoadingIcon from 'components/LoadingIcon/MiniLoadingIcon';

import BackendApiService from 'service/api/BackendApiService';
import ErrorHandleService from 'error/ErrorHandleService';
import { ManageApiError } from 'error/ManageError';

class EventListener {

  static _onSearchButtonClick(e) {
    const { userId } = this.state;
    this.setState({ isBalanceLoading: true });
    Promise.all([
      this.props.backendApiService.getBalance(this.props.assortId, userId)
    ]).then(([ response ]) => {
      console.trace('get balance api succeeded', response);
      this.setState({ isBalanceLoading: false, balance: response.data, balanceUserId: userId });
    }).catch((error) => {
      console.error('get balance api failed.', error);
      this.setState({ isBalanceLoading: false });
      ErrorHandleService.handleError(EventListener._errorHandler.bind(this), error);
    });
  }

  static _errorHandler(error) {
    if (!(error instanceof ManageApiError)) { throw error; }
    const [ status, body, errorCode, errorSubCode, errorMessage ] = [ error.getStatusCode(), error.getResponseBody(), error.getErrorCode(), error.getErrorSubCode(), error.getErrorMessage() ];
    console.debug('error handler.', status, body, errorCode, errorSubCode, errorMessage);
    switch (status) {
      default:
        this.props.mainComponent.setState({ showAlert: true, alertContent: 'エラーが発生しました。' });
        break;
    }
  }

  static _onUserIdChange(e) {
    this.setState({ userId: e.target.value });
  }
}

export default class BalancePage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      userId: '',
      isBalanceLoading: false,
      balance: null,
      balanceUserId: ''
    };
  }

  _balanceView(userId, balance) {
    if (balance == null) {
      return null;
    }
    return (
      <table class="p-balance__table table table-bordered">
        <thead>
          <tr>
            <th> ユーザーID </th>
            <th> 合計ポイント数 </th>
            <th> 有料ポイント数 </th>
            <th> 無料ポイント数 </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td> {userId} </td>
            <td> {balance.totalPoints} </td>
            <td> {balance.details.paidPoints} </td>
            <td> {balance.details.freePoints} </td>
          </tr>
        </tbody>
      </table>
    );
  }

  _searchBalanceForm() {
    return (
      <div class="form-group row">
        <label class="col-sm-2 col-form-label text-right font-weight-bold">ユーザーID</label>
        <div class="col-sm-5">
          <input type="text"
                 class="form-control"
                 placeholder="user id"
                 value={this.state.userId}
                 onChange={EventListener._onUserIdChange.bind(this)}/>
        </div>
        <div class="col-sm-2">
          <button type="button" class="btn btn-primary btn-sm"
                  onClick={EventListener._onSearchButtonClick.bind(this)}>
            検索
          </button>
        </div>
      </div>
    );
  }

  render() {
    console.log('BalancePage', this);
    const { balance, isBalanceLoading, balanceUserId } = this.state;

    if (isBalanceLoading) {
      return ( <MiniLoadingIcon/> );
    }
    return (
      <div class="p-balance container-fluid">
        <div class="p-balance__header">
          <div class="page-header">
            <h1><small class="text-muted">ユーザーのポイント残高確認</small></h1>
          </div>
        </div>
        <div class="p-balance__body row">
          <div class="p-balance__main container-fluid">
            { this._searchBalanceForm() }
            { this._balanceView(balanceUserId, balance) }
          </div>
        </div>
      </div>
    );
  }
}

BalancePage.propTypes = {
  backendApiService: BackendApiService,
  errorHandleService: ErrorHandleService
};

BalancePage.defaultProps = {
  backendApiService: BackendApiService,
  errorHandleService: ErrorHandleService
};
