import './style.scss';
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom';

import MiniLoadingIcon from 'components/LoadingIcon/MiniLoadingIcon';

import BackendApiService from 'service/api/BackendApiService';
import ErrorHandleService from 'error/ErrorHandleService';
import { ManageApiError } from 'error/ManageError';

export default class UserListPage extends React.Component {

  componentDidMount() {
    const { isUsersLoading } = this.props.mainComponent.state;
    if (isUsersLoading) {
      Promise.all([
        this.props.backendApiService.getUsers()
      ]).then(([ usersResponse ]) => {
        console.trace('get users api succeeded', usersResponse);
        this.props.mainComponent.setState({
          users: usersResponse.data.users,
          isUsersLoading: false
        });
      }).catch((error) => {
        console.error('get users api failed.', error);
        this.props.errorHandleService.handleError(UserListPage._errorHandler.bind(this), error);
      });
    }
  }

  static _errorHandler(error) {
    if (!(error instanceof ManageApiError)) { throw error; }
    const [ status, body, errorCode, errorSubCode, errorMessage ] = [ error.getStatusCode(), error.getResponseBody(), error.getErrorCode(), error.getErrorSubCode(), error.getErrorMessage() ];
    console.debug('error handler.', status, body, errorCode, errorSubCode, errorMessage);
    switch (status) {
      default:
        this.props.mainComponent.setState({ showAlert: true, alertContent: 'エラーが発生しました。' });
        break;
    }
  }

  _userItem(user) {
    return (
      <tr key={user.id}>
        <td> {user.id} </td>
        <td> {user.username} </td>
        <td> {user.email} </td>
        <td> {user.createdAt} </td>
        <td> {user.updatedAt} </td>
        <td>
          <button type="button" class="btn btn-light btn-sm">
            <Link to={ '/apps/manage/users/' + user.id }>
              <i class="fa fa-pencil-square-o" aria-hidden="true"></i> 編集
            </Link>
          </button>
        </td>
      </tr>
    );
  }

  _userList(users) {
    // 論理削除されたユーザーを除外
    const activeUsers = users.filter((u) => { return u.deletedAt == null; });
    return (
      <table class="table table-bordered">
        <thead>
          <tr>
            <th> ID </th>
            <th> ユーザー名 </th>
            <th> メールアドレス </th>
            <th> 作成日時 </th>
            <th> 更新日時 </th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {activeUsers.map((user) => {
             return this._userItem(user);
           })}
        </tbody>
      </table>
    );
  }

  render() {
    console.log('UserListPage', this);
    const { users, isUsersLoading } = this.props.mainComponent.state;
    if (isUsersLoading) {
      return ( <MiniLoadingIcon/> );
    } 
    return (
      <div class="p-user-list container-fluid">
        <div class="page-header">
          <h1><small class="text-muted">ユーザー一覧</small></h1>
        </div>
        <div class="p-user-list__body row">
          <div class="p-user-list__main container-fluid">
            { this._userList(users) }
          </div>
        </div>
      </div>
    );
  }
}

UserListPage.propTypes = {
  backendApiService: BackendApiService,
  errorHandleService: ErrorHandleService
};

UserListPage.defaultProps = {
  backendApiService: BackendApiService,
  errorHandleService: ErrorHandleService
};
