/**
 * グループのメンバー一覧を管理するコンポーネント
 */
// TODO: グループの作成と共通化する
import React from 'react';

class EventListener {

  static _contains(list, v) {
    return list.find((a) => { return a == v; }) != null;
  }

  static _getUsersFromUserIds(allUsers, userIds) {
    return allUsers.filter((u) => { return EventListener._contains(userIds, u.id); });
  }

  static _onAddMemberButtonClick(e) {
    console.log('onAddMemberButtonClick', e, this);
    if (this.refs.usersSelectBox.selectedOptions == null) { return ; }
    const addMemberTargetUserIds = [ ... this.refs.usersSelectBox.selectedOptions ].map((option) => { return option.value; });
    const joinUserIds = this.state.joinUsers.map((u) => { return u.id; });
    const newJoinUsers = EventListener._getUsersFromUserIds(this.state.allUsers, joinUserIds.concat(addMemberTargetUserIds));
    this.setState({ joinUsers: newJoinUsers });
  }

  static _onDeleteMemberButtonClick(e) {
    console.log('onDeleteMemberButtonClick', e);
    const deleteMemberTargetUserIds = [ ... this.refs.joinGroupUsersSelectBox.selectedOptions ].map((option) => { return option.value; });
    const joinUserIds = this.state.joinUsers.map((u) => { return u.id; });
    const newJoinUserIds = joinUserIds.filter((uid) => { return !EventListener._contains(deleteMemberTargetUserIds, uid); });
    const newJoinUsers = EventListener._getUsersFromUserIds(this.state.allUsers, newJoinUserIds);
    this.setState({ joinUsers: newJoinUsers });
  }

  static _onSearchUserTextChange(e) {
    const searchUserText = e.target.value;
    const matchedUsers = this.state.allUsers.filter((u) => { return u.username.indexOf(searchUserText) != -1; });
    this.setState({ searchUserText, matchedUsers });
  }
}

export default class GroupMembers extends React.Component {
  constructor(props) {
    super(props);
    const members = EventListener._getUsersFromUserIds(this.props.mainComponent.state.users, this.props.memberIds);
    const activeUsers = this.props.mainComponent.state.users.filter((u) => { return u.deletedAt == null; });
    this.state = {
      searchUserText: '',
      allUsers: activeUsers,
      matchedUsers: activeUsers,
      joinUsers: members
    };
  }

  _joinMembers() {
    return (
      <select multiple class="form-control flex-grow-1 h-100 w-100" ref="joinGroupUsersSelectBox">
        {this.state.joinUsers.map((user) => {
            return (
              <option key={ `join-group-users_${user.id}` } value={user.id}>{ user.username }</option>
            );
          })}
      </select>
    );
  }

  _allUsers() {
    return (
      <div class="d-flex flex-column">
        <div class="p-group-members__search-user">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fa fa-search"/></span>
            </div>
            <input type="text" class="form-control flex-grow-1 w-100"
                    placeholder="Search user"
                    onChange={EventListener._onSearchUserTextChange.bind(this)} />
          </div>
        </div>
        <div class="p-group-members__user-list">
          <select multiple class="form-control flex-grow-1 h-100 w-100" ref="usersSelectBox">
            {this.state.matchedUsers.map((user) => {
                return (
                  <option key={ `all-users_${user.id}` } value={user.id}>{ user.username }</option>
                );
              })}
          </select>
        </div>
      </div>
    );
  }

  _addAndDeleteMemberButtons() {
    return (
      <div class="p-group-members__member-operation-main form-group row">
        <div class="col-sm-12 p-group-members__member-operation-sub">
          <button type="button"
                  class="p-group-members__add-member btn btn-primary"
                  onClick={EventListener._onAddMemberButtonClick.bind(this)}>
            <i class="fa fa-arrow-circle-left"/> 追加
          </button>
          <button type="button"
                  class="p-group-members__delete-member btn btn-primary"
                  onClick={EventListener._onDeleteMemberButtonClick.bind(this)}>
            削除 <i class="fa fa-arrow-circle-right"/>
          </button>
        </div>
      </div>
    );
  }

  getMembers() {
    return this.state.joinUsers;
  }

  render() {
    return (
      <div class="p-group-members col-sm-7">
        <div class="row p-group-members__main">
          <div class="col-sm-5 p-group-members__join-members">
            {this._joinMembers()}
          </div>
          <div class="col-sm-2 p-group-members__member-operation">
            {this._addAndDeleteMemberButtons()}
          </div>
          <div class="col-sm-5 p-group-members__all-users">
            {this._allUsers()}
          </div>
        </div>
      </div>
    );
  }
};
