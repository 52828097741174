/**
 * ユーザーが認証済みかどうかを管理するサービス
 */
import SessionService from 'service/SessionService';

export default class UserAuthenticationService {
  /**
   * 現在のユーザーが認証済みかどうかを確認する
   * セッションローカルストレージにセッションが保存されている場合に認証済みとみなしています。
   */
  static isAuthenticated() {
    return this.sessionService.load() != null;
  }
}

UserAuthenticationService.sessionService = SessionService;
