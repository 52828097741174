/**
 * ローディング画面
 */
import './style.scss';
import React from 'react';

export default class LoadingIcon extends React.Component {
  render() {
    return (
      <div class="p-loading-icon">
        <div class="p-loading-icon__image">
          <img src="/image/loading.gif" alt=""/>
        </div>
      </div>
    );
  }
};
