/**
 * 無料ポイント付与に利用するダイアログ
 */
import React from 'react';

/**
 * 無料ポイント付与の確認用ダイアログ
 */
export class AddFreePointsConfirmationDialog extends React.Component {
  render() {
    const {eventId, targets} = this.props;
    console.log('AddFreePointsConfirmationDialog', this, targets);

    return (
      <div class="modal fade" id="add_free_points_dialog" role="dialog">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">無料ポイント付与対象確認 (合計 {targets.length} 件)</h4>
            </div>
            <div class="modal-body">
              <p class="offset-sm-1 form-text text-muted"> 以下の内容で無料ポイントを付与します。本当によろしいですか？ </p>
              <p class="offset-sm-1 form-text text-muted"> イベントID: {eventId} </p>
              <table class="table table-bordered">
                <thead>
                <tr>
                  <th> ユーザーID</th>
                  <th> ポイント数</th>
                  <th> 有効期限</th>
                </tr>
                </thead>
                <tbody>
                {targets.map((target, idx) => {
                  return (
                    <tr key={idx}>
                      <td> {target.userId} </td>
                      <td> {target.points} pt</td>
                      <td> {target.expireAt} </td>
                    </tr>
                  );
                })}
                </tbody>
              </table>
            </div>
            <div class="modal-footer">
              <button class="btn btn-light"
                      data-dismiss="modal"
                      type="button"
                      onClick={this.props.onCancelButtonClicked}>閉じる
              </button>
              <button class="btn btn-light"
                      data-dismiss="modal"
                      type="button"
                      onClick={this.props.onOkButtonClicked}>実行
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/**
 * ポイント付与後の結果確認用ダイアログ
 */
export class AddFreePointsResultDialog extends React.Component {
  render() {
    const {addFreePointsResult} = this.props;
    console.log('AddFreePointsResultDialog', this, addFreePointsResult);

    return (
      <div class="modal fade" id="add_free_points_dialog" role="dialog">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">無料ポイント付与結果確認</h4>
            </div>
            <div class="modal-body">
              <table class="table table-bordered">
                <thead>
                <tr>
                  <th> 取引きID</th>
                  <th> 付与結果</th>
                  <th> エラー理由</th>
                </tr>
                </thead>
                <tbody>
                {addFreePointsResult.results.map((result, idx) => {
                  return (
                    <tr key={idx}>
                      <td> {result.data.dealId} </td>
                      <td> {(result.meta.status == 200) ? "成功" : "失敗"} </td>
                      <td> {result.meta.errorMessage} </td>
                    </tr>
                  );
                })}
                </tbody>
              </table>
            </div>
            <div class="modal-footer">
              <button class="btn btn-light"
                      data-dismiss="modal"
                      type="button"
                      onClick={this.props.onOkButtonClicked}>OK
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
