import './style.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom';

import MiniLoadingIcon from 'components/LoadingIcon/MiniLoadingIcon';

import BackendApiService from 'service/api/BackendApiService';
import ErrorHandleService from 'error/ErrorHandleService';
import {ManageApiError} from 'error/ManageError';
import {AddFreePointsResultDialog, AddFreePointsConfirmationDialog} from './AddFreePointsDialog';

  // biome-ignore lint/complexity/noStaticOnlyClass: TODO
class EventListener {
  static _onEventIdChange(e) {
    this.setState({eventId: e.target.value});
  }

  static _onUserIdChange(e) {
    this.setState({currentUserId: e.target.value});
  }

  static _onPointsChange(e) {
    this.setState({currentPoints: e.target.value});
  }

  static _onExpiredAtChange(e) {
    this.setState({currentExpiredAt: e.target.value});
  }

  static _onAddTargetButtonClick(e) {
    const {targets, currentUserId, currentPoints, currentExpiredAt} = this.state;
    const newTargets = [].concat(targets, [{
      userId: currentUserId,
      points: currentPoints,
      expiredAt: currentExpiredAt
    }]);
    this.setState({targets: newTargets, currentUserId: '', currentPoints: '', currentExpiredAt: ''})
  }

  static _onCsvFileInputChanged(e) {
    const {targets} = this.state;
    const files = e.target.files;
    if (files.length != 0) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const lines = fileReader.result
          .split('\n') // 各取引きは改行区切り
          .filter((line) => {
            return (line != '');
          }) // 空行は除外
          .map(line => line.split(',').map(v => v.replace(/"/g, ""))); // カンマで区切り、ダブルクオートを除去する
        const csvTargets = [];
        lines.forEach((line) => {
          const csvTarget = {};
          csvTarget.userId = line[0];
          csvTarget.points = line[1];
          csvTarget.expiredAt = line[2];
          csvTargets.push(csvTarget);
        });
        this.setState({targets: targets.concat(csvTargets)});
      };
      fileReader.readAsText(files[0]);
    } else {
      this.setState({targets});
    }
  }

  static _onConfirmButtonClick(e) {
    const assortId = this.props.assortId;
    const {eventId, targets} = this.state;
    this.setState({isAddPointsLoading: true});
    const newTargets = targets.map(target => { // expiredAtが設定されてない場合、キーを削除
      if (target.expiredAt != '') return target;
      else return {userId: target.userId, points: target.points}
    });
    Promise.all([
      this.props.backendApiService.addFreePoints(assortId, eventId, newTargets)
    ]).then(([ response ]) => {
      console.trace('add free points api succeeded', response);
      this.setState({isAddPointsLoading: false, addFreePointsResult: response.data});
      const resultDialog = ReactDOM.findDOMNode(this.refs.addFreePointsResultDialog);
      $(resultDialog).modal({backdrop: false});
    }).catch((error) => {
      console.error('add free points api failed.', error);
      this.setState({isAddPointsLoading: false});
      this.props.errorHandleService.handleError(EventListener._errorHandler.bind(this), error);
    });
  }

  static _onRemoveTargetButtonClick(e) {
    const {targets} = this.state;
    const idx = Number(e.target.getAttribute('name'));
    targets.splice(idx, 1);
    this.setState({targets: targets})
  }

  static _onResetButtonClick(e) {
    this.setState({
      eventId: '',
      currentUserId: '',
      currentPoints: '',
      currentExpiredAt: '',
      targets: [],
      addFreePointsResult: null
    });
  }

  static _errorHandler(error) {
    if (!(error instanceof ManageApiError)) {
      throw error;
    }
    const [ status, body, errorCode, errorSubCode, errorMessage ] = [error.getStatusCode(), error.getResponseBody(), error.getErrorCode(), error.getErrorSubCode(), error.getErrorMessage()];
    console.debug('error handler.', status, body, errorCode, errorSubCode, errorMessage);
    switch (status) {
      default:
        this.props.mainComponent.setState({showAlert: true, alertContent: 'エラーが発生しました。'});
        break;
    }
  }
}

export default class AddFreePointsPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      eventId: '',
      currentUserId: '',
      currentPoints: '',
      currentExpiredAt: '',
      targets: [],
      addFreePointsResult: null,
      isAddPointsLoading: false
    };
  }

  _eventIdForm() {
    return (
      <form>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label text-right font-weight-bold">イベントID</label>
          <div class="col-sm-8">
            <input type="text"
                   class="form-control"
                   placeholder=""
                   value={this.state.eventId}
                   onChange={EventListener._onEventIdChange.bind(this)}
                   required/> 必須
          </div>
        </div>
      </form>
    );
  }

  _targetForm() {
    return (
      <form>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label text-right font-weight-bold">ユーザーID</label>
          <div class="col-sm-8">
            <input type="text"
                   class="form-control"
                   placeholder=""
                   value={this.state.currentUserId}
                   onChange={EventListener._onUserIdChange.bind(this)}/>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label text-right font-weight-bold">付与ポイント数</label>
          <div class="col-sm-8">
            <input type="text"
                   class="form-control"
                   placeholder=""
                   value={this.state.currentPoints}
                   onChange={EventListener._onPointsChange.bind(this)}/>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label text-right font-weight-bold">ポイント有効期限</label>
          <div class="col-sm-8">
            <input type="datetime-local"
                   class="form-control"
                   placeholder=""
                   value={this.state.currentExpiredAt}
                   onChange={EventListener._onExpiredAtChange.bind(this)}/>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label text-right font-weight-bold"> </label>
          <button type="button" class="btn btn-primary btn-sm"
                  onClick={EventListener._onAddTargetButtonClick.bind(this)}>
            追加
          </button>
        </div>
      </form>
    );
  }

  _csvTargetsForm() {
    return (
      <div class="p-add-points__csv-targets-form">
        <p class="offset-sm-2 form-text text-muted">無料ポイント付与対象を含んだCSVファイルを指定してください。</p>
        <p class="offset-sm-2 form-text text-muted">フォーマット: "ユーザID","ポイント数","有効期限"</p>
        <div class="form-group row">
          <label class="offset-sm-1 col-sm-2 font-weight-bold">CSVファイル</label>
          <input type="file" class="col-sm-5" id="csvFile"
                 onChange={EventListener._onCsvFileInputChanged.bind(this)}/>
        </div>
      </div>
    );
  }

  _currentTargetsView() {
    const {targets} = this.state;
    return (
      <div>
        <table class="table table-bordered">
          <thead>
          <tr>
            <th> ユーザーID</th>
            <th> ポイント数</th>
            <th> 有効期限</th>
            <th> 削除</th>
          </tr>
          </thead>
          <tbody>
          {targets.map((target, idx) => {
            return (
              <tr key={idx}>
                <td> {target.userId} </td>
                <td> {target.points} pt</td>
                <td> {target.expiredAt} </td>
                <td>
                  <button type="button" class="btn btn-light btn-sm"
                          name={idx}
                          onClick={EventListener._onRemoveTargetButtonClick.bind(this)}>
                    削除
                  </button>
                </td>
              </tr>
            );
          })}
          </tbody>
        </table>
      </div>
    );
  }

  _addPointsButton() {
    return (
      <div class="form-group row">
        <label class="col-sm-2 col-form-label text-right font-weight-bold"> </label>
        <button type="button" class="btn btn-primary btn-sm"
                data-target="#add_free_points_dialog" data-toggle="modal" data-backdrop="false">
          ポイント付与
        </button>
      </div>
    );
  }

  _dialogs() {
    const {eventId, targets, addFreePointsResult} = this.state;
    // ポイント付与前の確認用ダイアログ
    const addFreePointsConfirmationDialog = (targets.length != 0) ? (
      <AddFreePointsConfirmationDialog
        eventId={eventId}
        targets={targets}
        onOkButtonClicked={EventListener._onConfirmButtonClick.bind(this)}/> ) : null;
    // ポイント付与後の結果確認用ダイアログ
    const addFreePointsResultDialog = (addFreePointsResult != null) ? (
      <AddFreePointsResultDialog
        ref="addFreePointsResultDialog"
        addFreePointsResult={addFreePointsResult}
        onOkButtonClicked={EventListener._onResetButtonClick.bind(this)}/> ) : null;
    return (
      <div>
        {addFreePointsConfirmationDialog}
        {addFreePointsResultDialog}
      </div>
    );
  }

  render() {
    console.log('AddFreePointsPage', this);
    const {isAddPointsLoading} = this.state;

    if (isAddPointsLoading) {
      return ( <MiniLoadingIcon/> );
    } 
    return (
      <div class="p-add-points container-fluid">
        <div class="p-add-points__header">
          <div class="page-header">
            <h1>
              <small class="text-muted">無料ポイント付与</small>
            </h1>
          </div>
        </div>
        <div class="p-add-points__body row">
          <p class="offset-sm-2 form-text text-muted">イベントIDを指定し、「個別追加」のフォームで一つづつ追加、もしくは「一括追加」のフォームでCSVによる追加を行ってください。</p>
          <div class="p-add-points__main container-fluid">
            { this._eventIdForm() }
            <h3>
              <small class="text-muted">個別追加</small>
            </h3>
            { this._targetForm() }
            <h3>
              <small class="text-muted">一括追加</small>
            </h3>
            { this._csvTargetsForm() }
            { this._currentTargetsView() }
            { this._addPointsButton() }
            { this._dialogs() }
          </div>
        </div>
      </div>
    );
  }
}

AddFreePointsPage.propTypes = {
  backendApiService: BackendApiService,
  errorHandleService: ErrorHandleService
};

AddFreePointsPage.defaultProps = {
  backendApiService: BackendApiService,
  errorHandleService: ErrorHandleService
};
