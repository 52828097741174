import UserAuthenticationService from 'service/UserAuthenticationService';

import React from 'react';
import {
  Route,
  Redirect
} from 'react-router-dom';

/**
 * 認証が必要なページへのルートクラス
 */
export default class AuthenticationRequiredRoute extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const rests = Object.assign({}, this.props);
    delete rests.component;
    return (
      <Route {...rests} render={props => (
          UserAuthenticationService.isAuthenticated() ? (
            <this.props.component {...props} {...rests}/>
          ) : (
            <Redirect to={{ pathname: '/login', state: { from: props.location } }}/>
          )
        )}/>
    );
  }
}
