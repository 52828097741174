import './style.scss';
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom';

import SiteHeader from 'components/SiteHeader/SiteHeader';
import PageSideBar from 'components/PageSideBar/PageSideBar';
import LoadingIcon from 'components/LoadingIcon/LoadingIcon';

import AccountPageHeader from 'components/PageHeader/AccountPageHeader';
import ProfilePage from 'components/ProfilePage/ProfilePage';
import UpdateAccountPasswordPage from 'components/UpdateAccountPasswordPage/UpdateAccountPasswordPage';

import BackendApiService from 'service/api/BackendApiService';
import ErrorHandleService from 'error/ErrorHandleService';
import { ManageApiError } from 'error/ManageError';

const APP_TYPE = 'account';

/**
 * アカウント管理アプリケーション
 */
export default class AccountApp extends React.Component {

  componentDidMount() {
    const mainComponent = this.props.mainComponent;
    const { isAccountInfoLoading, isAccountRolesLoading, isAssortsLoading } = mainComponent.state;
    if (isAccountInfoLoading && isAccountRolesLoading && isAssortsLoading) {
      Promise.all([
        BackendApiService.getUserProfile(),
        BackendApiService.getSessionUserRoles(),
        BackendApiService.getAssorts()
      ]).then(([ userResponse, userRolesResponse, assortsResponse ]) => {
        console.trace('all api succeeded.', userResponse, userRolesResponse, assortsResponse);
        mainComponent.setState({ isAccountInfoLoading: false, account: userResponse.data, isAccountRolesLoading: false, accountRoles: userRolesResponse.data, isAssortsLoading: false, assorts: assortsResponse.data });
      }).catch((error) => {
        console.error('get account profile api failed.', error);
        ErrorHandleService.handleError(AccountApp._errorHandler.bind(this), error);
      });
    }
  }

  static _errorHandler(error) {
    if (!(error instanceof ManageApiError)) { throw error; }
    const [ status, body, errorCode, errorSubCode, errorMessage ] = [ error.getStatusCode(), error.getResponseBody(), error.getErrorCode(), error.getErrorSubCode(), error.getErrorMessage() ];
    console.debug('error handler.', status, body, errorCode, errorSubCode, errorMessage);
    switch (status) {
      case 400:
        throw error;
        break;
      default:
        throw error;
        break;
    }
  }

  _viewTemplate(siteHeader, pageHeader, pageSideBar, pageBody) {
    return (
      <div class="p-account-app container-fluid d-flex flex-column">
        <div class="p-account-app_site-header">
          {siteHeader}
        </div>
        <div class="p-account-app_main container-fluid">
          <div class="p-account-app_page-header">
            {pageHeader}
          </div>
          <div class="p-account-app_page-main row">
            <div class="col-sm-2 p-account-app_side-bar">
              {pageSideBar}
            </div>
            <div class="col-sm-10 p-account-app_page-body">
              {pageBody}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    console.trace('AccountApp', this);
    const { isAccountInfoLoading, isAccountRolesLoading } = this.props.mainComponent.state;

    if (isAccountInfoLoading || isAccountRolesLoading) {
      return ( <LoadingIcon/> );
    }
    const { mainComponent } = this.props;
    const { account } = mainComponent.state;

    return (
      <Switch>
        <Route path="/apps/:appId/profile" exact render={props => {
            // プロフィールページ
            const pageType = 'PROFILE';
            const siteHeader = (<SiteHeader account={account}/>);
            const pageHeader = (<AccountPageHeader {...props} pageType={pageType} mainComponent={mainComponent}/>);
            const pageSideBar = (<PageSideBar {...props} mainComponent={mainComponent}/>);
            const pageBody = (<ProfilePage {...this.props} {...props}/>);
            return this._viewTemplate(siteHeader, pageHeader, pageSideBar, pageBody);
          }}/>
        <Route path="/apps/:appId/update-password" exact render={props => {
            // パスワード更新ページ
            const pageType = 'UPDATE_PASSWORD';
            const siteHeader = (<SiteHeader account={account}/>);
            const pageHeader = (<AccountPageHeader {...props} pageType={pageType} mainComponent={mainComponent}/>);
            const pageSideBar = (<PageSideBar {...props} mainComponent={mainComponent}/>);
            const pageBody = (<UpdateAccountPasswordPage {...this.props} {...props}/>);
            return this._viewTemplate(siteHeader, pageHeader, pageSideBar, pageBody);
          }}/>
        <Redirect from="/apps/account" to="/apps/account/profile"/>
      </Switch>
    );
  }
}

AccountApp.APP_TYPE = APP_TYPE;
