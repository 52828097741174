import './style.scss';
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom';

import MiniLoadingIcon from 'components/LoadingIcon/MiniLoadingIcon';

import BackendApiService from 'service/api/BackendApiService';
import ErrorHandleService from 'error/ErrorHandleService';
import { ManageApiError } from 'error/ManageError';

export default class ProductListPage extends React.Component {

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentDidUpdate() {
    const assortProduct = this.props.mainComponent.state.assortProducts.find((assortProduct) => { return assortProduct.assortId == this.props.assortId; })
    const { products, isProductsLoading } = (assortProduct != null) ? assortProduct : { products: [], isProductsLoading: true };
    if (isProductsLoading) {
      Promise.all([
        this.props.backendApiService.getProducts(this.props.assortId)
      ]).then(([ productsResponse ]) => {
        console.trace('get products api succeeded', this.props.assortId, productsResponse);
        const assortProducts = [].concat(this.props.mainComponent.state.assortProducts, [{
          products: productsResponse.data.products,
          assortId: this.props.assortId,
          isProductsLoading: false
        }]);
        this.props.mainComponent.setState({ assortProducts });
      }).catch((error) => {
        console.error('get products api failed.', error);
        this.props.errorHandleService.handleError(ProductListPage._errorHandler.bind(this), error);
      });
    }
  }

  static _errorHandler(error) {
    if (!(error instanceof ManageApiError)) { throw error; }
    const [ status, body, errorCode, errorSubCode, errorMessage ] = [ error.getStatusCode(), error.getResponseBody(), error.getErrorCode(), error.getErrorSubCode(), error.getErrorMessage() ];
    console.debug('error handler.', status, body, errorCode, errorSubCode, errorMessage);
    switch (status) {
      default:
        this.props.mainComponent.setState({ showAlert: true, alertContent: 'エラーが発生しました。' });
        break;
    }
  }

  _productItem(assortId, product) {
    return (
      <tr key={product.id}>
        <td> {product.id} </td>
        <td> {product.name} </td>
        <td> {product.createdAt} </td>
        <td> {product.updatedAt} </td>
        <td>
          <button type="button" class="btn btn-light btn-sm">
            <Link to={ `/apps/mmt/assorts/${assortId}/products/${product.id}` }>
              <i class="fa fa-pencil-square-o" aria-hidden="true"></i> 編集
            </Link>
          </button>
        </td>
      </tr>
    );
  }

  _productList(assortId, products) {
    return (
      <table class="table table-bordered">
        <thead>
          <tr>
            <th> ID </th>
            <th> 商品名 </th>
            <th> 作成日時 </th>
            <th> 更新日時 </th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => {
             return this._productItem(assortId, product);
           })}
        </tbody>
      </table>
    );
  }

  render() {
    console.log('ProductListPage', this);
    const { isAssortProductsLoading } = this.props.mainComponent.state;
    const assortProduct = this.props.mainComponent.state.assortProducts.find((assortProduct) => { return assortProduct.assortId == this.props.assortId; })
    const { products, isProductsLoading } = (assortProduct != null) ? assortProduct : { products: [], isProductsLoading: true };
    if (isProductsLoading || isAssortProductsLoading) {
      return ( <MiniLoadingIcon/> );
    }
    return (
      <div class="p-product-list container-fluid">
        <div class="p-product-list__header">
          <div class="page-header">
            <h1><small class="text-muted">商品一覧</small></h1>
          </div>
        </div>
        <div class="p-product-list__body row">
          <div class="p-product-list__main container-fluid">
            { this._productList(this.props.assortId, products) }
          </div>
        </div>
      </div>
    );
  }
}

ProductListPage.propTypes = {
  backendApiService: BackendApiService,
  errorHandleService: ErrorHandleService
};

ProductListPage.defaultProps = {
  backendApiService: BackendApiService,
  errorHandleService: ErrorHandleService
};
