import './style.scss';
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom';

import BackendApiService from 'service/api/BackendApiService';
import ErrorHandleService from 'error/ErrorHandleService';
import { ManageApiError } from 'error/ManageError';

class EventListener extends React.Component {
  static _onUpdateButtonClick(e) {
    /*
    const { providerId, providerName } = this.state;
    const providerIdInt = parseInt(providerId, 10);
    Promise.all([
      BackendApiService.updateProvider(providerIdInt, providerName)
    ]).then(([ response ]) => {
      console.trace('edit provider api succeeded', response);
      setTimeout(() => { location.href = '/apps/mmt/providers'; }, 500);
    }).catch((error) => {
      console.error('update provider api failed.', error);
      ErrorHandleService.handleError(EventListener._errorHandler.bind(this), error);
    });
    */
  }

  static _errorHandler(error) {
    if (!(error instanceof ManageApiError)) { throw error; }
    const [ status, body, errorCode, errorSubCode, errorMessage ] = [ error.getStatusCode(), error.getResponseBody(), error.getErrorCode(), error.getErrorSubCode(), error.getErrorMessage() ];
    console.debug('error handler.', status, body, errorCode, errorSubCode, errorMessage);
    switch (status) {
      default:
        this.props.mainComponent.setState({ showAlert: true, alertContent: 'エラーが発生しました。' });
        break;
    }
  }

  static _onProviderNameChange(e) {
    this.setState({ providerName: e.target.value });
  }
};

export default class EditProviderPage extends React.Component {

  constructor(props) {
    super(props);
    const provider = this.props.mainComponent.state.providers.find((p) => { return p.id == this.props.providerId; });
    if (provider != null) {
      this.state = {
        isEditProviderCompleted: false,
        providerName: provider.name
      };
    } else {
      this.state = {
        isEditProviderCompleted: false
      };
    }
  }

  render() {
    console.log('EditProviderPage', this);
    const { isEditProviderCompleted, providerName } = this.state;
    const { isProvidersLoading, providers } = this.props.mainComponent.state;

    if (isEditProviderCompleted) {
      return ( <Redirect to={ '/apps/mmt/providers' }/> );
    }

    if (isProvidersLoading) {
      return ( <Redirect to={ '/apps/mmt/providers' }/> );
    }

    const provider = providers.find((p) => { return p.id == this.props.providerId; });
    if (provider == null) {
      return ( <div>存在しないプロバイダです。</div> );
    }

    return (
      <div class="p-edit-provider-page container-fluid">
        <div class="p-edit-provider-page__header">
          <div class="page-header">
            <h1><small class="text-muted">プロバイダ編集ページ</small></h1>
          </div>
        </div>
        <div class="p-edit-provider-page__body row">
          <div class="p-edit-provider-page__main container-fluid">
            <form>
              <div class="form-group row">
                <label for="inputProviderId" class="col-sm-2 col-form-label text-right font-weight-bold">プロバイダID</label>
                <div class="col-sm-7">
                  {provider.id}
                </div>
              </div>
              <div class="form-group row">
                <label for="inputProviderName" class="col-sm-2 col-form-label text-right font-weight-bold">プロバイダ名</label>
                <div class="col-sm-7">
                  <input type="text"
                         class="form-control"
                         id="inputProviderName"
                         placeholder="例: テストプロバイダ"
                         value={this.state.providerName}
                         onChange={EventListener._onProviderNameChange.bind(this)} />
                </div>
              </div>
              <h4>SBPS設定</h4>
              <div class="form-group row">
                <label for="inputSbpsOkCallbackUrl" class="col-sm-2 col-form-label text-right font-weight-bold">OKコールバックURL</label>
                <div class="col-sm-7">
                  <input type="text" class="form-control" id="inputSbpsOkCallbackUrl" placeholder="例: https://www.testprovider.nico/payments/ok"/>
                </div>
              </div>
              <div class="form-group row">
                <label for="inputSbpsNgCallbackUrl" class="col-sm-2 col-form-label text-right font-weight-bold">NGコールバックURL</label>
                <div class="col-sm-7">
                  <input type="text" class="form-control" id="inputSbpsNgCallbackUrl" placeholder="例: https://www.testprovider.nico/payments/ng"/>
                </div>
              </div>
              <div class="form-group row">
                <label for="inputSbpsCancelCallbackUrl" class="col-sm-2 col-form-label text-right font-weight-bold">キャンセルコールバックURL</label>
                <div class="col-sm-7">
                  <input type="text" class="form-control" id="inputSbpsCancelCallbackUrl" placeholder="例: https://www.testprovider.nico/payments/cancel"/>
                </div>
              </div>
              <div class="form-group row">
                <label for="inputSbpsSalesCompletionApiUrl" class="col-sm-2 col-form-label text-right font-weight-bold">購入完了通知API URL</label>
                <div class="col-sm-7">
                  <input type="text" class="form-control" id="inputSbpsSalesCompletionApiUrl" placeholder="例: https://www.testprovider.nico/api/v1/complete_purchase"/>
                </div>
              </div>
              <div class="form-group row">
                <label for="inputSbpsTransactionHashSalt" class="col-sm-2 col-form-label text-right font-weight-bold">ソルト値</label>
                <div class="col-sm-7">
                  <input type="text" class="form-control" id="inputSbpsTransactionHashSalt" placeholder="例: d9b3zfb8adEe20D5c96d9Q51def2"/>
                </div>
              </div>
              <div class="form-group row">
                <div class="offset-sm-1 col-sm-10">
                  <button type="button"
                          class="btn btn-primary btn-lg"
                          onClick={EventListener._onUpdateButtonClick.bind(this)}>
                    更新(TODO)
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
