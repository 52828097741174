import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom';

import BackendApiService from 'service/api/BackendApiService';
import ErrorHandleService from 'error/ErrorHandleService';
import { ManageApiError } from 'error/ManageError';

// biome-ignore lint/complexity/noStaticOnlyClass: TODO
class EventListener extends React.Component {
  static _onRegisterButtonClick(e) {
    const { roleName, permissions } = this.state;
    Promise.all([
      BackendApiService.createRole(roleName, permissions)
    ]).then(([ response ]) => {
      console.trace('create role api succeeded', response);
      setTimeout(() => { location.href = '/apps/manage/roles'; }, 500);
    }).catch((error) => {
      console.error('create role api failed.', error);
      ErrorHandleService.handleError(EventListener._errorHandler.bind(this), error);
    });
  }

  static _errorHandler(error) {
    if (!(error instanceof ManageApiError)) { throw error; }
    const [ status, body, errorCode, errorSubCode, errorMessage ] = [ error.getStatusCode(), error.getResponseBody(), error.getErrorCode(), error.getErrorSubCode(), error.getErrorMessage() ];
    console.debug('error handler.', status, body, errorCode, errorSubCode, errorMessage);
    switch (status) {
      default:
        this.props.mainComponent.setState({ showAlert: true, alertContent: 'エラーが発生しました。' });
        break;
    }
  }

  static _onRoleNameChange(e) {
    this.setState({ roleName: e.target.value });
  }

  static _onPermissionCheckboxChanged(e) {
    const [ targetPermissionId, checked ] = [ e.target.value, e.target.checked ];
    if (checked) {
      const permissions = this.state.permissions.concat([ targetPermissionId ]);
      this.setState({ permissions });
    } else {
      const permissions = this.state.permissions.filter((permissionId) => { return permissionId != targetPermissionId; });
      this.setState({ permissions });
    }
  }
};

// TODO: バリデーション
export default class CreateRolePage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isCreateRoleCompleted: false,
      permissions: []
    };
  }

  render() {
    console.log('CreateRolePage', this);
    const { isCreateRoleCompleted } = this.state;

    if (isCreateRoleCompleted) {
      return ( <Redirect to={ '/apps/manage/roles' }/> );
    }

    return (
      <div class="p-create-role-page container-fluid">
        <div class="p-create-role-page__header">
          <div class="page-header">
            <h1><small class="text-muted">ロール登録ページ</small></h1>
          </div>
        </div>
        <div class="p-create-role-page__body row">
          <div class="p-create-role-page__main container-fluid">
            <form>

              <div class="form-group row">
                <label for="inputRoleName" class="col-sm-2 col-form-label text-right font-weight-bold">ロール名</label>
                <div class="col-sm-7">
                  <input type="text"
                         class="form-control"
                         id="inputRoleName"
                         placeholder="role name"
                         onChange={EventListener._onRoleNameChange.bind(this)} />
                </div>
              </div>

              <div class="form-group row">
                <label for="inputRoleName" class="col-sm-2 col-form-label text-right font-weight-bold">権限</label>
                <div class="d-flex flex-column justify-content-start align-items-start offset-sm-2 col-sm-10">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="MANAGE_USER" onChange={EventListener._onPermissionCheckboxChanged.bind(this)}/>
                    <label class="form-check-label font-weight-bold ml-1">ユーザーの管理権限</label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="MANAGE_GROUP" onChange={EventListener._onPermissionCheckboxChanged.bind(this)}/>
                    <label class="form-check-label font-weight-bold ml-1">グループの管理権限</label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="MANAGE_PRIVILEGE" onChange={EventListener._onPermissionCheckboxChanged.bind(this)}/>
                    <label class="form-check-label font-weight-bold ml-1">ロールの管理権限</label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="MANAGE_BALANCE" onChange={EventListener._onPermissionCheckboxChanged.bind(this)}/>
                    <label class="form-check-label font-weight-bold ml-1">ポイント残高の管理権限</label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="MANAGE_DEAL_HISTORY" onChange={EventListener._onPermissionCheckboxChanged.bind(this)}/>
                    <label class="form-check-label font-weight-bold ml-1">取引き履歴の管理権限</label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="MANAGE_ADD_PAID_POINTS" onChange={EventListener._onPermissionCheckboxChanged.bind(this)}/>
                    <label class="form-check-label font-weight-bold ml-1">有料ポイント付与の管理権限</label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="MANAGE_ADD_FREE_POINTS" onChange={EventListener._onPermissionCheckboxChanged.bind(this)}/> 
                    <label class="form-check-label font-weight-bold ml-1">無料ポイント付与の管理権限</label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="MANAGE_INVALIDATE_POINTS" onChange={EventListener._onPermissionCheckboxChanged.bind(this)}/>
                    <label class="form-check-label font-weight-bold ml-1">ポイント失効の管理権限</label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="MANAGE_INQUIRY" onChange={EventListener._onPermissionCheckboxChanged.bind(this)}/>
                    <label class="form-check-label font-weight-bold ml-1">問い合わせの管理権限</label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="MANAGE_ASSORT" onChange={EventListener._onPermissionCheckboxChanged.bind(this)}/> 
                    <label class="form-check-label font-weight-bold ml-1">ポイント種別の管理権限</label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="MANAGE_PROVIDER" onChange={EventListener._onPermissionCheckboxChanged.bind(this)}/>
                    <label class="form-check-label font-weight-bold ml-1">プロバイダの管理権限</label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="MANAGE_PRODUCT" onChange={EventListener._onPermissionCheckboxChanged.bind(this)}/>
                    <label class="form-check-label font-weight-bold ml-1">商品の管理権限</label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="MANAGE_EVENT" onChange={EventListener._onPermissionCheckboxChanged.bind(this)}/>
                    <label class="form-check-label font-weight-bold ml-1">イベントの管理権限</label>
                  </div>
                  <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="MANAGE_VOUCHER" onChange={EventListener._onPermissionCheckboxChanged.bind(this)}/>
                    <label class="form-check-label font-weight-bold ml-1">金券の管理権限</label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="MANAGE_FORCE_CONSUME_POINTS" onChange={EventListener._onPermissionCheckboxChanged.bind(this)}/>
                    <label class="form-check-label font-weight-bold ml-1">ポイント強制消費の管理権限</label>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <div class="offset-sm-1 col-sm-10">
                  <button type="button"
                          class="btn btn-primary btn-lg"
                          onClick={EventListener._onRegisterButtonClick.bind(this)}>
                    登録
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
