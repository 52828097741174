import './style.scss';
import React from 'react';

import createBrowserHistory from 'history/createBrowserHistory';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom';

import BackendApiService from 'service/api/BackendApiService';
import PageHeaderBase from './PageHeaderBase';

const PAGE_TYPES = {
  PROFILE : 'PROFILE'
};

export default class AccountPageHeader extends React.Component {
  _profileTypeView() {
    const history = createBrowserHistory();
    return (
      <div class="collapse navbar-collapse">
        <button type="button" class="p-page-header__button btn btn-light">
          <Link to="/apps/account/profile">
            <i class="fa fa-home fa-lg"/>
          </Link>
        </button>
        <button type="button" class="p-page-header__button btn btn-light" onClick={ (e) => { history.goBack(); } }>
          <i class="fa fa-arrow-left fa-lg"/>
        </button>
        <button type="button" class="p-page-header__button btn btn-light">
          <Link to="/apps/account/update-password">
            <i class="fa fa-pencil-square-o"/> パスワード変更
          </Link>
        </button>
      </div>
    );
  }

  _viewTemplate(view) {
    const title = 'アカウント管理';
    return (
      <PageHeaderBase title={title} dialogs={null}>
        {view}
      </PageHeaderBase>
    );
  }

  render() {
    console.log('AccountPageHeader', this);
    const { pageType } = this.props;

    switch (pageType){
      case PAGE_TYPES.PROFILE:
        return this._viewTemplate(this._profileTypeView());
        break;
      default:
        return this._viewTemplate(this._profileTypeView());
        break;
    }
  }
}

AccountPageHeader.PAGE_TYPES = PAGE_TYPES;
