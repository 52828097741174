import './global-style.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import BootService from 'service/BootService';
import Main from 'components/Main/Main';
import $ from 'jquery';
import 'bootstrap';

BootService.init();
ReactDOM.render(<Main/>, document.getElementById('app'));
