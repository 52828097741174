/**
 * APIサービス
 *
 * APIのアクセスを提供します。内部的にはfetchを利用しています。
 */
export default class ApiService {

  /**
   * fetchのデフォルト設定を取得する
   */
  static _getSetting(additionalSettings = {}, additionalHeaders = {}) {
    const headers = Object.assign({}, {
      Accept: 'application/json',
      'content-type': 'application/json'
    }, additionalHeaders);
    const setting = Object.assign({}, {
      mode: 'cors',
      method: 'GET',
      headers: new Headers(headers),
      credentials: 'include',
      cache: 'default'
    }, additionalSettings);
    return setting;
  }

  static get(url, headers = {}) {
    return fetch(url, ApiService._getSetting({}, headers));
  }

  static post(url, headers = {}, body = {}) {
    return fetch(url, ApiService._getSetting({
      method: 'POST',
      body: JSON.stringify(body)
    }, headers));
  }

  static delete(url, headers = {}) {
    return fetch(url, ApiService._getSetting({
      method: 'DELETE'
    }, headers));
  }

  static put(url, headers = {}, body = {}) {
    return fetch(url, ApiService._getSetting({
      method: 'PUT',
      body: JSON.stringify(body)
    }, headers));
  }
}
