import './style.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom';
import moment from 'moment';

import MiniLoadingIcon from 'components/LoadingIcon/MiniLoadingIcon';

import BackendApiService from 'service/api/BackendApiService';
import ErrorHandleService from 'error/ErrorHandleService';
import { ManageApiError } from 'error/ManageError';
import { CancelDealsResultDialog, CancelDealsConfirmationDialog } from './CancelDealsDialog';

// 1ページあたりの取引き履歴件数
const limit = 100;

class EventListener {
  static _errorHandler(error) {
    if (!(error instanceof ManageApiError)) { throw error; }
    const [ status, body, errorCode, errorSubCode, errorMessage ] = [ error.getStatusCode(), error.getResponseBody(), error.getErrorCode(), error.getErrorSubCode(), error.getErrorMessage() ];
    console.debug('error handler.', status, body, errorCode, errorSubCode, errorMessage);
    switch (status) {
      default:
        this.props.mainComponent.setState({ showAlert: true, alertContent: 'エラーが発生しました。' });
        break;
    }
  }

  static _onUserIdChange(e) {
    this.setState({ userId: e.target.value });
  }

  static _onDealIdChange(e) {
    this.setState({ dealId: e.target.value });
  }

  static _onTransactionIdChange(e) {
    this.setState({ transactionId: e.target.value });
  }

  static _onKaeruTransactionIdChange(e) {
    this.setState({ kaeruTransactionId: e.target.value });
  }

  static _onDealtAtFromChange(e) {
    this.setState({ dealtAtFrom: e.target.value });
  }

  static _onDealtAtUntilChange(e) {
    this.setState({ dealtAtUntil: e.target.value });
  }

  static _onSearchButtonClick(e) {
    this._searchDeals(this.state.page, this.state);
  }

  static _onDealTypeSelectBoxChange(e) {
    this.setState({ dealType: e.target.selectedOptions[0].value });
  }

  static _onPageButtonClick(e) {
    const page = parseInt(e.target.getAttribute('value'), 10);
    this._searchDeals(page, this.state);
  }

  static _updateAllDealHistoryCheckStatus(dealHistoryResponse, checked = false) {
    const dealHistoryList = dealHistoryResponse.dealHistory.map((deal) => { return Object.assign({}, deal, { checked }); });
    return Object.assign({}, dealHistoryResponse, { dealHistory: dealHistoryList });
  }

  static _updateDealHistoryCheckStatus(dealHistoryResponse, dealId, checked = false) {
    const dealHistoryList = dealHistoryResponse.dealHistory;
    const targetIndex = dealHistoryList.findIndex((deal) => { return deal.id == dealId; });
    dealHistoryList[targetIndex].checked = checked;
    return Object.assign({}, dealHistoryResponse, { dealHistory: dealHistoryList });
  }

  static _onCancelDealsButtonClick(e) {
    Promise.all([
      BackendApiService.cancelDeals(this.props.assortId, this.state.cancelTargetDealIds, this.state.cancelDescription)
    ]).then(([ response ]) => {
      console.trace('cancel deals api succeeded', response);
      this.setState({ cancelDealsResult: response.data });
      const resultDialog = ReactDOM.findDOMNode(this.refs.cancelDealsResultDialog);
      $(resultDialog).modal({ backdrop: false });
    }).catch((error) => {
      console.error('cancel deals api failed.', error);
      ErrorHandleService.handleError(EventListener._errorHandler.bind(this), error);
    });
    this.setState({ cancelDescription: '' });
  }

  static _onDealCheckboxChange(e) {
    const targetDealId = e.target.getAttribute('value');
    if (e.target.checked) {
      const cancelTargetDealIds = [].concat(this.state.cancelTargetDealIds, [ targetDealId ]);
      const dealHistory = EventListener._updateDealHistoryCheckStatus(this.state.dealHistory, targetDealId, true);
      this.setState({ cancelTargetDealIds, dealHistory });
    } else {
      const cancelTargetDealIds = this.state.cancelTargetDealIds.filter((d) => { return d != targetDealId; });
      const dealHistory = EventListener._updateDealHistoryCheckStatus(this.state.dealHistory, targetDealId, false);
      this.setState({ cancelTargetDealIds, dealHistory });
    }
  }

  static _onCheckAllDealCheckboxChange(e) {
    if (e.target.checked) {
      const dealHistoryResponse = EventListener._updateAllDealHistoryCheckStatus(this.state.dealHistory, true);
      const cancelTargetDealIds = this.state.dealHistory.dealHistory.map((deal) => { return deal.id; });
      this.setState({ cancelTargetDealIds, dealHistory: dealHistoryResponse });
    } else {
      const dealHistoryResponse = EventListener._updateAllDealHistoryCheckStatus(this.state.dealHistory, false);
      this.setState({ cancelTargetDealIds: [], dealHistory: dealHistoryResponse });
    }
  }

  static _onCsvFileInputChanged(e) {
    const files = e.target.files;
    if (files.length != 0) {
      const fr = new FileReader();
      fr.onload = () => {
        const csvDealIds =  fr.result
          .split('\n') // 各取引きは改行区切り
          .filter((line) => { return (line != ''); }); // 空行は除外
        this.setState({ csvFile: files[0], csvDealIds });
      };
      fr.readAsText(files[0]);
    } else {
      this.setState({ csvFile: [], csvDealIds: [] });
    }
  }

  static _onCancelDescriptionChanged(e) {
    this.setState({ cancelDescription: e.target.value });
  }

  static _onCancelButtonClicked(e) {
    this.setState({ cancelDescription: '' });
  }
}

export default class SearchDealPage extends React.Component {

  constructor(props) {
    super(props);
    const dealtAtFrom = moment().format('YYYY-MM-DDT00:00');
    const dealtAtUntil = moment().add(1, 'months').format('YYYY-MM-DDT00:00');
    this.state = {
      page: 1,
      dealType: 'all',
      dealId: '',
      userId: '',
      kaeruTransactionId: '',
      transactionId: '',
      dealtAtFrom,
      dealtAtUntil,
      isSearchDealHistoryLoading: false,
      isCancelDealsLoading: false,
      dealHistory: null,
      cancelTargetDealIds: [],
      cancelDescription: '',
      cancelDealsResult: null,
      csvFile: [],
      csvDealIds: []
    };
  }

  static _getDealTypeText(dealType) {
    const dealTypeLabels = {
      'all': 'すべて',
      'add_points': 'ポイント付与',
      'add_free_points': '無料ポイント付与',
      'add_paid_points': '有料ポイント付与',
      'purchase_voucher': '金券購入',
      'consume_points': 'ポイント消費',
      'force_consume_points': 'ポイント強制消費',
      'points_expired': 'ポイント期限失効',
      'points_invalidated': 'ポイント失効',
      'cancel_deal': '取引きキャンセル'
    }
    const result = dealTypeLabels[dealType];
    return (result != null) ? result : '';
  }

  static _getDealTypeClassName(dealType) {
    const dealTypeClassNames = {
      'all': 'badge badge-default',
      'add_points': 'badge badge-primary',
      'add_free_points': 'badge badge-primary',
      'add_paid_points': 'badge badge-primary',
      'purchase_voucher': 'badge badge-info',
      'consume_points': 'badge badge-success',
      'force_consume_points': 'badge badge-success',
      'points_expired': 'badge badge-warning',
      'points_invalidated': 'badge badge-warning',
      'cancel_deal': 'badge badge-warning'
    }
    const result = dealTypeClassNames[dealType];
    return (result != null) ? result : '';
  }

  static _getDealDescription(deal) {
    switch (deal.dealType){
      case 'add_points':
        return `${deal.description}`;
        break;
      case 'add_free_points':
        return `${deal.description}`;
        break;
      case 'add_paid_points':
        return `${deal.description}`;
        break;
      case 'purchase_voucher':
        return `金券 ID: ${deal.description}`;
        break;
      case 'consume_points':
        return `商品 ID: ${deal.description}`;
        break;
      case 'force_consume_points':
        return `${deal.description}`;
        break;
      case 'points_expired':
        return `${deal.description}`;
        break;
      case 'cancel_deal':
        return `${deal.description} ID: ${deal.cancelTargetDealId}`;
        break;
    }
    return deal.description;
  }

  _dealHistoryView(dealHistoryResponse) {
    if (dealHistoryResponse == null) {
      return null;
    }
    return (
      <div>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>
                <div class="input-group">
                  <input type="checkbox"
                          onChange={EventListener._onCheckAllDealCheckboxChange.bind(this)}/>
                </div>
              </th>
              <th> 取引きID </th>
              <th> ユーザーID </th>
              <th> 取引き区分 </th>
              <th> 取引き内容 </th>
              <th> ポイント数 </th>
              <th> 購入金額 </th>
              <th> 売上金額 </th>
              <th> プロバイダ名 </th>
              <th> 取引き日時 </th>
            </tr>
          </thead>
          <tbody>
            {dealHistoryResponse.dealHistory.map((deal) => {
                const dealtAtLabel = moment(deal.dealtAt).format('YYYY/MM/DD HH:mm:ss');
                const dealDescription = SearchDealPage._getDealDescription(deal);
                return (
                  <tr key={deal.id}>
                    <td>
                      <div class="input-group">
                        <input type="checkbox"
                              value={deal.id}
                              checked={deal.checked}
                              onChange={EventListener._onDealCheckboxChange.bind(this)}/>
                      </div>
                    </td>
                    <td> {deal.id} </td>
                    <td> {deal.userId} </td>
                    <td>
                      <span class={ SearchDealPage._getDealTypeClassName(deal.dealType) }>
                        {SearchDealPage._getDealTypeText(deal.dealType)}
                      </span>
                    </td>
                    <td> {dealDescription} </td>
                    <td class="text-right"> {deal.points} pt </td>
                    <td class="text-right"> {deal.purchasePrice} 円 </td>
                    <td class="text-right"> {deal.salesPrice} 円 </td>
                    <td> {deal.provider.name} </td>
                    <td> {dealtAtLabel} </td>
                  </tr>
                );
            })}
          </tbody>
        </table>
        <div class="offset-sm-9 col-sm-2">
          <button type="button" class="btn btn-primary btn-lg"
                  data-target="#cancelDeals" data-toggle="modal" data-backdrop="false">
            取引きキャンセル
          </button>
        </div>
      </div>
    );
  }

  _searchForm() {
    return (
      <form>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label text-right font-weight-bold">取引き区分</label>
          <div class="col-sm-3">
            <select class="form-control"
                    onChange={EventListener._onDealTypeSelectBoxChange.bind(this)}
                    defaultValue={this.state.dealType}>
              <option value="all">すべて</option>
              <option value="add_points">ポイント付与</option>
              <option value="add_free_points">無料ポイント付与</option>
              <option value="add_paid_points">有料ポイント付与</option>
              <option value="purchase_voucher">金券購入</option>
              <option value="consume_points">ポイント消費</option>
              <option value="force_consume_points">ポイント強制消費</option>
              <option value="points_expired">ポイント失効</option>
              <option value="cancel_deal">取引きキャンセル</option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label text-right font-weight-bold">取引きID</label>
          <div class="col-sm-8">
            <input type="text"
                   class="form-control"
                   placeholder=""
                   value={this.state.dealId}
                   onChange={EventListener._onDealIdChange.bind(this)}/>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label text-right font-weight-bold">ユーザーID</label>
          <div class="col-sm-8">
            <input type="text"
                   class="form-control"
                   placeholder=""
                   value={this.state.userId}
                   onChange={EventListener._onUserIdChange.bind(this)}/>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label text-right font-weight-bold">決済ID</label>
          <div class="col-sm-8">
            <input type="text"
                   class="form-control"
                   placeholder=""
                   value={this.state.kaeruTransactionId}
                   onChange={EventListener._onKaeruTransactionIdChange.bind(this)}/>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label text-right font-weight-bold">エージェンシー決済ID</label>
          <div class="col-sm-8">
            <input type="text"
                   class="form-control"
                   placeholder=""
                   value={this.state.transactionId}
                   onChange={EventListener._onTransactionIdChange.bind(this)}/>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label text-right font-weight-bold">取引き日時</label>
          <label class="col-sm-1 col-form-label text-right font-weight-bold">検索開始:</label>
          <div class="col-sm-3">
            <input type="datetime-local"
                   class="form-control"
                   placeholder="例 2017-01-01T00:00:00"
                   value={this.state.dealtAtFrom}
                   onChange={EventListener._onDealtAtFromChange.bind(this)}/>
          </div>
          <label class="col-sm-1 col-form-label text-right font-weight-bold">検索終了:</label>
          <div class="col-sm-3">
            <input type="datetime-local"
                   class="form-control"
                   placeholder="例 2017-01-01T00:00:00"
                   value={this.state.dealtAtUntil}
                   onChange={EventListener._onDealtAtUntilChange.bind(this)}/>
          </div>
        </div>

        <div class="form-group row mt-4">
          <label class="offset-sm-1 col-sm-2 font-weight-bold">CSVファイル</label>
          <input type="file" class="col-sm-5" id="csvFile"
                 onChange={EventListener._onCsvFileInputChanged.bind(this)} />
        </div>
        <div class="form-group row">
          <p class="offset-sm-2 form-text text-muted">複数の取引きIDを含んだCSVファイルを指定してください。</p>
        </div>
        <div class="form-group row">
          <div class="offset-sm-1 col-sm-10">
            <button type="button" class="btn btn-primary btn-lg"
                    onClick={EventListener._onSearchButtonClick.bind(this)}>
              検索
            </button>
          </div>
        </div>
      </form>
    );
  }

  _getCurrentPaginationSetting() {
    const { page, dealHistory } = this.state;
    return {
      limit,
      offset: limit * (page - 1),
      currentPage: page,
      maxPage: Math.ceil(dealHistory.totalCount / limit),
      countStart: limit * (page - 1) + 1,
      countEnd: Math.min(limit * page, dealHistory.totalCount),
      totalCount: dealHistory.totalCount
    };
  }

  _paginationView(dealHistoryResponse) {
    if (dealHistoryResponse == null) {
      return null;
    }

    const currentPaginationSetting = this._getCurrentPaginationSetting();
    const paginationLabel = (currentPaginationSetting.totalCount != 0) ? `${currentPaginationSetting.countStart} - ${currentPaginationSetting.countEnd} / ${currentPaginationSetting.totalCount} 件` : '0件';
    if (currentPaginationSetting.maxPage > 20) { // ページ数が多すぎる場合には次のページと前のページだけだすようにする
      const prevClassLabel = (1 >= currentPaginationSetting.currentPage) ? 'disabled' : '';
      const nextClassLabel = (currentPaginationSetting.currentPage >= currentPaginationSetting.maxPage) ? 'disabled' : '';
      return (
        <div>
          <nav aria-label="Page navigation" class="d-flex align-items-center">
            <div class="rounded-pill border d-inline-flex justify-content-center align-items-center px-3 py-2 mr-3">{paginationLabel}</div>
            <ul class="pagination d-flex align-items-center mb-0">
              <li class={ `page-item ${prevClassLabel}` } key={ `page-${currentPaginationSetting.currentPage-1}` }>
                <button
                  class="btn btn-link page-link"
                  type="button"
                  value={currentPaginationSetting.currentPage-1}
                  onClick={EventListener._onPageButtonClick.bind(this)}>前
                </button>
              </li>
              <li class={ `page-item ${nextClassLabel}` } key={ `page-${currentPaginationSetting.currentPage+1}` }>
                <button
                  class="btn page-link"
                  type="button"
                  value={currentPaginationSetting.currentPage+1}
                  onClick={EventListener._onPageButtonClick.bind(this)}>次
                </button>
              </li>
            </ul>
          </nav>
        </div>
      );
    }
    return (
      <div>
        <nav aria-label="Page navigation" class="d-flex align-items-center">
          <div class="rounded-pill border d-inline-flex justify-content-center align-items-center px-3 py-2 mr-3"> {paginationLabel} </div>
          <ul class="pagination d-flex align-items-center mb-0">
            {Array.from(Array(currentPaginationSetting.maxPage).keys()).map((p) => {
                const classLabel = (currentPaginationSetting.currentPage == p+1) ? 'active' : '';
                return (
                  <li class={`page-item ${classLabel}`} key={ `page-${p+1}` }>
                    <button
                      class="btn page-link"
                      type="button"
                      value={p+1}
                      onClick={EventListener._onPageButtonClick.bind(this)}>{ p+1 }</button>
                  </li>
                );
              })}
          </ul>
        </nav>
      </div>
    );
  }

  _dialogs() {
    const { cancelTargetDealIds, dealHistory, cancelDealsResult, cancelDescription } = this.state;
    const targetDeals = (dealHistory != null) ? dealHistory.dealHistory.filter((d) => { return cancelTargetDealIds.includes(d.id); }) : [];
    // キャンセル実行前の確認用ダイアログ
    const cancelDealsConfirmationDialog = (dealHistory != null) ? (
      <CancelDealsConfirmationDialog
          deals={targetDeals}
          cancelDescription={cancelDescription}
          onCancelDescriptionChanged={EventListener._onCancelDescriptionChanged.bind(this)}
          onCancelButtonClicked={EventListener._onCancelButtonClicked.bind(this)}
          onOkButtonClicked={EventListener._onCancelDealsButtonClick.bind(this)}/> ) : null;
    // キャンセル実行後の結果確認用ダイアログ
    const cancelDealsResultDialog = (cancelDealsResult != null) ? (
      <CancelDealsResultDialog
          ref="cancelDealsResultDialog"
          cancelDealsResult={cancelDealsResult}/> ) : null;
    return (
      <div>
        {cancelDealsConfirmationDialog}
        {cancelDealsResultDialog}
      </div>
    );
  }

  _searchDeals(page, searchConditions) {
    const { userId, dealType, dealId, transactionId, kaeruTransactionId, dealtAtFrom, dealtAtUntil, csvDealIds } = searchConditions;
    const offset = limit * (page - 1);
    const dealIds = (dealId != null && dealId != '') ? csvDealIds.concat([ dealId ]) : csvDealIds;
    this.setState({ isSearchDealHistoryLoading: true });
    Promise.all([
      BackendApiService.searchDeal(this.props.assortId, offset, limit, dealType, dealtAtFrom, dealtAtUntil, dealIds, userId, null, kaeruTransactionId, transactionId)
    ]).then(([ response ]) => {
      console.trace('search deal api succeeded', response);
      const dealHistoryResponse = EventListener._updateAllDealHistoryCheckStatus(response.data, false);
      this.setState({ isSearchDealHistoryLoading: false, dealHistory: dealHistoryResponse, page, cancelTargetDealIds: [] });
    }).catch((error) => {
      console.error('search deal api failed.', error);
      this.setState({ isSearchDealHistoryLoading: false });
      ErrorHandleService.handleError(EventListener._errorHandler.bind(this), error);
    });
  }

  render() {
    console.log('BalancePage', this);
    const { isSearchDealHistoryLoading, dealHistory } = this.state;

    if (isSearchDealHistoryLoading) {
      return ( <MiniLoadingIcon/> );
    }
    return (
      <div class="p-deal-history-list container-fluid">
        <div class="p-deal-history-list__header">
          <div class="page-header">
            <h1><small class="text-muted">取引き履歴検索</small></h1>
          </div>
        </div>
        <div class="p-deal-history-list__body row">
          <div class="p-deal-history-list__main container-fluid">
            { this._searchForm() }
            { this._paginationView(dealHistory) }
            { this._dealHistoryView(dealHistory) }
            { this._dialogs() }
          </div>
        </div>
      </div>
    );
  }
}
