import './style.scss';
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom';

import GroupMembers from './GroupMembers';

import BackendApiService from 'service/api/BackendApiService';
import ErrorHandleService from 'error/ErrorHandleService';
import { ManageApiError } from 'error/ManageError';

class EventListener {

  static _onRegisterButtonClick(e) {
    const members = this.refs.groupMembers.getMembers();
    const { groupName, groupDescription } = this.state;
    const [ roleId, memberIds ] = [ this._getRoleId(), members.map((m) => { return m.id; }) ];

    Promise.all([
      BackendApiService.createGroup(groupName, groupDescription, roleId, memberIds)
    ]).then(([ response ]) => {
      console.trace('create group api succeeded', response);
      setTimeout(() => { location.href = '/apps/manage/groups'; }, 500);
    }).catch((error) => {
      console.error('create group api failed.', error);
      ErrorHandleService.handleError(EventListener._errorHandler.bind(this), error);
    });
  }

  static _errorHandler(error) {
    if (!(error instanceof ManageApiError)) { throw error; }
    const [ status, body, errorCode, errorSubCode, errorMessage ] = [ error.getStatusCode(), error.getResponseBody(), error.getErrorCode(), error.getErrorSubCode(), error.getErrorMessage() ];
    console.debug('error handler.', status, body, errorCode, errorSubCode, errorMessage);
    switch (status) {
      default:
        this.props.mainComponent.setState({ showAlert: true, alertContent: 'エラーが発生しました。' });
        break;
    }
  }

  static _onGroupNameChange(e) {
    this.setState({ groupName: e.target.value });
  }

  static _onGroupDescriptionChange(e) {
    this.setState({ groupDescription: e.target.value });
  }
};

// TODO: バリデーション
export default class CreateGroupPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isCreateGroupCompleted: false,
      groupName: '',
      groupDescription: ''
    };
  }

  render() {
    console.log('CreateGroupPage', this);
    const { isCreateGroupCompleted } = this.state;
    const { isGroupsLoading, roles } = this.props.mainComponent.state;

    if (isCreateGroupCompleted) {
      return ( <Redirect to={ '/apps/manage/groups' }/> );
    }

    if (isGroupsLoading) {
      return ( <Redirect to={ '/apps/manage/groups' }/> );
    }

    if (roles.length == 0) {
      return ( <div>ロールが存在しません。ロールを新規作成してください。</div> );
    }

    return (
      <div class="p-create-group-page container-fluid">
        <div class="p-create-group-page__header">
          <div class="page-header">
            <h1><small class="text-muted">グループ登録ページ</small></h1>
          </div>
        </div>
        <div class="p-create-group-page__body row">
          <div class="p-create-group-page__main container-fluid">
            <form>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label text-right font-weight-bold">グループ名</label>
                <div class="col-sm-7">
                  <input type="text"
                         class="form-control"
                         placeholder="group name"
                         onChange={EventListener._onGroupNameChange.bind(this)}/>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label text-right font-weight-bold">説明</label>
                <div class="col-sm-7">
                  <textarea class="form-control"
                            rows="5"
                            placeholder="group description"
                            onChange={EventListener._onGroupDescriptionChange.bind(this)}></textarea>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label text-right font-weight-bold">ロール</label>
                <div class="col-sm-7">
                  <div class="row">
                    <div class="col-sm-5">
                      <select class="form-control"
                              ref="roleSelectBox">
                        {roles.map((role) => {
                          return (<option key={role.id} value={role.id}>{role.name}</option>);
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label text-right font-weight-bold">メンバー</label>
                <GroupMembers {...this.props} ref="groupMembers"/>
              </div>

              <div class="form-group row">
                <div class="offset-sm-1 col-sm-10">
                  <button type="button" class="btn btn-primary btn-lg"
                          onClick={EventListener._onRegisterButtonClick.bind(this)}>
                    作成
                  </button>
                </div>
              </div>
            </form>

          </div>
        </div>
      </div>
    );
  }

  _getRoleId() {
    if (this.refs.roleSelectBox.selectedOptions != null) {
      const roleIdStr = this.refs.roleSelectBox.selectedOptions[0].value;
      return parseInt(roleIdStr, 10);
    } else {
      return this.props.mainComponent.state.roles[0].id;
    }
  }
}
