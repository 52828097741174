import './style.scss';
import React from 'react';
import ReactDOM from 'react-dom';

import MiniLoadingIcon from 'components/LoadingIcon/MiniLoadingIcon';

import BackendApiService from 'service/api/BackendApiService';
import ErrorHandleService from 'error/ErrorHandleService';
import {ManageApiError} from 'error/ManageError';
import {AddPaidPointsResultDialog, AddPaidPointsConfirmationDialog} from './AddPaidPointsDialog';
import {v4 as uuidv4} from "uuid";

// biome-ignore lint/complexity/noStaticOnlyClass: TODO
class EventListener {
  static _onUserIdChange(e) {
    this.setState({userId: e.target.value});
  }

  static _onPriceChange(e) {
    this.setState({price: e.target.value});
  }

  static _onPointsChange(e) {
    this.setState({points: e.target.value});
  }

  static _onDescriptionChange(e) {
    this.setState({description: e.target.value});
  }

  static _onConfirmButtonClick(e) {
    const assortId = this.props.assortId;
    const {userId, description, price, points} = this.state;
    const uniqueToken = uuidv4();
    this.setState({isAddPointsLoading: true});
    Promise.all([
      this.props.backendApiService.addPaidPoints(assortId, userId, description, uniqueToken, price, points)
    ]).then(([ response ]) => {
      console.trace('add paid points api succeeded', response);
      this.setState({isAddPointsLoading: false, addPaidPointsResult: response});
      const resultDialog = ReactDOM.findDOMNode(this.refs.addPaidPointsResultDialog);
      $(resultDialog).modal({backdrop: false});
    }).catch((error) => {
      console.error('add paid points api failed.', error);
      this.setState({isAddPointsLoading: false});
      this.props.errorHandleService.handleError(EventListener._errorHandler.bind(this), error);
    });
  }

  static _onResetButtonClick(e) {
    this.setState({
      userId: '',
      description: '',
      price: '',
      points: '',
      addPaidPointsResult: null
    });
  }

  static _errorHandler(error) {
    if (!(error instanceof ManageApiError)) {
      throw error;
    }
    const [ status, body, errorCode, errorSubCode, errorMessage ] = [error.getStatusCode(), error.getResponseBody(), error.getErrorCode(), error.getErrorSubCode(), error.getErrorMessage()];
    console.debug('error handler.', status, body, errorCode, errorSubCode, errorMessage);
    switch (status) {
      default:
        this.props.mainComponent.setState({showAlert: true, alertContent: 'エラーが発生しました。'});
        break;
    }
  }
}

export default class AddPaidPointsPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      userId: '',
      description: '',
      price: '',
      points: '',
      addPaidPointsResult: null,
      isAddPointsLoading: false
    };
  }

  _addPaidPointsForm() {
    return (
      <form>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label text-right font-weight-bold">ユーザーID</label>
          <div class="col-sm-8">
            <input type="text"
                   class="form-control"
                   placeholder=""
                   value={this.state.userId}
                   onChange={EventListener._onUserIdChange.bind(this)}/>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-2 col-form-label text-right font-weight-bold">説明</label>
          <div className="col-sm-8">
            <input type="text"
                   className="form-control"
                   placeholder=""
                   value={this.state.description}
                   onChange={EventListener._onDescriptionChange.bind(this)}/>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-2 col-form-label text-right font-weight-bold">価格</label>
          <div className="col-sm-8">
            <input type="text"
                   className="form-control"
                   placeholder=""
                   value={this.state.price}
                   onChange={EventListener._onPriceChange.bind(this)}/>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label text-right font-weight-bold">付与ポイント数</label>
          <div class="col-sm-8">
            <input type="text"
                   class="form-control"
                   placeholder=""
                   value={this.state.points}
                   onChange={EventListener._onPointsChange.bind(this)}/>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-2 col-form-label text-right font-weight-bold"> </label>
          <button type="button" className="btn btn-primary btn-sm"
                  data-target="#add_paid_points_dialog" data-toggle="modal" data-backdrop="false">
            ポイント付与
          </button>
        </div>
      </form>
    );
  }

  _dialogs() {
    const {userId, description, price, points, addPaidPointsResult} = this.state;
    // ポイント付与前の確認用ダイアログ
    const addPaidPointsConfirmationDialog = (
      <AddPaidPointsConfirmationDialog
        userId={userId}
        description={description}
        price={price}
        points={points}
        onOkButtonClicked={EventListener._onConfirmButtonClick.bind(this)}/> );
    // ポイント付与後の結果確認用ダイアログ
    const addPaidPointsResultDialog = (addPaidPointsResult != null) ? (
      <AddPaidPointsResultDialog
        ref="addPaidPointsResultDialog"
        addPaidPointsResult={addPaidPointsResult}
        onOkButtonClicked={EventListener._onResetButtonClick.bind(this)}/> ) : null;
    return (
      <div>
        {addPaidPointsConfirmationDialog}
        {addPaidPointsResultDialog}
      </div>
    );
  }

  render() {
    console.log('AddpaidPointsPage', this);
    const {isAddPointsLoading} = this.state;

    if (isAddPointsLoading) {
      return ( <MiniLoadingIcon/> );
    }
    return (
      <div class="p-add-points container-fluid">
        <div class="p-add-points__header">
          <div class="page-header">
            <h1>
              <small class="text-muted">有料ポイント付与</small>
            </h1>
          </div>
        </div>
        <div class="p-add-points__body row">
          <div class="p-add-points__main container-fluid">
            <h3>
              <small class="text-muted">個別追加</small>
            </h3>
            { this._addPaidPointsForm() }
            { this._dialogs() }
          </div>
        </div>
      </div>
    );
  }
}

AddPaidPointsPage.propTypes = {
  backendApiService: BackendApiService,
  errorHandleService: ErrorHandleService
};

AddPaidPointsPage.defaultProps = {
  backendApiService: BackendApiService,
  errorHandleService: ErrorHandleService
};
