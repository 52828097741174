import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom';

import BackendApiService from 'service/api/BackendApiService';

// TODO: 編集機能、バリデーション機能
export default class EditUserPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isEditUserCompleted: false
    };
  }

  render() {
    console.log('EditUserPage', this);
    const { isEditUserCompleted } = this.state;
    const { isUsersLoading, users } = this.props.mainComponent.state;

    if (isEditUserCompleted) {
      return ( <Redirect to={ '/apps/manage/users' }/> );
    }

    if (isUsersLoading) {
      return ( <Redirect to={ '/apps/manage/users' }/> );
    }

    const user = users.find((u) => { return u.id == this.props.userId; });
    if (user == null) {
      return ( <div>存在しないユーザーです。</div> );
    }

    return (
      <div class="p-edit-user-page container-fluid">
        <div class="p-edit-user-page__header">
          <div class="page-header">
            <h1><small class="text-muted">ユーザー情報ページ</small></h1>
          </div>
        </div>
        <div class="p-edit-user-page__body row">
          <div class="p-edit-user-page__main container-fluid">
            <form>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label text-right font-weight-bold">ID</label>
                <div class="col-sm-7 pt-2">
                  <p>{ user.id }</p>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label text-right font-weight-bold">メールアドレス</label>
                <div class="col-sm-7 pt-2">
                  <p>{ user.email }</p>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label text-right font-weight-bold">ユーザー名</label>
                <div class="col-sm-7 pt-2">
                  <p>{ user.username }</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
