import ExtendableError from 'es6-error';

/**
 * 管理ツールのエラー基底
 */
export class ManageError extends ExtendableError {
  constructor(message = 'ManageError') {
    super(message);
  }
}

/**
 * APIエラー
 */
export class ManageApiError extends ManageError {
  constructor(response, body) {
    super('ManageApiError');
    this.response = response;
    this.body = body;
  }

  getResponse() {
    return this.response;
  }

  getStatusCode() {
    return this.response.status;
  }

  getResponseBody() {
    return this.body;
  }

  getErrorCode() {
    return this.body.meta.errorCode;
  }

  getErrorSubCode() {
    return this.body.meta.errorSubCode;
  }

  getErrorMessage() {
    return this.body.meta.errorMessage;
  }

  getDisplayMessage() {
    return `status: ${this.response.status}, code: ${this.body.meta.errorCode}, message: ${this.body.meta.errorMessage}`;
  }
}
