import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom';

import BackendApiService from 'service/api/BackendApiService';
import ErrorHandleService from 'error/ErrorHandleService';
import { ManageApiError } from 'error/ManageError';

class EventListener {

  static _onPasswordChange(e) {
    this.setState({ password: e.target.value });
  }

  static _onConfirmationPasswordChange(e) {
    this.setState({ confirmationPassword: e.target.value });
  }

  static _onUpdatePasswordButtonClick(e) {
    const { password } = this.state;
    Promise.all([
      BackendApiService.updatePassword(password)
    ]).then(([ response ]) => {
      console.trace('update password api succeeded', response);
      setTimeout(() => { location.href = '/apps/account/profile'; }, 500);
    }).catch((error) => {
      console.error('update password api failed.', error);
      ErrorHandleService.handleError(EventListener._errorHandler.bind(this), error);
    });
  }

  static _errorHandler(error) {
    if (!(error instanceof ManageApiError)) { throw error; }
    const [ status, body, errorCode, errorSubCode, errorMessage ] = [ error.getStatusCode(), error.getResponseBody(), error.getErrorCode(), error.getErrorSubCode(), error.getErrorMessage() ];
    console.debug('error handler.', status, body, errorCode, errorSubCode, errorMessage);
    switch (status) {
      default:
        this.props.mainComponent.setState({ showAlert: true, alertContent: 'エラーが発生しました。' });
        break;
    }
  }
}

export default class UpdateAccountPasswordPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      password: '',
      confirmationPassword: ''
    };
  }

  render() {
    console.log('UpdateAccountPasswordPage', this);
    const { isAccountInfoLoading, password, confirmationPassword } = this.state;

    if (isAccountInfoLoading) {
      return ( <Redirect to={ '/apps/account/profile' }/> );
    }

    return (
      <div class="p-update-account-password container-fluid">
        <div class="p-update-account-password__header">
          <div class="page-header">
            <h1><small class="text-muted">パスワード変更ページ</small></h1>
          </div>
        </div>
        <div class="p-profile-page__body row">
          <div class="p-profile-page__main container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <form>
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label text-right font-weight-bold">パスワード</label>
                    <div class="col-sm-7">
                      <input type="password" class="form-control" placeholder="password"
                             value={password}
                             onChange={EventListener._onPasswordChange.bind(this)}/>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label text-right font-weight-bold">パスワード確認</label>
                    <div class="col-sm-7">
                      <input type="password" class="form-control" placeholder="confirmation password"
                             value={confirmationPassword}
                             onChange={EventListener._onConfirmationPasswordChange.bind(this)}/>
                    </div>
                  </div>

                  <div class="form-group row">
                    <div class="offset-sm-1 col-sm-10">
                      <button type="button" class="btn btn-primary btn-lg"
                              onClick={EventListener._onUpdatePasswordButtonClick.bind(this)}>更新</button>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
