import './style.scss';
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom';

import BackendApiService from 'service/api/BackendApiService';
import SessionService from 'service/SessionService';

class EventListener {
  static onLogoutButtonClick(history) {
    BackendApiService
      .logout()
      .then((response) => {
        SessionService.clear();
        setTimeout(() => { location.href = '/'; }, 500);
      });
  }
}

export default class SiteHeader extends React.Component {
  render() {
    const { account } = this.props;

    return (
      <div class="p-site-header">
        <nav class="p-site-header__nav navbar navbar-expand-sm navbar-dark bg-dark">
          <div class="container-fluid">
            <div class="p-site-header__label">
              <Link to="/" class="navbar-brand">アプリ版ニコニコポイント 管理ツール</Link>
            </div>
            <div class="p-site-header__main collapse navbar-collapse">
              <ul class="p-site-header__right nav navbar-nav ml-auto">
                <li class="dropdown nav-item">
                  <span class="dropdown-toggle nav-link" data-toggle="dropdown">
                    {account.username}
                    <i class="p-site-header-dropdown__icon fa fa-user"/>
                    <b class="caret"/>
                  </span>
                  <ul class="dropdown-menu">
                    <li>
                      <Link to="/apps/account/profile">
                        <i class="fa fa-fw fa-user"/> User Profile
                      </Link>
                    </li>
                    <li class="dropdown-divider"/>
                    <li>
                      <span class="text-primary" onClick={EventListener.onLogoutButtonClick.bind(this)}>
                        <i class="fa fa-fw fa-sign-out"/> Logout
                      </span>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}
